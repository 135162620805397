import request from "../services/request";
import { API_BASE_URL } from "../services/const";

export const addStaff = (data) =>
  request(`${API_BASE_URL}/employee`, {
    method: "POST",
  });

export const getInterest = (data) =>
  request(`${API_BASE_URL}/getallinterests`, {
    method: "POST",
    data,
  });

export const getInterestById = (id, data) =>
  request(`${API_BASE_URL}/interests/${id}`, {
    method: "POST",
    data,
  });

export const getAllCustomerData = (data) => {
  return request(`${API_BASE_URL}/getallcustomers`, {
    method: "POST",
    data,
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });
};

export const getAllCustomerDataNew = (schema) => {
  return request(`${API_BASE_URL}/getallcustomers`, {
    method: "POST",
    schema,
  });
};

export const getAllEmplyeesData = (data) =>
  request(`${API_BASE_URL}/getallemployees`, {
    method: "POST",
    data,
  });

export const getAllGroups = (data) =>
  request(`${API_BASE_URL}/getAllGroups`, {
    method: "POST",
    data,
  });

export const getBranches = (data) =>
  request(`${API_BASE_URL}/getAllBranches`, {
    method: "POST",
    data,
  });

export const deleteEmplyeesDataById = (id, data) =>
  request(`${API_BASE_URL}/deleteEmployee/${id}`, {
    method: "POST",
    data,
  });

export const updateEmplyeesDataById = (data) =>
  request(`${API_BASE_URL}/employee/${data.id}`, {
    method: "PATCH",
    data,
  });

export const getAllCustomers = (data) =>
  request(`${API_BASE_URL}/getallcustomers`, {
    method: "POST",
    data,
  });

export const getAllCustomersCount = (data) =>
  request(`${API_BASE_URL}/getCustomerCount`, {
    method: "POST",
    data,
  });

export const getAllIncomeMeta = (data) =>
  request(`${API_BASE_URL}/getPrincipleAmount`, {
    method: "POST",
    data,
  });

export const getAllConversionMeta = (data) =>
  request(`${API_BASE_URL}/getallConversionMeta`, {
    method: "POST",
    data,
  });

export const getAllSessionMeta = (data) =>
  request(`${API_BASE_URL}/getallSessionMeta`, {
    method: "POST",
    data,
  });

export const getAllMetaData = (data) =>
  request(`${API_BASE_URL}/getallMetaData`, {
    method: "POST",
    data,
  });

export const getCollections = (data) =>
  request(`${API_BASE_URL}/getAllEmi`, {
    method: "POST",
    data,
  });

export const deleteCustomersById = (data) =>
  request(`${API_BASE_URL}/deleteCustomer/${data.id}`, {
    method: "POST",
    data,
  });

// export const addCustomer = (data) =>
//   request(`${API_BASE_URL}/customers`, {
//     method: "POST",
//     data,
//     headers: new Headers({
//       "Content-Type": "multipart/form-data",
//     }),
//   });

export const addCustomer = (data) =>
  request(`${API_BASE_URL}/addNewCustomer`, {
    method: "POST",
    data,
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });

export const updateCustomer = ({ data, id, params = "" }) =>
  request(`${API_BASE_URL}/customers/pardeep/${id}`, {
    method: "PATCH",
    data,
    params: params,
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });

export const getAllCustomerSanctionLetter = (data) =>
  request(`${API_BASE_URL}/getAllFileSanctionLetter`, {
    method: "POST",
    data,
  });

export const getCompanyDashboard = (data) => {
  const { companyId } = data;
  return request(`${API_BASE_URL}/company/${companyId}/dashboard`, {
    method: "GET",
  });
};
