import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CFormSelect, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllEmiByCustomerId, setCustomerId } from "src/redux/actions/emiAction";
import { getAllCustomers } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import LoanEmiDetailsData from "./loanEmiDetails";
import { CSVLink } from "react-csv";
import { getS3Url } from "src/services/helpers";
import { Stack } from "@mui/system";
import PaymentModel from "./paymentModal";
import { COMPANY_NAME } from "src/services/const";

const EmiRecords = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const { emiData } = useSelector((state) => state?.emiReducer);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [getAllEmis, setGetAllEmis] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showEmiDetails, setShowEmiDetails] = useState(true);
  const [visible, setVisible] = useState(false);
  const [dueData, setDueData] = useState(null);
  const componentPDF = useRef();
  const [printState, setPrintState] = useState(false);
  const [loanId, setLoanId] = useState("");

  useEffect(() => { getAllCompanyData(); }, []);

  useEffect(() => {
    if (printState) { generatePDF(); }
  }, [printState]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setAllCustomerData(res?.data);
    });
  };

  const download_btn = () => { setPrintState(true); };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const rowsPerPage = 10;

  const filteredEmis = getAllEmis
    ?.filter((emi) => {
      if (selectedStatus === "all") return true;
      return emi?.emiStatus === selectedStatus;
    })
    .sort((item, item_) => {
      return new Date(item.dueDate) - new Date(item_.dueDate);
    });

  const handlePayment = (data) => {
    setDueData(data);
    setVisible(true);
  };

  const handlePageChange = (pageNumber) => { setCurrentPage(pageNumber); };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredEmis?.slice(indexOfFirstRow, indexOfLastRow);

  const handleSearch = () => {
    const filteredCustomer = allCustomerData?.filter(
      (customer) =>
        customer?.aadharNumber === firstName ||
        customer?.phoneNumber === firstName
    );

    if (filteredCustomer?.length > 0) {
      setCustomerData(filteredCustomer[0]);
      const obj = {
        schemaName: SchemaName,
        customerId: filteredCustomer[0]?.id,
      };
      const customer = filteredCustomer[0];
      dispatch(setCustomerId(customer));
      dispatch(getAllEmiByCustomerId(obj));
      setShowDetails(true);
      setShowTable(true);
    } else {
      setCustomerData([]);

    }
  };
  const parsedAddress = customerData?.address;

  return (
    <div className="content">
      {visible && (
        <PaymentModel
          visible={visible}
          setVisible={setVisible}
          customerData={customerData}
          emiData={emiData}
          dueData={dueData}
          loanId={loanId}
        />
      )}
      <CCard>
        <CCardHeader>
          <div className=" flex justify-between">
            <strong>EMI Record</strong>
            <div>
              <CButton className="add-btn mr-10 w-auto" onClick={download_btn}>
                Download
              </CButton>
              {currentRows && (
                <CSVLink
                  data={currentRows === undefined ? " " : currentRows}
                  filename={"emiRecord-table.csv"}
                >
                  <CButton className="add-btn w-auto m-0">CSV</CButton>
                </CSVLink>
              )}
              {showEmiDetails === false ? (
                <CButton
                  className="add-btn m-0 w-auto"
                  onClick={() => setShowEmiDetails(true)}
                >
                  Back
                </CButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow>
              <CCol xs={4}>
                <CFormInput
                  placeholder="Enter your Phone Number/Aadhar Number"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </CCol>

              <CCol xs={2}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
              {showEmiDetails === false ? (
                <CCol xs={2}>
                  <CFormSelect
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">Select EMI Status</option>
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>
                  </CFormSelect>
                </CCol>
              ) : (
                ""
              )}
            </CRow>
          </div>
          <div className="serch_bar"></div>

          <div
            ref={printState ? componentPDF : null}
            style={{ padding: printState ? "20px" : "0px" }}
          >
            {printState && (
              <div className="print-div">
                <div>
                  {/* <Vakilkaro /> */}
                  <h3>Riyms</h3>
                </div>
                <div>
                  <p>{COMPANY_NAME}</p>
                </div>
              </div>
            )}

            {showDetails && (
              <div
                className="customer_details d-flex flex-row"
                ref={componentPDF}
              >
                <div style={{ width: "85%" }}>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Full Name : </span>
                      <span className="content-name">{`${customerData?.firstName}`}</span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Aadhar Number : </span>
                      <span className="content-name">
                        {customerData?.aadharNumber}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name"> Email : </span>
                      <span className="content-name">
                        {customerData?.email}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Pan Number : </span>
                      <span className="content-name">
                        {customerData?.PanNumber}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Loan Account Number : </span>
                      <span className="content-name">
                        {customerData?.loanAccountNumber}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Bank Account Number : </span>
                      <span className="content-name">
                        {customerData?.bankAccountNo}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Phone Number : </span>
                      <span className="content-name">
                        {customerData?.phoneNumber}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Branch Name : </span>
                      <span className="content-name">
                        {customerData?.branch.branchName}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={12}>
                      <span className="lable-name">Address : </span>
                      {parsedAddress && (
                        <>
                          <span className="content-name">
                            {parsedAddress.area},
                          </span>
                          <span className="content-name">
                            {parsedAddress.city},
                          </span>
                          <span className="content-name">
                            {parsedAddress.district},
                          </span>
                          <span className="content-name">
                            {parsedAddress.state},
                          </span>
                          <span className="content-name">
                            {parsedAddress.country},
                          </span>
                          <span className="content-name">
                            {parsedAddress.pincode}.
                          </span>
                        </>
                      )}
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Total Amount</span>
                      <span className="content-name">
                        {customerData?.approvalAmmount}
                      </span>
                    </CCol>
                  </CRow>
                </div>
                <div
                  className="d-flex justify-content-start"
                  style={{ width: "15%" }}
                >
                  {customerData?.passportSizePhoto ? (
                    <img
                      src={getS3Url(customerData?.passportSizePhoto)}
                      width="100px"
                      height="125px"
                      alt="Customer Image here"
                    />
                  ) : (
                    <img
                      src="/user-logo.png"
                      width="100px"
                      alt="Default Image"
                    />
                  )}
                </div>
              </div>
            )}
            <div>
              {showTable && (
                <>
                  {showEmiDetails === true ? (
                    <LoanEmiDetailsData
                      customerData={customerData?.id}
                      setShowEmiDetails={setShowEmiDetails}
                      setGetAllEmis={setGetAllEmis}
                      setLoanId={setLoanId}
                    />
                  ) : (
                    <CTable hover className="" responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Sr No.</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Due Date</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Loan Amount</span>
                          </CTableHeaderCell>

                          <CTableHeaderCell scope="col" className="">
                            <span className=""> EMI Amount</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">EMI Status</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Payment</span>
                          </CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {filteredEmis?.map((company, index) => (
                          <CTableRow key={index}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>
                              {company?.dueDate ? company?.dueDate : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.loadAmount
                                ? parseInt(company?.loadAmount) +
                                parseInt(company?.interestAmount)
                                : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.emiAmount ? company?.emiAmount : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.emiStatus ? company?.emiStatus : "-"}
                            </CTableDataCell>
                            <CTableDataCell
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Stack width={"100px"}>
                                {company?.emiStatus === "pending" ? (
                                  <CButton
                                    onClick={() => handlePayment(company)}
                                  >
                                    Pay
                                  </CButton>
                                ) : (
                                  "paid"
                                )}
                              </Stack>
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    </CTable>
                  )}{" "}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Showing {indexOfFirstRow + 1} to{" "}
                {Math?.min(indexOfLastRow, filteredEmis?.length)} of{" "}
                {filteredEmis?.length} entries
              </div>
              <div className="pagination-container">
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={rowsPerPage}
                  totalItemsCount={filteredEmis?.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText="Previous"
                  nextPageText="Next"
                  firstPageText="First"
                  lastPageText="Last"
                  innerClass="pagination"
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EmiRecords;
