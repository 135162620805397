import { S3_BASE_URL } from "./const";

export const getS3Url = (url) => {
    const s3url = `${S3_BASE_URL}/${url}`;
    return s3url;
};

export const formatDate = (dateString) => {
    if (!dateString) return "-";
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
};