import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CFormLabel, CFormSelect, CRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getAllEmisByEmiStatus } from "src/redux/actions/emiAction";
import { COMPANY_NAME } from "src/services/const";
import { formatDate } from "src/services/helpers";

const PendingEmiDetails = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { emiStatusData } = useSelector((state) => state?.emiReducer);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [minEndDate, setMinEndDate] = useState("");
  const [status, setStatus] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedEmiStatus, setSelectedEmiStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [printState, setPrintState] = useState(false);
  const componentPDF = useRef();
  const rowsPerPage = 10;

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const objData = {
    schemaName: SchemaName,
    emiStatus: selectedEmiStatus,
    startDate: startDate,
    enddate: endDate,
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleSearch = () => {
    setStatus(true);
    setShowTable(true);
  };

  useEffect(() => {
    if (status === true) {
      dispatch(getAllEmisByEmiStatus(objData));
      setStatus(false);
    }
  }, [status, showTable]);

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    setMinEndDate(selectedStartDate);
    if (endDate < selectedStartDate) {
      setEndDate("");
    }
  };

  const filterByGroupName = () => {
    let filteredRows = emiStatusData?.data || [];
    if (customerName) {
      filteredRows = filteredRows?.filter(
        (item) =>
          String(item?.customer?.loanAccountNumber.toString().toLowerCase()).includes(String(customerName)) ||
          String(item?.customer?.branch.branchName.toString().toLowerCase()).includes(String(customerName)) ||
          String(item?.customer?.firstName.toString().toLowerCase()).includes(String(customerName)) ||
          String(item?.customer?.phoneNumber.toString().toLowerCase()).includes(String(customerName))
      );
    }
    return filteredRows;
  };

  const filteredRows = filterByGroupName();
  const download_btn = () => {
    setPrintState(true);
  };

  const groupedByLoanAccountNumber = filteredRows?.reduce((acc, company) => {
    const loanAccountNumber = company?.customer?.loanAccountNumber || "-";
    if (!acc[loanAccountNumber]) {
      acc[loanAccountNumber] = [];
    }
    acc[loanAccountNumber]?.push(company);
    return acc;
  }, {});

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <strong>All EMI Record</strong>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow className="mb-2">
              <CCol xs={6}>
                <CFormLabel htmlFor="start_date">
                  Start Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="Start Date"
                  aria-label="sm input example"
                  onChange={handleStartDateChange}
                  value={startDate}
                  required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="address">
                  End Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="End Date"
                  aria-label="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  min={minEndDate}
                  required
                />
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol xs={6}>
                <CFormLabel htmlFor="emi-type">
                  Select EMI Type <span className="red-star">*</span>
                </CFormLabel>
                <CFormSelect
                  value={selectedEmiStatus}
                  onChange={(e) => setSelectedEmiStatus(e.target.value)}
                  required
                >
                  <option value="">Select EMI Type</option>
                  <option value="paid">Paid</option>
                  <option value="pending">Pending</option>
                  <option value="extended_date">Extended Date</option>
                </CFormSelect>
              </CCol>
              <CCol xs={6} className="d-flex align-items-end">
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
            </CRow>
          </div>
          {showTable && (
            <div className="table-container">
              <div className="searchinput mb-2">
                <div className="row">
                  <div className="col-xs-12 col-md-4 col-lg-5">
                    <label htmlFor="customer-name">Loan Account Number</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Loan Account Number"
                      aria-label="Loan Account Number"
                      onChange={(e) => setCustomerName(e.target.value)}
                      value={customerName}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 col-lg-2">
                    <button className="custom-button w-auto" onClick={download_btn}>
                      Download
                    </button>
                  </div>
                </div>
              </div>
              <div ref={printState ? componentPDF : null} style={{ padding: printState ? "20px" : "0px" }}>
                {printState && (
                  <div className="print-div">
                    <div>
                      <p>{COMPANY_NAME}</p>
                    </div>
                  </div>
                )}
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Loan Account No.</th>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th>Branch Code</th>
                      <th>EMI Amount</th>
                      <th>Penalty</th>
                      <th>{selectedEmiStatus === "paid" ? "Paid Date" : "Due Date"}</th>
                      <th>Extended Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupedByLoanAccountNumber)?.map((loanAccountNumber, groupIndex) => {
                      return groupedByLoanAccountNumber[loanAccountNumber]?.map((company, index) => (
                        <tr key={`${groupIndex}-${index}`}>
                          <td>{indexOfFirstRow + groupIndex + index + 1}</td>
                          <td>{company?.customer?.loanAccountNumber ? company?.customer?.loanAccountNumber : "--"}</td>
                          <td>{company?.customer?.firstName}</td>
                          <td>{company?.customer?.phoneNumber ? company?.customer?.phoneNumber : "--"}</td>
                          <td>{company?.customer?.branch.branchName ? company?.customer?.branch.branchName : "--"}</td>
                          <td>{company?.emiAmount ? company?.emiAmount : "--"}</td>
                          <td>{company?.penalty ? company?.penalty : "--"}</td>
                          <td>{company?.dueDate ? formatDate(company?.dueDate) : "--"}</td>
                          <td>{company?.extended_date ? formatDate(company?.extended_date) : "--"}</td>

                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {emiStatusData?.data?.length} entries
                </div>
              </div>
            </div>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default PendingEmiDetails;
