// src/App.js
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sale.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import authInstance from "src/api/apiConfig";
import Assets from "./insideData/asstes";
import Equity from "./insideData/equity";
import Liabilities from "./insideData/liabilities";
import Sales from "./insideData/sales";
import Cost from "./insideData/cost";
import IncomeTax from "./insideData/incometax";
import NetIncomeAfterTax from "./insideData/netIncommeData";
import DetailsTable from "./insideData/tableDetails";
import NavigationRoute from "./navigation";
import EmiCalcutor from "./insideData/emiCalculator";

ChartJS.register(ArcElement, Tooltip, Legend);

const NewDashBoard = () => {
  const [allDataCount, setAllDataCount] = useState();

  const allCounts = async () => {
    const payload = {
      schemaName: sessionStorage.getItem("schemaName"),
    };
    try {
      const response = await authInstance.post("/count", payload);
      if (response.status === 200) {
        setAllDataCount(response?.data?.message);
      }
    } catch (error) {}
  };
  useEffect(() => {
    allCounts();
  }, []);
  return (
    <>
      <div className="content">
        <NavigationRoute />
        <Container fluid>
          <Row>
            <Col md={4}>
              <Assets datas={allDataCount} />
            </Col>
            <Col md={4}>
              <Equity datas={allDataCount} />
            </Col>
            <Col md={4}>
              <Liabilities datas={allDataCount} />
            </Col>
          </Row>
          <Row style={{ margin: "20px 0px" }}>
            <Col md={6}>
              <Sales />
            </Col>
            <Col md={6}>
              <NetIncomeAfterTax />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Cost />
            </Col>
            <Col md={4}>
              <IncomeTax />
            </Col>
            <Col md={4}>
              <NetIncomeAfterTax />
            </Col>
          </Row>

          <Row style={{ margin: "20px 0px" }}>
            <DetailsTable />
          </Row>
        </Container>
        <EmiCalcutor />
      </div>
    </>
  );
};

export default NewDashBoard;
