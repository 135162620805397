import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";

const initialValues = {
  pan: "",
  aadhar: "",
  address: "",
  pan1: "",
  aadhar1: "",
  address1: "",
  GuarantorName: "",
  fathersName: "",
  mobileNumber: "",
  GuarantorName1: "",
  fathersName1: "",
  mobileNumber1: "",
};

const Nomniee = (props) => {
  const [cusIncome, setCusIncome] = useState(props?.formData || initialValues);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneError1, setPhoneError1] = useState(false);
  const [aadharError, setAadharError] = useState(false);
  const [aadharError1, setAadharError1] = useState(false);

  const onChangeValue = (e) => {
    const { name, value } = e.target;

    if (name === "pan" || name === "pan1") {
      const sanitizedValue = value.replace(/\s/g, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });
    } else if (
      name === "GuarantorName" ||
      name === "GuarantorName1" ||
      name === "fathersName" ||
      name === "fathersName1"
    ) {
      const sanitizedValue = value.replace(/[^A-Za-z]/gi, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });
    } else if (name === "mobileNumber") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });

      if (sanitizedValue.length !== 10) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    } else if (name === "mobileNumber1") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });

      if (sanitizedValue.length !== 10) {
        setPhoneError1(true);
      } else {
        setPhoneError1(false);
      }
    } else if (name === "aadhar") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });

      if (sanitizedValue.length !== 12) {
        setAadharError(true);
      } else {
        setAadharError(false);
      }
    } else if (name === "aadhar1") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setCusIncome({
        ...cusIncome,
        [name]: sanitizedValue,
      });

      if (sanitizedValue.length !== 12) {
        setAadharError1(true);
      } else {
        setAadharError1(false);
      }
    } else {
      setCusIncome({
        ...cusIncome,
        [name]: value,
      });
    }
  };

  const handleSubmit = (values) => {
    // if (validationSchema) {
    props.updateFormData("Nomniee", cusIncome);
    props.setKey("Reference", values);
    // }
  };

  const {
    pan,
    aadhar,
    address,
    GuarantorName,
    fathersName,
    mobileNumber,
    GuarantorName1,
    fathersName1,
    mobileNumber1,
    pan1,
    aadhar1,
    address1,
  } = cusIncome;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          {/* <CContainer> */}
          <div>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="GuarantorName">
                  Name
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="GuarantorName"
                  placeholder="Guarantor Name"
                  name="GuarantorName"
                  value={GuarantorName}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="mobileNumber">
                  Mobile Number
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="mobileNumber"
                  placeholder="Mobile Number"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={onChangeValue}
                // required
                />
                {phoneError && (
                  <div className="red-star">
                    Mobile number should be 10 digits.
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="fathersName">
                  Father Name
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="fathersName"
                  placeholder="Father Name"
                  name="fathersName"
                  value={fathersName}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
              {/* <CCol xs={6}>
                <CFormLabel htmlFor="Income">
                  Aadhar Number
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="aadhar"
                  placeholder="Aadhar Number"
                  name="aadhar"
                  value={aadhar}
                  onChange={onChangeValue}
                  required
                />
                {aadharError && (
                  <div className="red-star">
                    Aadhar number should be 12 digits.
                  </div>
                )}
              </CCol> */}
              {/* </CRow> */}
              {/* <CRow className="pt-20"> */}
              {/* <CCol xs={6}>
                <CFormLabel htmlFor="monthlyExpenses">
                  PAN Number
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="pan"
                  placeholder="PAN Number"
                  name="pan"
                  value={pan}
                  onChange={onChangeValue}
                  required
                />
              </CCol> */}
              <CCol xs={6}>
                <CFormLabel htmlFor="monthlyExpenses">
                  Address
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="address"
                  placeholder="Address"
                  name="address"
                  value={address}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
            </CRow>
          </div>
          <div className="divider_gurantor pt-20">Other Guarantors</div>
          <div>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="GuarantorName">
                  Name
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="GuarantorName1"
                  placeholder="Guarantor Name"
                  name="GuarantorName1"
                  value={GuarantorName1}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="mobileNumber">
                  Mobile Number
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="mobileNumber1"
                  placeholder="Mobile Number"
                  name="mobileNumber1"
                  value={mobileNumber1}
                  onChange={onChangeValue}
                // required
                />
                {phoneError1 && (
                  <div className="red-star">
                    Mobile number should be 10 digits.
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="fathersName1">
                  Father Name
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="fathersName1"
                  placeholder="Father Name"
                  name="fathersName1"
                  value={fathersName1}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
              {/* <CCol xs={6}>
                <CFormLabel htmlFor="aadhar1">
                  Aadhar Number
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="aadhar1"
                  placeholder="Aadhar Number"
                  name="aadhar1"
                  value={aadhar1}
                  onChange={onChangeValue}
                  required
                />
                {aadharError1 && (
                  <div className="red-star">
                    Aadhar number should be 12 digits.
                  </div>
                )}
              </CCol> */}
              {/* </CRow>
            <CRow className="pt-20"> */}
              {/* <CCol xs={6}>
                <CFormLabel htmlFor="pan1">
                  PAN Number
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="pan1"
                  placeholder="PAN Number"
                  name="pan1"
                  value={pan1}
                  onChange={onChangeValue}
                  required
                />
              </CCol> */}
              <CCol xs={6}>
                <CFormLabel htmlFor="address1">
                  Address
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="address1"
                  placeholder="Address"
                  name="address1"
                  value={address1}
                  onChange={onChangeValue}
                // required
                />
              </CCol>
            </CRow>
          </div>
          {/* </CContainer> */}

          <div className="identification_btn">
            <CButton type="submit" className="next_btn">
              Next
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};

Nomniee.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
};
export default Nomniee;
