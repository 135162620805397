import React, { useRef, useState } from "react";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const initialValues = {
  aadharNumber: "",
  PanNumber: "",
  singnatureImageUrl: null,
  panImageUrl: null,
  aadharImageUrl: null,
  paySlipImageUrl: null,
  localImage: null,
  billImage: null,
  fileCharge: null,
};

const IdentiFicationForm = (props) => {
  const { isUpdateForm } = props;
  const { adharNumber } = useSelector((state) => state?.companyReducer);

  const localImage = useRef();
  const billImage = useRef();
  const aadharRef = useRef();
  const singnatureRef = useRef();
  const photoRef = useRef();
  const [aaadharNumber, setAdharNumber] = useState("");
  const [identification, setIdentification] = useState(
    props?.formData || initialValues
  );

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };

  const handleSubmit = (values) => {
    const obj = {
      aadharNumber: aaadharNumber,
      PanNumber: PanNumber,
      singnatureImageUrl: singnatureRef?.current?.files[0],
      aadharImageUrl: aadharRef?.current?.files[0],
      passportSizePhoto: photoRef?.current?.files[0],
      localImage: localImage?.current?.files[0],
      billImage: billImage?.current?.files[0],
    };
    props.updateFormData("identiFication", obj);
    props.setKey("Income", values);
  };

  const { PanNumber, aadharNumber } = identification;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="aadharNumber">
                Aadhar Number
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                id="aadharNumber"
                type="number"
                required
                placeholder="Aadhar Card Number"
                onChange={(e) => setAdharNumber(e.target.value)}
                // value={adharNumber || props?.customerData?.aadharNumber}
                value={adharNumber || aadharNumber}
              />
            </CCol>
            {/* <CCol xs={6}>
              <CFormLabel htmlFor="aadharImageUrl">
                Upload Aadhar Card
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={aadharRef}
                id="aadharImageUrl"
                placeholder="Upload Aadhar Card"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol> */}
          </CRow>
          {/* <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="passportSizePhoto">
                Upload Passport Size Photo{" "}
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={photoRef}
                id="passportSizePhoto"
                placeholder="Upload Passport Size Photo"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="singnatureImageUrl">
                Upload Signature
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={singnatureRef}
                id="singnatureImageUrl"
                placeholder="Upload Singnature"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow> */}

          {/* <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="localImage">
                Upload local Image
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={localImage}
                id="localImage"
                placeholder="Upload localImage"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="singnatureImageUrl">
                Upload Bill
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={billImage}
                id="billImage"
                placeholder="Upload Bill"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow> */}
          <div className="identification_btn">
            <CButton type="submit" className="next_btn">
              Next{" "}
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
IdentiFicationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  isUpdateForm: PropTypes.bool,
  customerData: PropTypes.object,
};
export default IdentiFicationForm;
