import { CButton, CSpinner } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FinalDetailsForm from "../customer/finalDetailsForm";
import { useReactToPrint } from "react-to-print";

const NomineeGroup = (props) => {
  const handleSubmit = () => {
    props.handleFormSubmit();
    setAddSppiner(true);
  };

  const [printState, setPrintState] = useState(false);
  const [addSppiner, setAddSppiner] = useState(false);
  const componentPDF = useRef();

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const handlePrint = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  return (
    <div>
      <FinalDetailsForm
        formdata={props?.formdata}
        componentPDF={componentPDF}
        printState={printState}
        setPrintState={setPrintState}
      />
      <div className="identification_btn">
        <CButton
          style={{ margin: "0px -9px 18px 100px" }}
          className="next_btn"
          onClick={handlePrint}
        >
          Download
        </CButton>
        {!addSppiner ? (
          <CButton
            style={{ margin: "0px -9px 18px 100px" }}
            className="next_btn"
            onClick={handleSubmit}
          >
            Submit Form{" "}
          </CButton>
        ) : (
          <CButton
            style={{ margin: "0px -9px 18px 100px" }}
            className="next_btn"
          >
            <CSpinner color="dark" />
          </CButton>
        )}
      </div>
    </div>
  );
};
NomineeGroup.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  emiList: PropTypes.array.isRequired,
  sub: PropTypes.bool.isRequired,
};
export default NomineeGroup;
