import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: "https://your-dsn@sentry.io/your-project-id",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>
);

reportWebVitals();
