import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import { formatDate, getS3Url } from "src/services/helpers";
import PaymentModel from "../CustomerDetails/paymentModal";
import { getCustomerByMobile } from "src/api/customerApis";
import { getLoanId } from "src/redux/actions/customerAction";
import authInstance from "src/api/apiConfig";
import { successAlert } from "src/utils/Swal";
import Swal from "sweetalert2";
import { COMPANY_NAME } from "src/services/const";

const EmiDetails = () => {
  const dispatch = useDispatch();

  const { loandetails } = useSelector((state) => state?.customerReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const { emiData } = useSelector((state) => state?.emiReducer);
  const [showDetails, setShowDetails] = useState(false);
  const [showAllBtn, setShowAllBtn] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dueData, setDueData] = useState(null);
  const componentPDF = useRef();
  const [printState, setPrintState] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [phone, setPhone] = useState("");
  const [newCustomerData, setNewCustomerData] = useState(null);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [selectedMode, setSelectedMode] = useState("");
  const loandata = loandetails?.data;
  const [customerloanId, setCustomerLoanId] = useState();
  const [filteredEmi, setFilteredEmi] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const [editableInput, setEditableInput] = useState("");
  const [editType, setEditype] = useState("");
  const [allSelectedData, setAllSelectedData] = useState(null);
  const [totalPayment, setTotalPayment] = useState(0);
  const [newEmiData, setNewEmiData] = useState();
  const [currSelect, setCurrSelect] = useState(null);
  const rowsPerPage = 10;

  useEffect(() => {
    setCustomerLoanId(loandata);
  }, [loandata, refresh]);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const download_btn = () => {
    setPrintState(true);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handlePayment = (data) => {
    setSelectedMode("pay");
    setDueData(data);
    setVisible(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredEmi?.payments?.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  useEffect(() => {
    if (refresh === true) {
      handleSubmit();
    }
  }, [refresh]);

  const handleSubmit = () => {
    const payload = {
      schemaName: SchemaName,
      phoneNumber: phone,
    };
    getCustomerByMobile(payload)
      .then((res) => {
        setNewCustomerData(res?.data);
        const obj = {
          schemaName: SchemaName,
          customerId: res?.data?.id,
        };
        dispatch(getLoanId(obj));
        setShowDetails(true);
      })
      .catch((err) => console.log("error 81", err));
  };


  const handleSelectLoan = (data, i) => {
    setLoanId(data?.id);
    setFilteredEmi(data);
    setShowAllBtn(true);
    setShowTable(true);
    setCurrSelect(i);
  };

  useEffect(() => {
    setFilteredEmi(loandetails != null && loandetails?.data[0]);
  }, [loandetails]);

  const handleDiscount = () => {
    setVisible(true);
    setSelectedMode("discount");
  };

  const handlePay = () => {
    setVisible(true);
    setSelectedMode("pay");
  };

  const handlePenalty = () => {
    setVisible(true);
    setSelectedMode("penalty");
  };

  const role = sessionStorage.getItem("role");

  const handleEditAmount = async (data, type) => {
    if (role === "employee") {
      return;
    }
    setAllSelectedData(data);
    setEditype(type);
    if (type === "extendedDate") {
      setEditableInput(data?.extended_date)
    } else if (type === "date") {
      setEditableInput(data?.paymentDate);
    } else if (type === "penalty") {
      setEditableInput(data?.penaltyAmount);
    } else if (type === "amount") {
      setEditableInput(data?.paymentAmount);
    } else if (type === "status") {
      setEditableInput(data?.status);
    } else {
      setEditableInput(data?.discount);
    }
    setOpenmodal(true);
  };

  const handleSubmitEditForm = async () => {
    let keyToSet =
      editType === "date"
        ? "paymentDate"
        : editType === "penalty"
          ? "penaltyAmount"
          : editType === "amount"
            ? "paymentAmount"
            : editType === "status"
              ? "status"
              : editType === "extended_date"
                ? "extended_date"
                : "discount";
    const payload = {
      [keyToSet]: editableInput,
      schemaName: SchemaName,
    };
    const response = await authInstance.patch(
      `${editType === "extended_date" ? 'emi' : 'payment'}/${allSelectedData?.id}`,
      payload
    )

    console.log("response sdf", response)

    if (response.status === 201) {
      const obj = {
        schemaName: SchemaName,
        customerId: newCustomerData?.id,
      };
      dispatch(getLoanId(obj));
      successAlert(`${[keyToSet]} successfully updated`);
      setOpenmodal(false);
    }
  };

  useEffect(() => {
    const emiTotal = filteredEmi?.emis?.reduce((accumulator, payment) => {
      return accumulator + (Number(payment.emiAmount) || 0);
    }, 0);

    const paymentTotal = filteredEmi?.payments?.reduce(
      (accumulator, payment) => {
        return accumulator + (Number(payment.paymentAmount) || 0);
      },
      0
    );

    const discountTotal = filteredEmi?.payments?.reduce(
      (accumulator, payment) => {
        return accumulator + (payment?.discount || 0);
      },
      0
    );

    const total =
      emiTotal + totalPenaltyAmount - (paymentTotal + discountTotal);
    setTotalPayment(total);
  }, [filteredEmi]);

  const verifyData = (company) => {
    Swal.fire({
      title: "Are you sure you want to verify this payment?",
      showCancelButton: true,
      confirmButtonText: "Verify",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          status: "paid",
          schemaName: SchemaName,
        };
        const response = await authInstance.patch(
          `payment/${company?.id}`,
          payload
        );
        if (response.status === 201) {
          const obj = {
            schemaName: SchemaName,
            customerId: newCustomerData?.id,
          };
          dispatch(getLoanId(obj));
          successAlert(`status successfully updated`);
          setOpenmodal(false);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const parseAddress = (address) => {
    try {
      const parsedAddress = JSON.parse(address);
      if (typeof parsedAddress === "object") {
        return `${parsedAddress.area ? parsedAddress.area + ", " : ""}${parsedAddress.district ? parsedAddress.district + ", " : ""
          }${parsedAddress.city ? parsedAddress.city + ", " : ""}${parsedAddress.state ? parsedAddress.state : ""
          }${parsedAddress.country ? ", " + parsedAddress.country : ""}${parsedAddress.pincode ? ", " + parsedAddress.pincode : ""
          }`;
      }
    } catch (error) {
      console.log("error 280 Emi-Details", error?.message)
    }
    return address;
  };

  const parseAddress1 = (address) => {
    try {
      const parsedAddress = JSON.parse(address);
      if (typeof parsedAddress === "object") {
        return `${parsedAddress.area1 ? parsedAddress.area1 + ", " : ""}${parsedAddress.district1 ? parsedAddress.district1 + ", " : ""
          }${parsedAddress.city1 ? parsedAddress.city1 + ", " : ""}${parsedAddress.state1 ? parsedAddress.state1 : ""
          }${parsedAddress.country1 ? ", " + parsedAddress.country1 : ""}${parsedAddress.pincode1 ? ", " + parsedAddress.pincode1 : ""
          }`;
      }
    } catch (error) {
      console.log("error 295 Emi-Details", error?.message)
    }
    return address;
  };

  const parseAddress2 = (address) => {
    try {
      const parsedAddress = JSON.parse(address);
      if (typeof parsedAddress === "object") {
        return `${parsedAddress.area2 ? parsedAddress.area2 + ", " : ""}${parsedAddress.district2 ? parsedAddress.district2 + ", " : ""
          }${parsedAddress.city2 ? parsedAddress.city2 + ", " : ""}${parsedAddress.state2 ? parsedAddress.state2 : ""
          }${parsedAddress.country2 ? ", " + parsedAddress.country2 : ""}${parsedAddress.pincode2 ? ", " + parsedAddress.pincode2 : ""
          }`;
      }
    } catch (error) {
      console.log("error 310 Emi-Details", error?.message)
    }
    return address;
  };

  const formattedAddress = parseAddress(newCustomerData?.address);
  const formattedAddress1 = parseAddress1(newCustomerData?.address);
  const formattedAddress2 = parseAddress2(newCustomerData?.address);

  useEffect(() => {
    const emisStatus = filteredEmi?.emis?.filter(
      (company) => company?.emiStatus === "pending"
    );
    setNewEmiData(emisStatus);
  }, [filteredEmi, refresh]);

  const totalPenaltyAmount = filteredEmi?.emis?.reduce(
    (accumulator, payment) => {
      return accumulator + (Number(payment?.penalty) || 0);
    },
    0
  );
  const sortedEmiData = newEmiData?.sort(
    (a, b) => new Date(a?.dueDate) - new Date(b?.dueDate)
  );

  const filterEmiForDate = filteredEmi?.emis?.length
    ? filteredEmi.emis.sort((a, b) => new Date(a?.dueDate) - new Date(b?.dueDate))
    : [];

  const filterPayments = currentRows?.length
    ? currentRows?.sort((a, b) => new Date(b?.paymentDate) - new Date(a?.paymentDate))
    : [];

  const emiDueData = filterEmiForDate.length ? filterEmiForDate[0]?.dueDate : 'No due date available';

  const allEmisPaid = filteredEmi?.emis?.every(emi => emi.emiStatus === 'paid');

  const AllEmiPaidBalance = (role === "employee" && allEmisPaid && totalPayment > 0)

  return (
    <div className="content">
      <CModal
        scrollable
        visible={openmodal}
        onClose={() => setOpenmodal(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel2">{`${newCustomerData?.firstName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={10}>
              <CFormInput
                type={editType === "date" || editType === "extended_date" ? "date" : "text"}
                value={editableInput}
                placeholder={`${editableInput}`}
                onChange={(e) => setEditableInput(e.target.value)}
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton onClick={handleSubmitEditForm} color="primary">
            Payment
          </CButton>
        </CModalFooter>
      </CModal>
      {visible && (
        <PaymentModel
          selectedMode={selectedMode}
          totalPayment={totalPayment}
          visible={visible}
          setVisible={setVisible}
          setRefresh={setRefresh}
          customerData={newCustomerData}
          emiData={emiData}
          dueData={dueData}
          loanId={loanId}
          emidetail={filteredEmi?.emis}
          AllEmiPaidBalanceAmount={AllEmiPaidBalance}
        />
      )}
      <CCard>
        <CCardHeader className="d-flex ">
          <div className="mr-10">
            <strong>EMI Record</strong>
          </div>
          <div className="d-flex">
            <div>
              <CButton
                className="add-btn mr-10 w-auto"
                onClick={download_btn}
              >
                Download
              </CButton>
              {currentRows && (
                <CSVLink
                  data={currentRows === undefined ? " " : currentRows}
                  filename={"emiRecord-table.csv"}
                >
                  <CButton className="add-btn w-auto mr-10">CSV</CButton>
                </CSVLink>
              )}
              <CButton
                className="add-btn m-0 w-auto"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Back
              </CButton>
            </div>

          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            {!showDetails ? (
              <CRow>
                <CCol xs={4}>
                  <CFormInput
                    placeholder="Enter your Phone Number/Aadhar Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </CCol>

                <CCol xs={2}>
                  <CButton onClick={handleSubmit}>Search</CButton>
                </CCol>
              </CRow>
            ) : (
              <CRow>
                <CCol xs={4}>
                  <div>
                    <span className="lable-name">Select Loan :</span>
                    <CFormSelect
                      value={currSelect}
                      onChange={(e) =>
                        handleSelectLoan(
                          customerloanId[e.target.value],
                          e.target.value
                        )
                      }
                    >
                      <option value={""}>Select Loan</option>
                      {customerloanId?.map((item, index) => (
                        <option key={index} value={index}>
                          Date:{" "}
                          {new Date(item?.approvalDate)?.toLocaleDateString()},
                          Amount: {item?.approvalAmount}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>

                {showAllBtn && (
                  <CCol
                    lg={8}
                    xs={5}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      height: "45px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {(role === "employee" && allEmisPaid && totalPayment > 0) && (
                      <CButton
                        style={{ p: "0px" }}
                        onClick={handlePay}
                        disabled={totalPayment <= 0}
                      >
                        Payment
                      </CButton>
                    )}
                    {role !== "employee" && (
                      <>
                        <CButton style={{ p: "0px" }} onClick={handlePay} disabled={totalPayment <= 0}>Payment</CButton>
                        <CButton onClick={handlePenalty}>Penalty</CButton>
                        <CButton onClick={handleDiscount}>Discount</CButton>
                      </>
                    )}
                  </CCol>
                )}
              </CRow>
            )}
          </div>
          <div className="serch_bar"></div>

          <div
            ref={printState ? componentPDF : null}
            style={{ padding: printState ? "20px" : "0px" }}
          >
            {printState && (
              <div className="print-div">
                <div>
                  {/* <Vakilkaro /> */}
                  <h3>Riyms</h3>
                </div>
                <div>
                  <p>{COMPANY_NAME}</p>
                </div>
              </div>
            )}

            {showDetails && (
              <div
                className="customer_details d-flex flex-row"
                ref={componentPDF}
              >
                <div style={{ width: "85%" }}>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Name : </span>
                      <span className="content-name">{`${newCustomerData?.firstName}`}</span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Father Name : </span>
                      <span className="content-name">{`${newCustomerData?.fatherName}`}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Grand Father Name : </span>
                      <span className="content-name">{`${newCustomerData?.grandFatherName}`}</span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Address : </span>
                      <span className="content-name">
                        {formattedAddress}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Address1 : </span>
                      <span className="content-name">
                        {formattedAddress1}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Address2 : </span>
                      <span className="content-name">
                        {formattedAddress2}
                      </span>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Aadhar Number : </span>
                      <span className="content-name">
                        {newCustomerData?.aadharNumber}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">
                        Account Created Date :{" "}
                      </span>
                      <span className="content-name">
                        {formatDate(newCustomerData?.createdAt?.split("T")[0])}
                      </span>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Phone Number : </span>
                      <span className="content-name">
                        {newCustomerData?.phoneNumber}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Branch : </span>
                      <span className="content-name">
                        {newCustomerData?.branch.branchName}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Loan Account Number :</span>
                      <span className="content-name">
                        {newCustomerData?.loanAccountNumber}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Branch Code: </span>
                      <span className="content-name">
                        {newCustomerData?.branch.branchCode}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={6}>
                      <span className="lable-name">Disbursement Amount : </span>
                      <span className="content-name">
                        {filteredEmi?.disbursements?.[0]?.DisbursementAmount}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Disbursement Date : </span>
                      <span className="content-name">
                        {formatDate(
                          filteredEmi?.disbursements?.[0]?.DisbursementDate
                        )}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">EMI Date : </span>
                      <span className="content-name">
                        {formatDate(emiDueData)}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Penalty Amount : </span>
                      <span className="content-name">{totalPenaltyAmount}</span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">Discount Amount : </span>
                      <span className="content-name">
                        {filteredEmi?.payments?.reduce(
                          (accumulator, payment) => {
                            return accumulator + (payment.discount || 0);
                          },
                          0
                        )}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">
                        Total Payment Collected :{" "}
                      </span>
                      <span className="content-name">
                        {filteredEmi?.payments?.reduce(
                          (accumulator, payment) => {
                            return (
                              accumulator + (Number(payment.paymentAmount) || 0)
                            );
                          },
                          0
                        )}
                      </span>
                    </CCol>

                    <CCol xs={6}>
                      <span className="lable-name">No of EMIs : </span>
                      <span className="content-name">
                        {filteredEmi?.numberOfEmis || "-"}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">EMIs Amount : </span>
                      <span className="content-name">
                        {filteredEmi?.emis?.reduce((accumulator, payment) => {
                          return Number(payment.emiAmount) || 0;
                        }, 0)}
                      </span>
                    </CCol>
                    <CCol xs={6}>
                      <span className="lable-name">
                        Total Loan with Interest :{" "}
                      </span>
                      <span className="content-name">
                        {filteredEmi?.emis?.reduce((accumulator, payment) => {
                          return accumulator + (Number(payment.emiAmount) || 0);
                        }, 0)}
                      </span>
                    </CCol>

                    <CCol xs={6}>
                      <span className="lable-name">Balance Amount : </span>
                      <span className="content-name">{totalPayment}</span>
                    </CCol>
                  </CRow>
                </div>
                <div
                  className="d-flex justify-content-start"
                  style={{ width: "15%" }}
                >
                  {newCustomerData?.passportSizePhoto ? (
                    <img
                      src={getS3Url(newCustomerData?.passportSizePhoto)}
                      width="100px"
                      height="125px"
                      alt="Default"
                    />
                  ) : (
                    <img src="/user-logo.png" width="100px" alt="Default" />
                  )}
                </div>
              </div>
            )}
            <div>
              {showTable && (
                <>
                  <div style={{ display: "flex", gap: "40px" }}>
                    <div style={{ width: "65%" }}>
                      <div style={{ margin: "10px 0px", fontSize: "16px", fontWeight: "600" }}>
                        Due Payments
                      </div>
                      <CTable hover className="" responsive>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell scope="col" className="col-md-2">
                              <span>S. No.</span>
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col" className="col-md-3">
                              <span>Due Date</span>
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col" className="col-md-3">
                              <span> EMI Amount</span>
                            </CTableHeaderCell>

                            <CTableHeaderCell
                              scope="col"
                              className="col-md-3"
                            >
                              <span>Penalty</span>
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              scope="col"
                              className="col-md-3"
                            >
                              <span>Extended Date</span>
                            </CTableHeaderCell>
                            {!printState && (
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-4"
                              >
                                <span>Action</span>
                              </CTableHeaderCell>
                            )}
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {sortedEmiData?.length > 0 &&
                            sortedEmiData?.map((company, index) => {
                              return (
                                <CTableRow key={index}>
                                  <CTableDataCell>{index + 1}</CTableDataCell>
                                  <CTableDataCell>
                                    {formatDate(company?.dueDate)}
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    {company.emiAmount}
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    {company?.penalty ? company.penalty : "--"}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEditAmount(company, "extended_date")}>
                                    {company?.extended_date ? formatDate(company?.extended_date) : "--"}
                                  </CTableDataCell>
                                  {!printState && (
                                    <CTableDataCell>
                                      {company?.emiStatus === "pending" ? (
                                        <CButton
                                          onClick={() => handlePayment(company)}
                                        >
                                          Pay
                                        </CButton>
                                      ) : (
                                        ""
                                      )}
                                    </CTableDataCell>
                                  )}
                                </CTableRow>
                              )
                            })}
                        </CTableBody>
                      </CTable>
                    </div>

                    <div style={{ width: "35%" }}>
                      <div
                        style={{
                          margin: "10px 0px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Penalties
                      </div>
                      <CTable hover className="" responsive>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell
                              scope="col"
                              className="col-md-2"
                            >
                              <span>S. No.</span>
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              scope="col"
                              className="col-md-3"
                            >
                              <span>Due Date</span>
                            </CTableHeaderCell>

                            <CTableHeaderCell
                              scope="col"
                              className="col-md-3"
                            >
                              <span>Penalty</span>
                            </CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {filteredEmi?.emis?.length > 0 &&
                            filteredEmi?.emis
                              ?.filter((ele, i) => ele?.penalty !== null)
                              ?.map((company, index) => (
                                <CTableRow key={index}>
                                  <CTableDataCell>{index + 1}</CTableDataCell>
                                  <CTableDataCell>
                                    {formatDate(company?.dueDate)}
                                  </CTableDataCell>

                                  <CTableDataCell>
                                    {company?.penalty
                                      ? company.penalty
                                      : "--"}
                                  </CTableDataCell>
                                </CTableRow>
                              ))}
                        </CTableBody>
                      </CTable>
                    </div>
                  </div>
                  <div style={{ margin: "10px 0px", fontSize: "16px", fontWeight: "600" }}>
                    Payments
                  </div>
                  <CTable hover className="" responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col" className="">
                          <span className="">Sr No.</span>
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="">
                          <span className="">Payment Date</span>
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="">
                          <span className=""> Payment Amount</span>
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="">
                          <span className="">Discount</span>
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="">
                          <span className="">Payment Mode</span>
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="">
                          <span className="">Payment Status</span>
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {filterPayments?.map((company, index) => (
                        <CTableRow key={index}>
                          <CTableDataCell>{index + 1}</CTableDataCell>
                          <CTableDataCell
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditAmount(company, "date")}
                          >
                            {formatDate(company?.paymentDate)}
                          </CTableDataCell>

                          <CTableDataCell
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditAmount(company, "amount")}
                          >
                            {company?.paymentAmount ? company?.paymentAmount : "-"}
                          </CTableDataCell>
                          <CTableDataCell
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditAmount(company, "discount")}
                          >
                            {company?.discount ? company?.discount : "-"}
                          </CTableDataCell>
                          <CTableDataCell>                            {company?.paymentMode ? company?.paymentMode : "-"} </CTableDataCell>
                          {role === "employee" ? (
                            <CTableDataCell>{company?.status ? company?.status : "-"}</CTableDataCell>
                          ) : (
                            <CTableDataCell
                              style={{ cursor: company?.status === "pending" && "pointer" }}
                              onClick={company?.status === "pending" ? () => verifyData(company) : null}
                            >
                              {company?.status ? company?.status : "-"}
                            </CTableDataCell>
                          )}
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </>
              )}
            </div>
            {!printState && (
              showTable && (
                <div style={{ display: "flex", justifyContent: "space-between" }}                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, filteredEmi?.payments?.length)} of{" "}
                    {filteredEmi?.payments?.length} entries
                  </div>
                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      totalItemsCount={filteredEmi?.payments?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              ))}
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EmiDetails;
