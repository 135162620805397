import React, { useState } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import { createDirector } from "src/redux/actions/customerAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { successAlert } from "src/utils/Swal";

const DirectorAddForm = ({ setShowAddForm, setShowAddButton, setShowSelectAndSearch }) => {

  const SchemaName = sessionStorage.getItem("schemaName");
  const CompanyId = sessionStorage.getItem("companyId");
  const companyN = sessionStorage.getItem("companyN");

  const initialFormData = {
    schemaName: SchemaName,
    din: "",
    directorName: "",
    capital: 0,
    companyId: CompanyId,
    companyName: companyN,
    email: "",
    phoneNumber: "",
  };

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState([]);

  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };

  const validation = () => {
    if (formData.directorName == null || formData.directorName === "") {
      setError("Director Name cannot be empty!");
      return false;
    }
    if (
      formData.email == null ||
      formData.email === "" ||
      formData.email.trim().length === 0
    ) {
      setError("Email cannot be empty!");
      return false;
    }
    if (formData.phoneNumber == null || formData.phoneNumber === "") {
      setError("Contact Number cannot be empty!");
      return false;
    }
    if (formData.phoneNumber.length !== 10 || isNaN(formData.phoneNumber)) {
      setError("Please enter a valid 10-digit phone number!");
      return false;
    }
    if (formData.din == null || formData.din === "") {
      setError("DIN Number cannot be empty!");
      return false;
    }
    return true;
  };

  const handleAddForm = (e) => {
    e.preventDefault();
    if (validation()) {
      dispatch(createDirector(formData));
      successAlert("Success", "New Director Successfully Created");
      const obj = {
        schemaName: SchemaName,
      };
      setShowAddButton(true);
      setShowAddForm(false);
      setShowSelectAndSearch(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    if (name === "phoneNumber") {
      sanitizedValue = value.replace(/\D/g, "");
    }

    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const { din, directorName, email, phoneNumber } = formData;
  return (
    <CCard>
      <CCardHeader>
        <div className="close_btn p-0">
          <div>
            <strong>Add Director</strong>
          </div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleShowAddButton()}
          ></button>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm onSubmit={(e) => handleAddForm(e)}>
          <CRow>
            <h6 className="color_heading">Note : (please do not refresh the page)</h6>
            <CCol xs={12}>
              <CRow className="row_margin">
                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="dri_name">
                      Director Name<span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Director Name"
                      id="dri_name"
                      name="directorName"
                      value={directorName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="email">
                      Email Address<span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="email"
                      placeholder="Email Address"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="dir_contact">
                      Contact Number<span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Contact Number"
                      id="dir_contact"
                      name="phoneNumber"
                      maxLength={10}
                      minLength={10}
                      value={phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="dri_iden_number">
                      Director Identification Number (DIN)
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Director Identification Number "
                      id="dri_iden_number"
                      name="din"
                      value={din}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={12}>
                  <h6
                    style={{
                      color: "#FF0000",
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </h6>
                </CCol>
              </CRow>
              <div className="branch_btn">
                <CButton type="submit">Submit</CButton>
              </div>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};
DirectorAddForm.propTypes = {
  setShowAddForm: PropTypes.func.isRequired,
  setShowAddButton: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  callBack: PropTypes.func,
};

export default DirectorAddForm;
