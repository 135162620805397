import { CCardBody, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import VerificationForm from "./verification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { verifiedData } from "src/redux/actions/userAction";

const CustomerListForVeriFication = () => {
  const dispatch = useDispatch();
  const { AllVerifiedData, newmanagerverifiedData, newstaffverifiedData } = useSelector((state) => state?.userReducer);
  const [selectedData, setSelectedData] = useState(null);
  const [showVerification, setShowVerification] = useState(false);

  useEffect(() => {
    const SchemaName = sessionStorage.getItem("schemaName");
    const payload = { schemaName: SchemaName };
    dispatch(verifiedData(payload));
  }, [newmanagerverifiedData, newstaffverifiedData]);

  const handleShowVerificationForm = (data) => {
    setSelectedData(data);
    setShowVerification(true);
  };

  const roleType = sessionStorage.getItem("role");
  const forCompany = AllVerifiedData?.data?.filter(
    (value, i) => value?.status === false
  );
  const forStaffData = AllVerifiedData?.data?.filter(
    (value, i) => value?.verifiedByStaff === false
  );
  const forManagerData = AllVerifiedData?.data?.filter(
    (value, i) => value?.status === false
  );

  return (
    <div className="content">
      <CCardBody>
        {showVerification === true ? (
          <VerificationForm
            selectedData={selectedData}
            setShowVerification={setShowVerification}
          />
        ) : (
          <CCardBody>
            <CTable color="dark" hover className="" responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell className="">S. No.</CTableHeaderCell>
                  <CTableHeaderCell className="">
                    Loan Account No
                  </CTableHeaderCell>
                  <CTableHeaderCell className="">Member Name</CTableHeaderCell>
                  <CTableHeaderCell className="">Father Name</CTableHeaderCell>

                  <CTableHeaderCell className="">Email</CTableHeaderCell>

                  <CTableHeaderCell className="">Mobile</CTableHeaderCell>
                  {roleType === "company" ? (
                    <></>
                  ) : (
                    <CTableHeaderCell className="">Action</CTableHeaderCell>
                  )}
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {(roleType === "manager"
                  ? forManagerData
                  : roleType === "employee"
                    ? forStaffData
                    : forCompany
                )?.map((company, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>
                      {company?.customer?.loanAccountNumber}
                    </CTableDataCell>
                    <CTableDataCell>
                      {company?.customer?.firstName}{" "}
                      {company?.customer?.middleName === "undefined"
                        ? ""
                        : company?.customer?.middleName}{" "}
                      {company?.customer?.lastName}
                    </CTableDataCell>
                    <CTableDataCell>
                      {company?.customer?.fatherName}
                    </CTableDataCell>
                    <CTableDataCell>{company?.customer?.email}</CTableDataCell>
                    <CTableDataCell>
                      {company?.customer?.phoneNumber}
                    </CTableDataCell>
                    {roleType === "company" ? (
                      <></>
                    ) : (
                      <CTableDataCell className="col-md-1">
                        <button
                          style={{ gap: "2px" }}
                          className="view-button btn_view ver_btn_"
                          onClick={() => handleShowVerificationForm(company)}
                        >
                          verify
                        </button>
                      </CTableDataCell>
                    )}
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCardBody>
        )}
      </CCardBody>
    </div>
  );
};

export default CustomerListForVeriFication;
