import { CButton, CCol, CForm, CFormInput, CRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { emptyVerifiedData, updateVerificationUser } from "src/redux/actions/userAction";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const initialValues = {
  managerVerificationImage: null,
  remark: "",
};
const ManagerVerification = ({ selectedData, setShowVerification }) => {
  const dispatch = useDispatch();
  const { newmanagerverifiedData } = useSelector((state) => state?.userReducer);
  const [identification, setIdentification] = useState(initialValues);
  const managerVerificationImageRef = useRef();
  const SchemaName = sessionStorage.getItem("schemaName");
  const roleId = sessionStorage.getItem("id");
  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("status", true);
    formData.append("verificationType", remark);
    formData.append("managerId", roleId);
    formData.append(
      "managerVerificationImage",
      managerVerificationImageRef?.current?.files[0]
    );

    const obj = {
      id: selectedData?.id,
      data: formData,
    };
    dispatch(updateVerificationUser(obj));
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };

  const { remark } = identification;
  useEffect(() => {
    if (newmanagerverifiedData?.status === "success") {
      dispatch(emptyVerifiedData())
      setShowVerification(false);
    }
  }, [newmanagerverifiedData]);

  return (
    <div>
      <div>
        <h4 className="card-title">Customer Information</h4>
      </div>
      <div className="staffform_header"></div>
      <div className="cus_data">
        <CRow>
          <CCol xs={6}>
            <div>
              <span className="cus_details">name :</span>
              <span className="cus_ap">{`${selectedData?.customer?.firstName} ${selectedData?.customer?.lastName}`}</span>
            </div>
            <div>
              <span className="cus_details">phone Number :</span>
              <span className="cus_ap">
                {selectedData?.customer?.phoneNumber}
              </span>
            </div>
          </CCol>
          <CCol xs={6}>
            <div>
              <span className="cus_details">Verified By Staff </span>
              <span className="cus_details">Name :</span>

              <span className="cus_ap">{`${selectedData?.staff?.firstName} ${selectedData?.staff?.middleName
                ? selectedData?.staff?.middleName
                : ""
                } ${selectedData?.staff?.lastName}`}</span>
            </div>
            <div>
              <span className="cus_details">Verified By Staff </span>
              <span className="cus_details"> Phone Number :</span>
              <span className="cus_ap">{selectedData?.staff?.phoneNumber}</span>
            </div>
          </CCol>
        </CRow>
      </div>
      <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
        <CRow className="pt-20">
          <CCol>
            <CFormInput
              type="file"
              ref={managerVerificationImageRef}
              id="managerVerificationImage"
              onChange={onChangeValue}
            />
          </CCol>
          <CCol>
            <CFormInput
              type="text"
              value={remark}
              placeholder="Enter a remark"
              name="remark"
              id="remark"
              onChange={onChangeValue}
            />
          </CCol>
        </CRow>
        <div className="btn_verify">
          <CRow>
            <CCol xs={12} className="d-flex justify-content-center">
              <CButton type="submit" color="primary" className="px-4">
                Verify
              </CButton>
            </CCol>
          </CRow>
        </div>
      </CForm>
    </div>
  );
};

ManagerVerification.propTypes = {
  selectedData: PropTypes.object,
  setShowVerification: PropTypes.func,
};

export default ManagerVerification;

