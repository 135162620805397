import React from "react";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";
import { Page, Text, Document, View, StyleSheet } from "@react-pdf/renderer";
import { Table } from "react-bootstrap";
import { getS3Url } from "src/services/helpers";

const Styles = StyleSheet.create({
  page: {},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  general_section: {
    display: "flex",
    justifyContent: "space-between",
  },
  general_right: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
  },
  form_heading: {
    paddingTop: "3%",
    fontSize: 14,
  },
  body_text: {
    fontSize: 14,
    lineHeight: 0.5,
  },
  accept: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: "5%",
  },
  prepayment: {
    border: 1,
    borderWidth: 2,
    borderColor: "#000000",
  },
});

const PrintSantionLetterForm = ({
  setPrintSantialLetter,
  custData,
  emiiData,
}) => {
  const add = custData.address;
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const parsedAddress = JSON.parse(custData?.address);
  const parsedgurantor = JSON.parse(custData?.nominee);
  const passportSizePhoto = getS3Url(custData?.passportSizePhoto);

  const createdAt = "2023-12-02T09:47:23.424Z";

  const datePart = createdAt.split("T")[0];
  const pendingemi = emiiData.data.filter(
    (item) => item.emiStatus === "pending"
  );
  const paidemi = emiiData.data.filter((item) => item.emiStatus === "paid");
  const firstPendingEMI = pendingemi[0];

  return (
    <>
      <button className="btn btn-primary" onClick={handlePrint}>
        Download
      </button>
      <div className="close_btn">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setPrintSantialLetter(false)}
        ></button>
      </div>
      <div style={{ padding: "20px 6%" }}>
        <div style={{ textAlign: "end", padding: "0px 10%" }}>
          <h6>RIYMS MICRO FOUNDATION</h6>
          <div>
            <span>CIN-</span> U64990HR2022NPL107842
          </div>
        </div>
        <h6 style={{ textAlign: "center" }}>LOAN AGREEMENT</h6>
        <div style={{ padding: "10px 0px" }}>
          This loan Agreement shall be executed as of date between
        </div>
        <div>
          General. This agreement shall be binding upon acceptance by you, the
          Borrower. For the purpose of this Agreement, RIYMS MICRO FOUNDATION
          through its authorized director(s) Mr. PARDEEP KUMAR having its
          registered office at C/O PARDEEP KUMAR PLOT NO 146, WARD NO 1, SAFIDON
          JIND HR 126112 IN IN, shall be referred to as “Lender” and you shall
          be referred to asthe “Borrower” particularly described in the Schedule
          I. The Borrower and Lender shall collectively be referred to as
          “Parties”. Parties hereby agree as follows: Service. The lender shall
          render Services to the Borrower including but not limited to KYC
          verifications, credit verifications, loan processing, loan collection,
          Call Centre-Help desk and loan closure, etc. including facilitating in
          the process of collection of the due loan outstanding from the
          customer as a constituent of service rendered to logically close the
          loan account. The services as mentioned above are to be rendered in
          full by the Lender during the course of the loan contract/ up to the
          closure of loan facility as per provisions of the contract. Loan
          Disbursal. The Loan shall be granted by the Lender to the Borrower as
          mentioned in Schedule I in response to the request made by the
          Borrower through the application form and subject to appraisal. The
          Lender shall disburse the loan as per the terms set put herein and
          subject to applicable law. Extension of Loan. The loan is granted for
          the tenure mentioned in Schedule I, in the event that borrower wishes
          to seek an extension for the loan tenure and /or repayment, the
          borrower may be permitted to do so subject to payment of Extension
          Fee. The Extension shall be granted at the discretion of the Lender.
          The borrower shall pay all Interest rates and charges, default rate
          and other costs for the extended period on pro-rata basis. Borrower
          Representations & Warranties. Borrower represents, warrants and
          acknowledges (a) it is a citizen and a person resident in India, (b)
          all information furnished in respect of the Facility are correct and
          the Borrower is a major, of sound mind and competent to contract; (c)
          that the Borrower has the authority to enter into and perform its
          obligations under this agreement; (d) to furnish all information of
          its finances and business as is required by the Lender; (e) it is not
          a party to any litigation and to the best of its knowledge, no act(s)
          or omission(s) has been committed that may give rise to any
          litigation; (f) that all repayments shall be in compliance of all
          applicable laws; (g) shall utilize the Loan for the purpose mentioned
          herein. Repayment and Overdue Interest/ Late Payment Repayment and
          Prepayment. The Borrower shall pay the Interest Rate as mentioned in
          the Schedule I. The Borrower understand that Interest Rate may change
          from time to time as per RBI guidelines and the same shall be
          effective prospectively only. The Borrower undertakes to pay the Loan
          outstanding on the Due Date as mentioned in the Schedule I and / or on
          such extended date as agreed herein. All amounts towards Interest Rate
          and other fees/charges mentioned in Schedule I shall be payable in
          accordance with the repayment schedule issued to the Borrower. The
          borrower can prepay the Loan amount in full during the term of the
          Loan, subject to payment of Prepayment charges as per Schedule I.
          Overdue Interest/ Late Payment. Failure to repay the Loan outstanding
          on the Due Date shall be treated as an event of default and the Lender
          shall be entitled to charge Late Payment Fee and Overdue Interest on
          the Loan at the rate mentioned in Schedule I. The Interest Rate,
          Overdue Interest and shall be calculated on 365/366 days based on the
          actual days elapsed. The Late Payment Fee shall be payable by the
          Borrower over and above Overdue Interest as per Schedule I. The
          Borrower shall unconditionally and irrevocably authorize the Lender
          through Electronic Clearing System (ECS) instruction// Electronic
          Mandate to debit the Borrower’s account towards the dues payable to
          the Lender. The Borrower further agrees to maintain sufficient balance
          in the account to enable his / her /its Banker to facilitate the debit
          of the account, and remittance of the amount so debited, to the credit
          of the Lender. The borrower shall absolve the Lender from any
          additional liability which may arise due to payment made by the
          borrower before clearance of electronic mandate. The Lender undertakes
          to reverse any duplicate payments received from the Borrower on
          account of dues payable to the Lender, without any additional
          charge/interest thereon. Consequences of Default. Over and above the
          aforementioned points, upon delay by the Borrower to pay outstanding
          amount on or before the Due Date, or breach of any representation or
          warranty, the Lender shall be entitled to , a) declare all sums
          outstanding under the Loan and other applicable charges to become
          immediately due and payable; (b) report the Borrower as a defaulter to
          Reserve Bank of India and other credit bureau agencies; (c) invoke
          Repayment Instruments or similar forms obtained from the Borrower,
          and/ or (d) exercise any other rights or remedy permitted by
          applicablelaw. Other Loan Conditions Appointment of Agents. The Lender
          shall be entitled to appoint and outsource its activities under this
          agreement to one or more service providers for the purpose of
          collecting KYC information, Loan related documents, collection,
          service etc. The Borrower understands and agrees that the services of
          such agents are essential for purpose of and effective enjoyment of
          the Loan. Indemnity: The Borrower shall indemnify the Lender, its
          directors, employees and agents against all costs, losses, and/ or
          damages due to any act or default on the part of the Borrower(s) in
          respect of the Loan and/or for the recovery of the outstanding dues on
          account of failure of the Borrower(s) of any of the terms and
          conditions herein and/or for any action undertaken by the Lender in
          good faith based on representations of the Borrower. Taxes: The
          Borrower shall pay all such present and future taxes, stamp duties,
          and other costs as may be applicable on the Loan. Notices/Grievances:
          Any notice under this Agreement must be in writing and shall be deemed
          to be received (a) on delivery, when delivered personally, and when
          sent from/ to the registered email id unless ‘delivery failure
          notification’ is received by the sender; (b) on the 3rd (third) day
          post-dispatch, when sent by registered post at the registered/ current
          address, postage prepaid. For the Lender, registered email id shall be
          riymsmicro@gmail.com .in and office address shall be RIYMS MICRO
          FOUNDATION, a company having its registered office at C/O PARDEEP
          KUMAR PLOT NO 146, WARD NO 1, SAFIDON JIND HR 126112 IN and addressed
          to Collection Manager at given address. Arbitration: In the unlikely
          event of any dispute or difference arising between the parties hereto
          as to the rights and obligations under this Agreement or as to any
          claim, monetary or otherwise of one party against the other or as to
          the interpretation and effect of any terms and conditions of this
          Agreement such dispute or difference shall be referred to the to a
          sole arbitrator who shall be nominated/ appointed by the Lender only.
          The applicable law shall be Indian laws. In the event of incapacity or
          resignation or death of the sole arbitrator so appointed, the Lender
          shall be entitled to appoint another in place of the earlier
          arbitrator, and the proceedings shall continue from the stage at which
          the predecessor had left. The award given by the arbitrator shall be
          final and binding on the parties to this Agreement. The cost of the
          Arbitration shall be borne with by the Party/ies, in accordance with
          the Award passed by the Arbitrator. The venue for such Arbitration
          shall be in India at Safidon, Jind-Haryana. The language to be used in
          the arbitral proceedings shall be English or Hindi. The JLG MEMBERS
          and Member hereby agree and confirm that the Lender shall be permitted
          to invoke the provisions of Indian Applicable Laws and any amendments
          thereto in order to recover its dues under this agreement from the JLG
          MEMBERS/ Member .The award rendered by the arbitrator shall be final
          and binding upon both Parties hereto and submit to the exclusive
          jurisdiction of the, situate at the place as specified in schedule I
          hereto. Assignment: The Borrower shall not be permitted to assign any
          of its rights and obligations under this Agreement. The Lender shall
          however be entitled and has the right to assign its rights and
          obligations under this Agreement. Notwithstanding any such assignment
          or transfer, the Borrower(s) shall, unless otherwise notified by the
          Lender, continue to make all payments under the loan terms to the
          Lender and all such payments when made to the Lender shall constitute
          a full discharge to the Borrower(s) from all its liabilities in
          respect of such payments. Disclosure: The Borrower consents for the
          disclosure by the Lender of any information relating to the Borrower
          and the Loan, as the Lender may deem appropriate to third parties
          (including RBI, credit bureaus, any other regulator). Waiver: The
          Parties agree that any delay or omission by the Lender in exercising
          any of its rights under this Agreement shall not be construed as
          waiver. The lender has the sole discretion to waive any fee/
          interest/commission in relation to the loan. Amendment: Subject to
          applicable laws, Lender shall have the sole discretion to amend any
          terms of this Agreement and Schedule I which shall be prospectively
          binding on the Borrower. Governing Law: This Agreement shall be
          governed in accordance with Indian laws and the Parties shall be
          subject to the exclusive jurisdiction of the courts at Safidon,
          Jind-Haryana. The Lender in addition shall have the right to file
          criminal complaint/FIR against the Borrower and/ or resort to other
          rights available under law.
        </div>
        <h6 style={{ padding: "20px 0px", textAlign: "center" }}>Schedule I</h6>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 10%",
          }}
        >
          <span>Borrower Details</span>
          <span> All Amount are in INR </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "0px 59px",
          }}
        >
          <span>
            Name of borrower{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${custData?.firstName} ${custData?.lastName}`}</span>
          </span>
          <span>
            Address of Borrower{" "}
            <span style={{ fontWeight: "600" }}>
              {`${parsedAddress.area}, ${parsedAddress.city},${parsedAddress.district
                }, ${parsedAddress.state - parsedAddress.pincode} ${parsedAddress.country
                }`}{" "}
            </span>{" "}
          </span>
          <span>
            PAN <span style={{ fontWeight: "600" }}>{custData?.PanNumber}</span>{" "}
          </span>
          <span>
            Email ID{" "}
            <span style={{ fontWeight: "600" }}>{custData?.email}</span>
          </span>
          <span>
            Phone number{" "}
            <span style={{ fontWeight: "600" }}>{custData?.phoneNumber}</span>
          </span>
          <span>Type of customer </span>
          <span>
            Nature of business/occupation{" "}
            <span style={{ fontWeight: "600" }}>{custData?.occupation}</span>
          </span>
          <h5>Loan Details</h5>
          <span>
            Loan Account ID{" "}
            <span style={{ fontWeight: "600" }}>
              {custData?.loanAccountNumber}
            </span>
          </span>
          <span>Loan Amount </span>
          <span>Nature of Loan ...... </span>
          <span>Approval fee* ...... </span>
          <span>
            Interest amount{" "}
            {/* <span style={{ fontWeight: "600" }}>
              {custData?.interest}
            </span> */}
          </span>
          <span>Service fee ** ...... </span>
          <span>Date of disbursal ...... </span>
          <span>Due date for scheduled repayment ...... </span>
          <span>EMI Amount ...... </span>
          <span>
            Term of the loan{" "}
            <span style={{ fontWeight: "600" }}>{custData?.loanTerm}</span>
          </span>
          <span>
            Total repayment due (Principal + Interest + Fee+ charges if any)
            ......{" "}
          </span>
          <span>Bank Account details ...... </span>
          <span>Account Holder Name (same as borrower) ...... </span>
          <span>
            Bank Name{" "}
            <span style={{ fontWeight: "600" }}>{custData?.bankName}</span>
          </span>
          <span>
            IFSC Code{" "}
            <span style={{ fontWeight: "600" }}>{custData?.ifscCode}</span>
          </span>
          <span>
            Address of the bank branch{" "}
            <span style={{ fontWeight: "600" }}>
              {custData?.branch?.address}
            </span>
          </span>

          <span>State ...... </span>
          <span>City ...... </span>
          <span>Date of loan contract Execution ...... </span>
          <span>
            Interest Rate{" "}
            <span style={{ fontWeight: "600" }}>
              {custData?.interest?.rate}
            </span>
          </span>
          <span>Late Payment Fee ...... </span>
          <span>Overdue Interest Rate ...... </span>
        </div>
        <div style={{ padding: "20px 0px" }}>
          * Approval Fee shall be deducted from the approved loan amount at the
          time of disbursal. ** Service Fee is computed as per the approved loan
          amount and tenure of the loan. In case the customer decides to pay
          partial outstanding amount/ close the loan before the due date by
          means of full repayment, service fee amount will be yet applicable as
          per the initial loan conditions. Note: In the event of the loan
          disbursal failing due to incorrect/ insufficient bank details as
          provided by the Borrower, this agreement shall be deemed as null and
          void.
        </div>
        <h6>
          IN WITNESS WHEREOF, THE PARTIES HERETO HAVE ACCEPTED THIS AGREEMENT
          INCLUDING TERMS AND CONDITIONS STATED ABOVE ON THE __________ DAY of
          ______________ MENTIONED BELOW.
        </h6>
        <div>Lender’s Details</div>
        <div>RIYMS MICRO FOUNDATION</div>
        <div style={{ display: "flex" }}>
          Authorized Person Name: <h6>Mr. PARDEEP KUMAR</h6>
        </div>
        <h6>PAN: ......</h6>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Father’s name: ......</span>
          <span>Address: ......</span>
          <span>Email -id ......</span>
        </div>
        <h6 style={{ margin: "15px 0px" }}>
          Sign with stamp: ________________
        </h6>
        <h5 style={{ margin: "15px 0px" }}>Borrower’s Details</h5>
        <span>
          Customer’s Name{" "}
          <span
            style={{ fontWeight: "600" }}
          >{`${custData?.firstName} ${custData?.lastName}`}</span>
        </span>
        <h6>
          PAN:{" "}
          <span style={{ fontWeight: "600" }}>{`${custData?.PanNumber}`}</span>
        </h6>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            Father’s name:{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${custData?.fatherName}`}</span>
          </span>
          <span>
            Address:{" "}
            <span style={{ fontWeight: "600" }}>
              {`${parsedAddress.area}, ${parsedAddress.city},${parsedAddress.district
                }, ${parsedAddress.state - parsedAddress.pincode} ${parsedAddress.country
                }`}{" "}
            </span>
          </span>
          <span>
            Email -id{" "}
            <span style={{ fontWeight: "600" }}>{`${custData?.email}`}</span>
          </span>
          <h6 style={{ margin: "15px 0px" }}>Sign: __________</h6>
        </div>
        <h5 style={{ margin: "15px 0px" }}>{"Guarantor's Details"}</h5>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            Name:{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${parsedgurantor?.GuarantorName}`}</span>
          </span>
          <span>
            PAN No:{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${parsedgurantor?.pan}`}</span>
          </span>
          <span>
            {"Father's name"}:{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${parsedgurantor?.fathersName}`}</span>
          </span>
          <span>
            Address:{" "}
            <span
              style={{ fontWeight: "600" }}
            >{`${parsedgurantor?.address}`}</span>
          </span>
          {/* <span>Email-Id: <span
              style={{ fontWeight: "600" }}
            >{`${parsedgurantor?.address}`}</span></span> */}
        </div>
        <h6 style={{ margin: "15px 0px" }}>Sign: ________</h6>
        <div>
          AGREEMENT FOR hire/borrow- PURCHASE OF A MOBILE PHONE THIS AGREEMENT
          made at SAFIDON ..this............ ...................day of
          .........., ..................202......, between RIYMS MICRO
          FOUNDATION Resident/Situated of SAFIDON (hereinafter called the
          owners) of the ONE PART and ..................................
          ..................................................resident of ...
          ......................
          ...............................................................................(hereinafter
          called the hire/borrower) of the OTHER PART. WHEREAS the owner is the
          owner of a mobile phone and the hire/borrower has approached the owner
          to let the said mobile phone on hire/borrower to him, to which the
          owner has agreed and has assured the hire/borrower that he shall have
          and enjoy quiet possession of the said phone. AND WHEREAS the
          hire/borrower has inspected the said phone and is satisfied about the
          same and considers it fit for the purposes for which he requires the
          same
        </div>
        <h6>NOW IT IS AGREED BETWEEN THE PARTIES AS FOLLOWS: </h6>
        <div>
          1. The owner will let and the hire/borrower will take on hire/borrower
          the Mobile phone
        </div>
        <div>
          2. The owner has delivered the said phone to the hire/borrower and has
          phone allowed the hire/borrower to use the said phone in the usual
          manner.
        </div>
        <div>
          3. During the continuance of hiring, the hire/borrower shall i. Not
          sell, assign, pledge, mortgage, underlet, lend or part with the
          possession of the said phone and not allow the said phone to be used
          by anybody else or kept or detained or run for the use of any other
          person. ii. Keep the said phone insured and kept insured so long as
          the hiring shall continue in the joint names of the owner and the
          hire/borrower against all risks and the hire/borrower shall pay all
          the premiums payable to the insurance company. On the hire/borrower
          becoming the owner of the said phone under the terms of this
          agreement, the owner will transfer to the hire/borrower the benefit of
          any insurance policy then current relating to the said phone. iii.
          Make good to the owner all damages to the said phone (fair wear and
          tear excepted) and pay the owner the full value of the said phone in
          the event of its total loss. iv. Not use or permit or suffer the said
          phone to be used in contravention of any law for the time being in
          force.
        </div>
        <div>
          4. If the hire/borrower shall duly observe and performs all the
          conditions herein contained and on his part to be observed and
          performed and shall pay to the owner the sum specified in clause 3
          hereof, together with all other sums if any payable by him to the
          owner under the provisions of this Agreement, then the hiring shall
          come to an end and the said phone shall become the property of the
          hire/borrower and the owner will assign and make over all his rights
          and interest in the same to the hire/borrower, but the hire/borrower
          shall have the option of purchasing the said phone at any time during
          the period of hiring by paying in one lump sum the balance of all the
          hire/borrower hereinbefore mentioned and other expenses incurred by
          the owner. Until all such payments as aforesaid have been made, the
          said phone shall remain the property of the owner. 5. If the
          hire/borrower shall make default i
        </div>
        <div>
          5. If the hire/borrower shall make default in payment of any monthly
          sum payable hereunder for ........ . days after the same have become
          due or shall fail to observe or perform any of the terms and
          conditions of this agreement, the owner may without prejudice to his
          claim for arrears of hire/borrower or damages (if any) for breach of
          this agreement block the phone completely by virtue of the software
          inducted in the phone or forthwith terminate the hiring and retake
          physical possession of the said phone himself or through his agents or
          servants and the hire/borrower shall not object to the retaking of
          possession of the said phone by the owner or his agents or servants
          and/or by written notice to the hire/borrower determine this agreement
          and the hiring hereby constituted. On such termination, the
          hire/borrower shall immediately return the said phone to the owner at
          his place of address for the time being.
        </div>
        <div>
          6. No neglect, delay or indulgence on the part of the owner in
          enforcing any terms or conditions of this agreement shall prejudice
          the rights of the owner hereunder.
        </div>
        <div>
          7. The agreement is personal to the hire/borrower and the rights of
          the hire/borrower shall not be assignable or chargeable by him in
          favour of third party.
        </div>
        <div>
          8. In the event of the hiring being determined by the hire/borrower or
          by the owner under clause 5 hereof, the hire/borrower shall forthwith
          return the said phone to the owner at the hire/borrower s expense. The
          determination of the hiring as aforesaid shall not affect or prejudice
          any claim the owner may have against the hire/borrower for arrears of
          hire/borrower payments or for damages for breach of this agreement or
          his right to enforce such claim by action or otherwise.
        </div>
        <div>
          9. The owner shall have access to the following features of the Phone
          till the entire amount is not paid: i. The owner will be technical
          device owner and device admin of the phone. ii. The owner can
          completely lock the device. iii. The owner will have access to phone
          number been used in the phone. The owner can request for location of
          the device. iv. The owner can auto play a audio file pre downloaded in
          the phone.
        </div>
        <div>
          10. The FRP account in the device will belong to the owner of the
          phone. The owner will be able to perform all the above controls either
          through push notification or through SMS s .
        </div>
        <div>
          11. The agreement shall determine if the hire/borrower commits any act
          of bankruptcy or makes any arrangement with his creditors or on
          presentation of a petition in the court for adjudicating the
          hire/borrower as an insolvent or on the appointment of a receiver of
          the properties of the hire/borrower or if an application is made by
          any creditor or other person against the hire/borrower for the
          attachment of the said phone.
        </div>
        <div>
          12. Any dispute or claim of any nature relating in any way to your use
          of any Services covered under this Agreement will be adjudicated
          through arbitration by a sole arbitrator to be appointed mutually by
          the Developer and the Creditor within the 30 days of the dispute. The
          seat shall be decided mutually by the parties.
        </div>
        <div>
          13. The parties hereby admit that this agreement has been fully
          explained to them and they have understood the meaning of all the
          clauses of this agreement and they have signed this agreement with
          full understanding of the obligations herein.
        </div>
        <div>
          IN WITNESS WHEREOF, the parties have hereunto set and subscribed their
          hands on the date and year above mentioned. Signed and delivered by
          the within named owner Signed and delivered by the within named
          hire/borrower
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: " space-around",
            padding: "40px 0px",
            fontWeight: "600",
          }}
        >
          <span> WITNESSES;</span>
          <span>Borrower Sign.</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "29px",
            padding: "0px 21%",
          }}
        >
          <div>1. __________</div>
          <div>2. __________</div>
        </div>
        <h5 style={{ textAlign: "center", padding: "20px 0px" }}>
          DEMAND PROMISSORY NOTE
        </h5>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <span>PLACE :-------------------</span>
          <span>DATE :--------------------</span>
          <span>RS :------------------------</span>
        </div>
        <div style={{ padding: " 10px 11px" }}>
          ON DEMAND I/WE ---------------------------------------------the
          undersigned Jointly and severally promise to M/S. RIYMS MICRO
          FOUNDATION, SAFIDON or Order at SAFIDON The sum of Rupees
          ...............................along with interest thereon @
          .............................% p. a. for this Date of payment full for
          value received.
        </div>
        <h5 style={{ textAlign: "center" }}>SIGNATURES X (BORROWER)</h5>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "0px 16%" }}
        >
          <div
            style={{
              // border: "2px solid #000000",
              width: "178px",
              height: "140px",
              textAlign: "center",
            }}
          >
            <img
              src={passportSizePhoto}
              style={{
                width: "178px",
                height: "140px",
              }}
              alt="img"
            />
          </div>
        </div>
        <h6>NAME____________________</h6>
        <h6>ADDRESS____________________</h6>
        <h5 style={{ textAlign: "center" }}>SIGNATURES X (BORROWER)</h5>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "0px 16%" }}
        >
          <div
            style={{
              // border: "2px solid #000000",
              width: "178px",
              height: "140px",
              textAlign: "center",
            }}
          >
            <img
              style={{
                width: "178px",
                height: "140px",
              }}
              src={passportSizePhoto}
              alt="img"
            />
          </div>
        </div>
        <h6>NAME____________________</h6>
        <h6>ADDRESS____________________</h6>
        <h5 style={{ textAlign: "center" }}>SIGNATURES X (GUARANTOR)</h5>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "0px 16%" }}
        >
          <div
            style={{
              border: "2px solid #000000",
              width: "178px",
              height: "140px",
              textAlign: "center",
            }}
          ></div>
        </div>
        <h6>NAME ...............</h6>
        <h6>ADDRESS ...............</h6>
        <h5 style={{ textAlign: "center", padding: "40px 0px" }}>
          Consent letter
        </h5>
        <div>____________________________________________________</div>
        <div>______________________________ declares that--- </div>
        <div>
          1. That I have taken a loan of Rs . ..........................from M/s
          RIYMS MICRO FOUNDATION (hereinafter referred as company)
          dated....................
        </div>
        <div>
          2. That I hereby give my consent that entire above mentioned loan
          amount shall be transferred to
          M/s......................................................................situated
          at ...............................................................
          ............................................................................................................................................................
          on my behalf to meet my liabilities for purchase of Mobile/ electronic
          or other item . I whatsoever have no objection for it
        </div>
        <div>
          3. That I hereby further give my consent, I agree to repay all the
          EMIs timely with Interest, fines, penalties and other charges, if any,
          of the above loan amount as agreed as per the loan agreement
        </div>
        <div>
          4. That, I further declare that the information given in this letter
          are true & fair on my behalf and undersigned .by myself without any
          pressure.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 10%",
            fontWeight: "600",
          }}
        >
          <span>Name</span> <span>signature</span>
        </div>
        <div style={{ fontWeight: "600", padding: "0px 10%" }}>Date</div>
      </div>
      <div style={{ padding: "10px 40px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4 style={{ borderBottom: "1.5px solid #000" }}>
            Income Declaration for Loan Application
          </h4>
        </div>
        <div style={{ paddingTop: "20px", paddingLeft: "50px" }}>
          <h6 style={{ textAlign: "left" }}>
            I, [borrower Full Name], the undersigned borrower, am applying for a
            loan from (Company Name). Hereby, <br /> I declare my income for
            this loan application:
          </h6>
          <div style={{ paddingTop: "10px" }}>
            <h6 style={{ paddingTop: "5px" }}>Employment Income: - </h6>
            <h6 style={{ paddingTop: "5px" }}>Monthly Salary: Rs. [Amount] </h6>
            <h6 style={{ paddingTop: "5px" }}>Bonuses: Rs. [Amount]</h6>
            <h6 style={{ paddingTop: "5px" }}>
              Other Compensation: Rs. [Amount]
            </h6>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h6 style={{ paddingTop: "5px" }}>Business Income: -</h6>
            <h6 style={{ paddingTop: "5px" }}>
              Business Name: [Business Name]{" "}
            </h6>
            <h6 style={{ paddingTop: "5px" }}>
              Nature of Business: [Description]
            </h6>
            <h6 style={{ paddingTop: "5px" }}>
              Total Business Income: Rs. [Amount]
            </h6>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h6 style={{ paddingTop: "5px" }}>Investment Income: -</h6>
            <h6 style={{ paddingTop: "5px" }}>Interest Income: Rs. [Amount]</h6>
            <h6 style={{ paddingTop: "5px" }}>
              Divide and Income: Rs. [Amount]
            </h6>
            <h6 style={{ paddingTop: "5px" }}>Capital Gains: Rs. [Amount]</h6>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h6 style={{ paddingTop: "5px" }}>Rental Income: -</h6>
            <h6 style={{ paddingTop: "5px" }}>Property Address: [Address]</h6>
            <h6 style={{ paddingTop: "5px" }}>
              Monthly Rental Income: Rs. [Amount]
            </h6>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h6 style={{ paddingTop: "5px" }}>Other Sources of Income: -</h6>
            <h6 style={{ paddingTop: "5px" }}>Specify: [Source]</h6>
            <h6 style={{ paddingTop: "5px" }}>Amount: Rs. [Amount]</h6>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h6 style={{ paddingTop: "5px" }}>
              I affirm that the details provided are accurate and complete.
              Furthermore, I declare that my household income for the last
              financial year does not exceed Rs. 3,00,000/-. I authorize
              (Company Name) to verify the information through appropriate
              channels. I am aware that any discrepancy may affect the loan
              approval process.
            </h6>
          </div>
          <div style={{ paddingTop: "25px" }}>
            <h6>Date: [Date]</h6>
            <h6>Signature: _________________________</h6>
          </div>
        </div>
      </div>
    </>
  );
};
PrintSantionLetterForm.propTypes = {
  setPrintSantialLetter: PropTypes.bool.isRequired,
  //cuData: PropTypes.object.isRequired,
  custData: PropTypes.object.isRequired,
  emiiData: PropTypes.object.isRequired,
};

export default PrintSantionLetterForm;
