import React from "react";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";
import {
  cilSpeedometer,
  cilLibraryBuilding,
  cilLayers,
  cibCreativeCommonsSamplingPlus,
  cilUserPlus,
} from "@coreui/icons";

const Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  //Adminstatiores Menu
  {
    component: CNavGroup,
    name: "Company",
    to: "/users",
    showOnlyForRole: "admin",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  //Company Screens Menu
  {
    component: CNavGroup,
    name: "Directors",
    to: "/directors",
    showOnlyForRole: "company",
  },

  {
    component: CNavGroup,
    name: "Branches",
    to: "/branches",
    showOnlyForRole: "company",
  },

  {
    component: CNavGroup,
    name: "Products",
    showOnlyForRole: "company",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup,
    name: "Products",
    showOnlyForRole: "employee",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup,
    name: "Manage Staff",
    to: "/staff",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Staff",
        to: "/staff/addstaff",
      },
      {
        component: CNavItem,
        name: "Staff Details",
        to: "/staff",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Loans",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Apply Loan",
        to: "/customers",
      },
      {
        component: CNavItem,
        name: "Customer Registration",
        to: "/newregisterform",
      },
      {
        component: CNavItem,
        name: "Verification",
        to: "/verification",
      },
      {
        component: CNavItem,
        name: "Track Customer Details",
        to: "/customer-profile",
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Group Loan",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Group",
        to: "/group/addgroup",
      },
      {
        component: CNavItem,
        name: "Group List",
        to: "/group/grouplist",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "EMI Record",
    showOnlyForRole: "company",
    to: "/emi-details",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // items: [
    //   {
    //     component: CNavItem,
    //     name: "EMI Record",
    //     to: "/emi-details",
    //   },
    // ],
  },

  {
    component: CNavGroup,
    name: "Collections",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavGroup,
        name: "Payment Collections",
        to: "/payment-collection",
      },
      {
        component: CNavGroup,
        name: "Pending Emi Details",
        to: "/pending-emi-details",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "LoanStatus",
    to: "/loan-status",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  //Manager Screen Menu
  {
    component: CNavGroup,
    name: "Products",
    showOnlyForRole: "manager",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup,
    name: "Manage Staff",
    to: "/staff",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Staff",
        to: "/staff/addstaff",
      },
      {
        component: CNavItem,
        name: "Staff Details",
        to: "/staff",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Group Loan",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Group",
        to: "/group/addgroup",
      },
      {
        component: CNavItem,
        name: "Group List",
        to: "/group/grouplist",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Loans",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Apply Loan",
        to: "/customers",
      },
      {
        component: CNavItem,
        name: "Verification",
        to: "/verification",
      },
      {
        component: CNavItem,
        name: "Track Customer Details",
        to: "/customer-profile",
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  //Staff Screen Menu
  // {
  //   component: CNavGroup,
  //   name: "Manage Staff",
  //   to: "/staff",
  //   showOnlyForRole: "employee",
  //   icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Staff",
  //       to: "/staff",
  //     },
  //   ],
  // },

  {
    component: CNavGroup,
    name: "Loans",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Apply Loan",
        to: "/customers",
      },
      {
        component: CNavItem,
        name: "Track Customer Details",
        to: "/customer-profile",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Emi Record",
    to: "/emi-details/",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
  },

  {
    component: CNavGroup,
    name: "Loans",
    showOnlyForRole: "staff",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Apply Loan",
        to: "/customers",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Collections",
    to: "/payment-collection",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  ///////////////
  {
    component: CNavGroup,
    name: "EMI Status",
    to: "/emi-details",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavGroup,
    name: "Collections",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Payment Collections",
        to: "/payment-collection",
      },
    ],
  },

  //Agent Screens Menu
  {
    component: CNavGroup,
    name: "Home",
    to: "/owndetails",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Loans",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Apply Loan",
        to: "/customers",
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Collections",
    to: "/pendingcollection",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Agent Collections",
        to: "/paidcollection",
      },
      {
        component: CNavItem,
        name: "Pending Collections",
        to: "/pendingcollection",
      },
      {
        component: CNavItem,
        name: "Paid Collections",
        to: "/paidcollection",
      },
    ],
  },

  //Customer Screens Menu

  {
    component: CNavGroup,
    name: "Manage Customers",
    to: "/customer-profile",
    showOnlyForRole: "customer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Customers Loan Details",
        to: "/customer-profile",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Emi",
    to: "/emi/loanType",
    showOnlyForRole: "customer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "loanType",
        to: "/emi/loanType",
      },
    ],
  },

  // ================================ Accounting section  ===========================================
  {
    component: CNavGroup,
    name: "Accounting",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Master Account",
        to: "/masteraccount",
      },
      {
        component: CNavItem,
        name: "Payment",
        to: "/paymentForm",
      },
      {
        component: CNavItem,
        name: "Reciept",
        to: "/recieptForm",
      },
      {
        component: CNavItem,
        name: "Journals",
        to: "/journaldata",
      },
      {
        component: CNavItem,
        name: "Ledger",
        to: "/ledger",
      },
      {
        component: CNavGroup,
        name: "BankStatement",
        showOnlyForRole: "company",
        to: "/bankstatement",
        icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Profit And Loss Account",
        to: "/profitandlosslist",
      },
      {
        component: CNavGroup,
        name: "Banking",
        showOnlyForRole: "company",
        to: "/banking",
        icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Accounting",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Master Account",
        to: "/masteraccount",
      },
      {
        component: CNavItem,
        name: "Journals",
        to: "/journaldata",
      },
      {
        component: CNavItem,
        name: "Ledger",
        to: "/ledger",
      },

      {
        component: CNavItem,
        name: "Trading Account",
        to: "/trading",
      },
      {
        component: CNavItem,
        name: "Profit And Loss Account",
        to: "/profitandlosslist",
      },
      {
        component: CNavItem,
        name: "Opening Balance Sheet",
        to: "/openingbalancelist",
      },
    ],
  },
];

export default Menu;
