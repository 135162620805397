import React from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardImage,
  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import { getS3Url } from "src/services/helpers";
const DownloadDocuments = ({ customerData, emiData }) => {
  const panUrl = getS3Url(customerData?.panImageUrl);
  const aadhaarUrl = getS3Url(customerData?.aadharImageUrl);
  const aadharbackimage = getS3Url(customerData?.aadharbackimageurl);
  // const bankstmtImg = getS3Url(customerData?.bankStatementsImageUrl);
  const passportsize = getS3Url(customerData?.passportSizePhoto);
  const billImage = getS3Url(customerData?.billImage);
  const guarantorimage = getS3Url(customerData?.guarantorimageurl);
  const lockscreen = getS3Url(customerData?.lockscreenimageurl);

  const handleDownloadClick = (imageUrl, fileName) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <CCard>
        <CCardBody>
          <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 2 }}>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={aadhaarUrl} />
                <CCardBody>
                  <CCardTitle>Aadhar Card Front</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(aadhaarUrl, "aadhaar_card_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={aadharbackimage} />
                <CCardBody>
                  <CCardTitle>Aadhar Card Back</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(
                        aadharbackimage,
                        "aadhaar_card_back_image.jpg"
                      )
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={passportsize} />
                <CCardBody>
                  <CCardTitle>Passport</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(passportsize, "passport_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={billImage} />
                <CCardBody>
                  <CCardTitle>Bill Image</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(billImage, "bill_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={guarantorimage} />
                <CCardBody>
                  <CCardTitle>Guarantor</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(guarantorimage, "Guarantor_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" className="height_300" src={lockscreen} />
                <CCardBody>
                  <CCardTitle>Local Screen</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(lockscreen, "Guarantor_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
};
DownloadDocuments.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};
export default DownloadDocuments;
