import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormSelect,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { useForm } from "react-hook-form";
import { errorAlert, successAlert } from "src/utils/Swal";
import { useDispatch, useSelector } from "react-redux";
import {
  adminstratorLogin,
  selectedRoleType,
} from "src/redux/actions/staffAction";

const Login = () => {
  const dispatch = useDispatch();
  const { staffReducer, errordata } = useSelector((state) => state);

  const [selectedRole, setSelectedRole] = useState("");
  const [status, setStatus] = useState(false);
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setStatus(true);
    dispatch(selectedRoleType(selectedRole));
    sessionStorage.setItem("role", selectedRole);
    if (selectedRole === "admin") {
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "employee") {
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "company") {
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "customer") {
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "agent") {
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "manager") {
      dispatch(adminstratorLogin(data));
    }
  };

  useEffect(() => {
    if (status === true) {
      if (staffReducer?.error === false) {
        if (staffReducer?.newlogindata?.status === "success") {
          sessionStorage.setItem("token", staffReducer?.newlogindata?.token);
          sessionStorage.setItem(
            "schemaName",
            staffReducer?.newlogindata?.data?.schemaName
          );
          sessionStorage.setItem(
            "companyN",
            staffReducer?.newlogindata?.data?.companyName
          );
          sessionStorage.setItem(
            "companyDetails",
            JSON.stringify(staffReducer?.newlogindata?.data?.companyDetails)
          );
          sessionStorage.setItem("id", staffReducer?.newlogindata?.data?.id);
          sessionStorage.setItem(
            "branc",
            staffReducer?.newlogindata?.data?.branchId
          );
          sessionStorage.setItem(
            "companyId",
            staffReducer?.newlogindata?.data?.companyId
          );
          successAlert("Success", "Logged in Successfully");

          const currentDate = new Date();
          const remindDate = new Date();
          remindDate.setDate(currentDate.getDate() + 3);
          const userCompanySubscrptionEndAt = new Date(
            staffReducer?.newlogindata?.data?.companyDetails?.dueDate
          );
          if (remindDate >= userCompanySubscrptionEndAt) {
            successAlert("Alert", "Your Subscrption will expire 3 days");
          }
          if (selectedRole === "company") {
            navigate("/dashboard", { replace: true });
          } else {
            navigate("/customers", { replace: true });
          }
          setStatus(false);
        }
      } else {
        let errMessage = "UserName or Password Not Matched";
        if (
          staffReducer?.message?.data?.error?.status === 403 ||
          errordata?.response?.data?.status === 403
        )
          errMessage = "Your Subscrption validity has been expired";
        errorAlert("Failed", errMessage);
        setStatus(false);
      }
    }
  }, [staffReducer, status]);

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard style={{ backgroundColor: "#bfc9d9" }} className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "grid", justifyContent: "center" }}>
                      <h1 className="loginSectionData">Login</h1>
                      <p className=" loginSectionData">Login to your account</p>
                    </div>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="User Name"
                        autoComplete="userName"
                        name="userName"
                        {...register("userName", { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        {...register("password", { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CFormSelect
                        name="role"
                        {...register("role", { required: true })}
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        <option value="">Select Role</option>
                        <option value="admin">Administrator</option>
                        <option value="company">Company Admin</option>
                        <option value="manager">Manager</option>
                        <option value="employee">Staff</option>
                        <option value="agent">Agent</option>
                        <option value="agent">Group Login</option>
                        <option value="customer">Customer</option>
                      </CFormSelect>
                    </CInputGroup>
                    <CRow>
                      <CCol className=" text-right">
                        <CButton type="submit" className="px-4 login-btn">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className=" text-right"></CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
