import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllEmiByCustomerId, setCustomerId } from "src/redux/actions/emiAction";
import { getAllCustomers } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import EmiStatement from "./emiStatement";
import EmiDetailsData from "./emiDetailsData";

const EmiDataForStatement = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { emiData } = useSelector((state) => state?.emiReducer);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [getAllEmis, setGetAllEmis] = useState(null);
  const [loanAccountNumber, setLoanAccountNumber] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [approvedLoan, setApprovedLoan] = useState()

  const [showEmiDetails, setShowEmiDetails] = useState(true);
  const componentPDF = useRef();
  const [printState, setPrintState] = useState(false);
  const [loanId, setLoanId] = useState("");

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setAllCustomerData(res?.data);
    });
  };

  const download_btn = () => {
    setPrintState(true);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const rowsPerPage = 10;

  const filteredEmis = getAllEmis?.filter((emi) => {
    if (selectedStatus === "all") return true;
    return emi?.emiStatus === "paid";
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleSearch = () => {
    const filteredCustomer = allCustomerData?.filter(
      (customer) =>
        customer?.loanAccountNumber === String(loanAccountNumber)
    );

    if (filteredCustomer?.length > 0) {
      setCustomerData(filteredCustomer[0]);
      const obj = {
        schemaName: SchemaName,
        customerId: filteredCustomer[0]?.id,
      };
      const customer = filteredCustomer[0];
      dispatch(setCustomerId(customer));
      dispatch(getAllEmiByCustomerId(obj));
      setShowDetails(true);
      setShowTable(true);
    } else {
      setCustomerData([]);
    }
  };

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <div className=" flex justify-between">
            <strong>EMI Statement</strong>
            <div>
              <CButton className="add-btn mr-10 w-auto" onClick={download_btn}>
                Download
              </CButton>

              {showEmiDetails === false ? (
                <CButton
                  className="add-btn m-0 w-auto"
                  onClick={() => setShowEmiDetails(true)}
                >
                  Back
                </CButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow>
              <CCol xs={2}>
                <CFormInput
                  placeholder="Enter your Loan Account"
                  onChange={(e) => setLoanAccountNumber(e.target.value)}
                />
              </CCol>
              <CCol xs={2}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>

            </CRow>
          </div>
          <div className="serch_bar"></div>
          <div>
            <div
              ref={printState ? componentPDF : null}
              style={{ padding: printState ? "20px" : "0px" }}
            >
              {showTable && (
                <>
                  {showEmiDetails === true ? (
                    <EmiDetailsData
                      customerData={customerData?.id}
                      setShowEmiDetails={setShowEmiDetails}
                      setGetAllEmis={setGetAllEmis}
                      setLoanId={setLoanId}
                      setApprovedLoan={setApprovedLoan}
                    />
                  ) : (
                    <EmiStatement approvedLoan={approvedLoan} filteredEmis={filteredEmis} customerData={customerData} />
                  )}{" "}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Showing {indexOfFirstRow + 1} to{" "}
                {Math?.min(indexOfLastRow, filteredEmis?.length)} of{" "}
                {filteredEmis?.length} entries
              </div>
              <div className="pagination-container">
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={rowsPerPage}
                  totalItemsCount={filteredEmis?.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText="Previous"
                  nextPageText="Next"
                  firstPageText="First"
                  lastPageText="Last"
                  innerClass="pagination"
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EmiDataForStatement;
