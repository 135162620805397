// src/components/Liabilities.js
import React from "react";
import { Card } from "react-bootstrap";
import { Doughnut, Pie } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "../sale.css";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);
const Liabilities = ({ datas }) => {
  const data = {
    labels: ["Total Branches", "total Products"],
    datasets: [
      {
        data: [datas?.branchCount, datas?.interestCount],
        backgroundColor: ["#FF6384", "#FFCE56"],
      },
    ],
  };

  return (
    <Card className="mb-4 bx">
      <Card.Body>
        <Card.Title>Liabilities</Card.Title>
        <h3>1,795K</h3>
        <Doughnut data={data} />
      </Card.Body>
    </Card>
  );
};
Liabilities.propTypes = {
  datas: PropTypes.object,
};
export default Liabilities;
