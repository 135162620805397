import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getLoanId } from "src/redux/actions/customerAction";
import authInstance from "src/api/apiConfig";
import { formatDate } from "src/services/helpers";

const calculateEmiTotals = (loans) => {
  let totalPendingEmi = 0;
  let totalPaidEmi = 0;

  loans.forEach((loan) => {
    loan.emis.forEach((emi) => {
      if (emi.emiStatus === 'pending') {
        totalPendingEmi += parseFloat(emi.emiAmount);
      } else if (emi.emiStatus === 'paid') {
        totalPaidEmi += parseFloat(emi.emiAmount);
      }
    });
  });

  return { totalPendingEmi, totalPaidEmi };
};

const LoanStatus = () => {
  const dispatch = useDispatch();
  const { loandetails } = useSelector((state) => state?.customerReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const { emiData } = useSelector((state) => state?.emiReducer);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [newData, setNewData] = useState(allCustomerData);
  const [showDetails, setShowDetails] = useState(false);
  const [showEmiDetails, setShowEmiDetails] = useState(true);
  const componentPDF = useRef();
  const [printState, setPrintState] = useState(false);
  const [filterData, setfilteredData] = useState([]);
  const [newCustomerData, setNewCustomerData] = useState(null);
  const SchemaName = sessionStorage.getItem("schemaName");
  const loandata = loandetails?.data;
  const [customerloanId, setCustomerLoanId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [currSelect, setCurrSelect] = useState("");

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    setCustomerLoanId(loandata);
  }, [loandata, refresh]);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const getAllCompanyData = async () => {
    const payload = { schemaName: SchemaName };
    try {
      const res = await authInstance.post("getDisLoans", payload);
      setAllCustomerData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const download_btn = () => {
    setPrintState(true);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const rowsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = allCustomerData?.slice(indexOfFirstRow, indexOfLastRow);

  useEffect(() => {
    setNewData(allCustomerData);
  }, [allCustomerData]);

  const handleSearch = () => {
    const data = allCustomerData
      ?.filter((ele) => ele.loans?.some((loan) => loan.status === currSelect))
      .map((ele) => ({
        ...ele,
        loans: ele.loans?.filter((loan) => loan.status === currSelect),
      }));
    setNewData(data);
  };

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <div className="flex justify-between">
            <strong>Loan Details</strong>
            <div>
              <div>
                <CButton className="add-btn mr-10 w-auto" onClick={download_btn}>
                  Download
                </CButton>
                <CButton className="add-btn m-0 w-auto" onClick={() => setShowDetails(false)}>
                  Back
                </CButton>
              </div>
              {showEmiDetails === false ? (
                <CButton className="add-btn m-0 w-auto" onClick={() => setShowEmiDetails(true)}>
                  Back
                </CButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow style={{ width: "-webkit-fill-available !important", alignItems: "end", justifyContent: "end" }}>
              <CCol xs={3}></CCol>
              <CCol xs={3}>
                <div>
                  <span className="lable-name">Select Status :</span>
                  <CFormSelect value={currSelect} onChange={(e) => setCurrSelect(e.target.value)}>
                    <option value={""}>Select Status</option>
                    <option value={"pending"}>Pending</option>
                    <option value={"reject"}>Reject</option>
                    <option value={"approved"}>Approved</option>
                    <option value={"open"}>Disburse</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol xs={3}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
            </CRow>
          </div>
          <div className="serch_bar"></div>

          <div ref={printState ? componentPDF : null} style={{ padding: printState ? "20px" : "0px" }}>
            <div style={{ marginTop: "40px" }}>
              <CTable hover className="" responsive>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Sr No.</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Date</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Customer Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Father Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Grand Father Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Phone Number</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Branch Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Approved Amount</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Total Pending EMI</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Total Paid EMI</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span className="">Loan Status</span>
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {newData?.length > 0 &&
                    newData?.map((company, index) => {
                      const { totalPendingEmi, totalPaidEmi } = calculateEmiTotals(company?.loans || []);
                      return (
                        <CTableRow key={index}>
                          <CTableDataCell>{index + 1}</CTableDataCell>
                          <CTableDataCell style={{ cursor: "pointer" }}>
                            {company?.loans[0]?.createdAt?.split("T")[0] ? formatDate(company?.loans[0]?.createdAt?.split("T")[0]) : "-"}
                          </CTableDataCell>
                          <CTableDataCell style={{ cursor: "pointer" }}>
                            {company?.firstName ? company?.firstName : "-"}
                          </CTableDataCell>
                          <CTableDataCell style={{ cursor: "pointer" }}>
                            {company?.fatherName ? company?.fatherName : "-"}
                          </CTableDataCell>
                          <CTableDataCell style={{ cursor: "pointer" }}>
                            {company?.grandFatherName ? company?.grandFatherName : "-"}
                          </CTableDataCell>
                          <CTableDataCell className="">
                            {company?.phoneNumber}
                          </CTableDataCell>
                          <CTableDataCell className="">
                            <span className="">
                              {company?.branch?.branchName}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell style={{ cursor: "pointer" }}>
                            {company?.loans[0]?.approvalAmount ? company?.loans[0]?.approvalAmount : "-"}
                          </CTableDataCell>
                          <CTableDataCell>{totalPendingEmi.toFixed(2)}</CTableDataCell>
                          <CTableDataCell>{totalPaidEmi.toFixed(2)}</CTableDataCell>
                          <CTableDataCell>
                            {company?.loans?.length === 1 ? (
                              company?.loans.map((ele, i) => ele?.status)
                            ) : (
                              <>
                                <CButton style={{ padding: "2px" }}>Multiple..</CButton>
                              </>
                            )}
                          </CTableDataCell>
                        </CTableRow>
                      );
                    })}
                </CTableBody>
              </CTable>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default LoanStatus;
