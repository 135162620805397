import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateLoan } from "src/redux/actions/customerAction";

const LoanApproveModal = ({ setVisible, visible, customerData }) => {
  const dispatch = useDispatch();
  const { loandetails, loandata } = useSelector(
    (state) => state?.customerReducer
  );
  const { loanAmountRequested, id } = customerData;

  const [loanAmount, setLoanAmount] = useState(0);
  const [approvedAmount, setApprovedAmount] = useState();

  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );

  useEffect(() => {
    setLoanAmount(loanAmountRequested || 0);
  }, [loanAmountRequested]);
  const currentDate = new Date().toISOString().split("T")[0];

  const handleSubmit = (event) => {
    event.preventDefault();
    const newData = {
      schemaName: SchemaName,
      status: "approved",
      approvalAmount: approvedAmount,
      approvalDate: currentDate,
    };

    const obj = {
      id: loandata?.id,
      data: newData,
    };
    dispatch(updateLoan(obj));
    // setStatus(true);
    setVisible(!visible);
  };

  const handleReject = (event) => {
    event.preventDefault();
    const rejectData = {
      schemaName: SchemaName,
      status: "reject",
      approvalAmount: 0,
    };

    const obj = {
      id: loandata?.id,
      data: rejectData,
    };
    dispatch(updateLoan(obj));
    setVisible(!visible);
  };

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CContainer>
          <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <CModalHeader>
              <CModalTitle id="StaticBackdropExampleLabel">
                Are You Want To Approve This Loan?
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CCol xs={12}>
                <CCardBody>
                  <div className="staffform_header"></div>
                  <CRow className="pt-20">
                    <CFormLabel>Customer Requsted Amount</CFormLabel>
                    <CCol>
                      <CFormInput
                        type="number"
                        id="loanAmount"
                        placeholder="Loan Amount"
                        name="loanAmount"
                        value={loandata?.requestedAmount}
                        onChange={(e) =>
                          setLoanAmount(parseInt(e.target.value) || 0)
                        }
                        required
                        disabled
                      />
                    </CCol>
                  </CRow>
                  <div className="body_d">
                    <CRow>
                      <CFormLabel>Approval Amount</CFormLabel>
                      <CCol xs={12}>
                        <CFormInput
                          type="number"
                          id="loanAmount"
                          placeholder="Loan Amount"
                          name="approvedAmount"
                          value={approvedAmount}
                          onChange={(e) => setApprovedAmount(e.target.value)}
                          required
                        />
                      </CCol>
                    </CRow>
                  </div>
                </CCardBody>
              </CCol>
            </CModalBody>
            <CModalFooter>
              <CCol>
                <CButton type="submit" color="primary" className="px-4">
                  Approve Loan
                </CButton>
              </CCol>
              <CCol>
                <CButton
                  onClick={handleReject}
                  color="primary"
                  className="px-4"
                >
                  Reject Loan
                </CButton>
              </CCol>
            </CModalFooter>
          </CForm>
        </CContainer>
      </CModal>
    </>
  );
};

LoanApproveModal.propTypes = {
  customerData: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default LoanApproveModal;
