import {
  CButton,
  CCard,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import authInstance from "src/api/apiConfig";
import { errorAlert } from "src/utils/Swal";
const ProfitAndLossList = () => {
  const initial = {
    startDate: "",
    endDate: "",
  };
  const [allData, setAllData] = useState();
  const [formValues, setFormValues] = useState(initial);
  const schemaName = sessionStorage.getItem("schemaName");
  const handleAllCustomerTotal = async () => {
    try {
      const payload = {
        schemaName: schemaName,
        startDate: startDate,
        endDate: endDate,
      };
      const response = await authInstance.post("/getallwithdate", payload);
      if (response.status === 200) {
        setAllData(response?.data?.message?.newJournal);
      }
    } catch (error) {
      errorAlert("failed", "Some-thing Went Wrong");
    }
  };
  useEffect(() => {
    handleAllCustomerTotal();
  }, []);
  const { startDate, endDate } = formValues;
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  return (
    <div className="content">
      <CCard>
        <CRow className="mb-3">
          <CCol xs={4}>
            <CFormLabel>Start Date</CFormLabel>
            <CFormInput
              type="date"
              placeholder="Select A Date"
              value={startDate}
              name="startDate"
              onChange={handleChange}
            />
          </CCol>
          <CCol xs={4}>
            <CFormLabel>End Date</CFormLabel>
            <CFormInput
              type="date"
              placeholder="Select A Date"
              value={endDate}
              name="endDate"
              onChange={handleChange}
            />
          </CCol>
          <CCol xs={4}>
            <CButton
              style={{ marginTop: "30px" }}
              onClick={handleAllCustomerTotal}
            >
              Search
            </CButton>
          </CCol>
        </CRow>
        <CTable striped hover>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">
                Particulars\Revenues
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
              <CTableHeaderCell scope="col">
                Particular\Expenses
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {allData?.map((ele, i) => (
              <CTableRow key={i}>
                <CTableDataCell>
                  <div className="p_l_data">
                    {/* <span>to gross loss b/d</span> */}
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  <div className="p_l_data">{/* <span>*****</span> */}\</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div className="p_l_data">
                    {/* <span>by Gross profit b/d</span> */}
                    <span>{`${ele?.debitAccount}`}</span>
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  <div className="p_l_data">
                    {/* <span>*****</span> */}
                    <span>{ele?.totalDebitAmount}</span>
                  </div>
                </CTableDataCell>
              </CTableRow>
            ))}
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>
                <div className="p_l_data"></div>
              </CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>
                <div className="p_l_data"></div>
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CCard>
    </div>
  );
};

export default ProfitAndLossList;
