import React, { useEffect, useState } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CFormSelect, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { getAllDirector } from "src/redux/actions/customerAction";
import DirectorAddForm from "./operations/directorAddForm";
import DirectorListById from "./capital";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const DirectorList = () => {
  const componentPDF = useRef();
  const dispatch = useDispatch();
  const { newDirectorCreated } = useSelector((state) => state?.customerReducer);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [dataByDirectorId, setDataByDirectorId] = useState(null);
  const [directorData, setDirectorData] = useState(false);
  const [printState, setPrintState] = useState(false);
  const SchemaName = sessionStorage.getItem("schemaName")
  const [currItem, setCurrItem] = useState({});
  const { Directors } = useSelector((state) => state?.customerReducer);

  useEffect(() => {
    const obj = { schemaName: SchemaName };
    dispatch(getAllDirector(obj));
  }, []);

  useEffect(() => {
    if (newDirectorCreated === true) {
      const obj = { schemaName: SchemaName };
      dispatch(getAllDirector(obj));
    }
  }, [newDirectorCreated]);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };


  const [showEdit, setShowEdit] = useState(false);

  const handleShowAddForm = (details) => {
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
    setCurrItem(details);
    setShowEdit(false);
  };

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleCapital = (data) => {
    setDataByDirectorId(data);
    setDirectorData(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = Directors?.data?.slice(indexOfFirstRow, indexOfLastRow);

  const modifiedRows = currentRows?.map(row => {
    const { capitalAccounts, company, companyId, id, schemaName, interest, ...rest } = row;
    return {
      ...rest,
    };
  });

  return (
    <>
      <CRow className="content">
        {directorData === true ? (
          <DirectorListById
            setDirectorData={setDirectorData}
            dataByDirectorId={dataByDirectorId}
            directorData={directorData || false}
          />
        ) : (
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Directors</strong>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs={12}>
                    <CCard className="mb-2">
                      <div className="search_bar">
                        <div className="search-inner">
                          {showSelectAndSearch && (
                            <div>
                              <CFormSelect
                                size="sm"
                                className="mb-3"
                                style={{ cursor: "pointer" }}
                                aria-label="Small select example"
                                onChange={handleRowsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </CFormSelect>
                            </div>
                          )}
                          <div style={{ display: "flex" }}>
                            {showSelectAndSearch && (
                              <>
                                <CFormInput
                                  type="text"
                                  size="sm"
                                  placeholder="Search"
                                  aria-label="sm input example"
                                  style={{ margin: "0px 0px 16px 0px" }}
                                  onChange={handleSearchChange}
                                  value={searchInput}
                                />
                                <span
                                  style={{
                                    border: " 1px solid #d1c5c5",
                                    borderRadius: "4px",
                                    margin: "0px 5px 16px -1px",
                                    padding: "1px 8px 0px 8px",
                                    background: "#ebedef",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fa fa-search"></i>
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="btn_add mt-0 mb-14 ">
                          {showAddButton && (
                            <Button
                              className="plr-20 add-btn w-auto mr-10 "
                              onClick={handleShowAddForm}
                            >
                              Add
                            </Button>
                          )}
                          {showAddButton && (
                            <Button
                              className="add-btn mr-10 w-auto"
                              onClick={download_btn}
                            >
                              Download
                            </Button>
                          )}
                          {showAddButton && (
                            <CSVLink data={modifiedRows === undefined ? " " : modifiedRows} filename={"director-table.csv"}>
                              <CButton className="add-btn m-0 w-auto">
                                CSV
                              </CButton>
                            </CSVLink>
                          )}
                        </div>
                      </div>
                      <CCardBody>
                        <div
                          className={`table-container ${showAccordion ? "hidden" : ""
                            } ${showAddForm ? "hidden" : ""}`}
                        >
                          <div className="mb-2"
                            ref={printState ? componentPDF : null}
                            style={{ padding: printState ? "20px" : "0px" }}
                          >
                            {printState && (
                              <div className="print-div">
                                <div>
                                  {/* <Vakilkaro /> */}
                                  <h3>Riyms</h3>
                                </div>
                                <div>
                                  <p>{COMPANY_NAME}</p>
                                </div>
                              </div>
                            )}
                            <CTable color="dark" hover responsive>
                              <CTableHead className="tabl-head">
                                <CTableRow className="table-row">
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-1"
                                  >
                                    S. No.
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-2"
                                  >
                                    Name
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-2"
                                  >
                                    Email
                                  </CTableHeaderCell>
                                  <CTableHeaderCell scope="col" className="col-md-2">
                                    Phone Number
                                  </CTableHeaderCell>
                                  {!printState && (
                                    <CTableHeaderCell scope="col" className="col-md-1">
                                      Actions
                                    </CTableHeaderCell>
                                  )}
                                </CTableRow>
                              </CTableHead>
                              <CTableBody>
                                {Directors?.data &&
                                  Directors?.data.length > 0 ? (
                                  currentRows
                                    ?.filter((item) =>
                                      item.directorName
                                        .toLowerCase()
                                        .includes(searchInput.toLowerCase())
                                    )
                                    .map((datas, index) => {
                                      return (
                                        <CTableRow key={index}>
                                          <CTableDataCell>
                                            {indexOfFirstRow + index + 1}
                                          </CTableDataCell>
                                          <CTableDataCell>
                                            {datas?.directorName
                                              ? datas?.directorName
                                              : "--"}
                                          </CTableDataCell>
                                          <CTableDataCell>
                                            {datas?.email ? datas?.email : "--"}
                                          </CTableDataCell>
                                          <CTableDataCell>
                                            {datas?.phoneNumber
                                              ? datas?.phoneNumber
                                              : "--"}
                                          </CTableDataCell>
                                          {!printState && (
                                            <CTableDataCell>
                                              <CButton
                                                onClick={() => handleCapital(datas)}
                                                style={{ padding: "0px 6px" }}
                                              >
                                                Add Fund
                                              </CButton>{" "}
                                            </CTableDataCell>
                                          )}
                                        </CTableRow>
                                      );
                                    })
                                ) : (
                                  <div className="loading_data">Loading...</div>
                                )}
                              </CTableBody>
                            </CTable>
                          </div>

                          <div className="pagin-div">
                            <div>
                              Showing {indexOfFirstRow + 1} to{" "}
                              {Math?.min(
                                indexOfLastRow,
                                Directors?.data?.length
                              )}{" "}
                              of {Directors?.data?.length} entries
                            </div>
                            <div className="pagination-container">
                              <ReactPaginate
                                activePage={currentPage}
                                itemsCountPerPage={rowsPerPage}
                                totalItemsCount={
                                  Directors?.data?.length
                                    ? Directors?.data?.length
                                    : 0
                                }
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                prevPageText="Previous"
                                nextPageText="Next"
                                firstPageText="First"
                                lastPageText="Last"
                                innerClass="pagination"
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div>
                          </div>
                        </div>
                        {showAddForm && (
                          <DirectorAddForm
                            selectedCompany={selectedCompany}
                            setShowAddForm={setShowAddForm}
                            setShowAddButton={setShowAddButton}
                            setShowSelectAndSearch={setShowSelectAndSearch}
                            intial={currItem}
                            edit={showEdit}
                            callBack={() => {
                              window.location.reload();
                              showAddForm(false);
                            }}
                          />
                        )}
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol xs={12}></CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>
    </>
  );
};

export default DirectorList;

