import React from "react";
import { Card } from "react-bootstrap";
import { Doughnut, Pie } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "../sale.css";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);
const Assets = ({ datas }) => {
  const data = {
    labels: ["Active Customers", "Active Empolyees"],
    datasets: [
      {
        data: [datas?.customerCount, datas?.employeeCount],
        backgroundColor: ["#FFCE56", "#36A2EB"],
      },
    ],
  };

  return (
    <Card className="mb-4 bx">
      <Card.Body>
        <Card.Title>Customers</Card.Title>
        <h3>{datas?.customerCount + datas?.employeeCount}</h3>
        <Doughnut data={data} />
      </Card.Body>
    </Card>
  );
};
Assets.propTypes = {
  datas: PropTypes.object,
};
export default Assets;
