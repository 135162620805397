import {
  CButton,
  CCard,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import authInstance from "src/api/apiConfig";
import "./bankstatement.css";
import { formatDate } from "date-fns";
import { errorAlert } from "src/utils/Swal";
const BankStatement = () => {
  const [balanceData, setBalanceData] = useState([]);
  const [allData, setAllData] = useState();
  const initial = {
    startDate: "",
    endDate: "",
  };
  const [formValues, setFormValues] = useState(initial);
  const { startDate, endDate } = formValues;
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const schemaName = sessionStorage.getItem("schemaName");
  const handleBalanceSheet = async () => {
    try {
      const payload = {
        schemaName: schemaName,
      };
      const response = await authInstance.post("/balanceSheet", payload);
      if (response.status === 201) {
        setBalanceData(response?.data?.capital);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleAllCustomerTotal = async () => {
    try {
      const payload = {
        schemaName: schemaName,
        startDate: startDate,
        endDate: endDate,
      };
      const response = await authInstance.post("/getallwithdate", payload);
      if (response.status === 200) {
        setAllData(response?.data?.message?.allCustomerTotals);
      }
    } catch (error) {
      errorAlert("failed", "Some-thing Went Wrong");
    }
  };
  useEffect(() => {
    handleBalanceSheet();
    handleAllCustomerTotal();
  }, []);
  const directors = balanceData.directors || [];
  const accountData = balanceData.getAllAccountData || [];
  const disbursments = balanceData.getAllDisbursments || [];
  const interests = balanceData.getAllInterests || [];
  const profitAndLoss = balanceData.getProfitAndLoss || [];
  const penalties = balanceData.getTotalPenaltyAmount || [];

  const maxLength = Math?.max(
    directors.length,
    accountData.length,
    disbursments.length,
    interests.length,
    profitAndLoss.length,
    penalties.length
  );
  return (
    <div className="content">
      <CCard className="mb-4">
        <CRow>
          <CCol xs={4}>
            <CFormLabel>Start Date</CFormLabel>
            <CFormInput
              type="date"
              placeholder="Select A Date"
              value={startDate}
              name="startDate"
              onChange={handleChange}
            />
          </CCol>
          <CCol xs={4}>
            <CFormLabel>End Date</CFormLabel>
            <CFormInput
              type="date"
              placeholder="Select A Date"
              value={endDate}
              name="endDate"
              onChange={handleChange}
            />
          </CCol>
          <CCol xs={4}>
            <CButton
              style={{ marginTop: "30px" }}
              onClick={handleAllCustomerTotal}
            >
              Search
            </CButton>
          </CCol>
        </CRow>
        <div style={{ margin: "30px 0px" }}>
          All Customer Account Details
          <CTable style={{ marginTop: "20px" }} hover className="" responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className="">
                  <span>Total DisbursementAmount</span>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span>Total DiscountAmount</span>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span>Total EmiAmount</span>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span>Total InterestAmount</span>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span>Total PaymentAmount</span>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span>Total PenaltyAmount</span>
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>
                  {allData?.totalDisbursementAmount
                    ? allData?.totalDisbursementAmount
                    : "--"}
                </CTableDataCell>
                <CTableDataCell>
                  {allData?.totalDiscountAmount
                    ? allData?.totalDiscountAmount
                    : "--"}
                </CTableDataCell>
                <CTableDataCell>
                  {allData?.totalEmiAmount ? allData?.totalEmiAmount : "--"}
                </CTableDataCell>
                <CTableDataCell>
                  {allData?.totalInterestAmount
                    ? allData?.totalInterestAmount
                    : "--"}
                </CTableDataCell>
                <CTableDataCell>
                  {allData?.totalPaymentAmount
                    ? allData?.totalPaymentAmount
                    : "--"}
                </CTableDataCell>
                <CTableDataCell>
                  {allData?.totalPenaltyAmount
                    ? allData?.totalPenaltyAmount
                    : "--"}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </div>

        <CTable color="dark" hover responsive>
          <CTableHead className="tabl-head">
            <CTableRow className="table-row">
              <CTableHeaderCell scope="col" colSpan="2" className="col-md-2">
                Directors
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                AllAccountData
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                AllDisbursments
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                AllInterests
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                getProfitAndLoss
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                TotalPenaltyAmount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            <CTableRow className="table-row">
              <CTableHeaderCell scope="col" className="col-md-2">
                Director Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Capital
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Account Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Bank
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Interest
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Total Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                Penalty
              </CTableHeaderCell>
            </CTableRow>

            {maxLength > 0 ? (
              Array.from({ length: maxLength }).map((_, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>
                    {directors[index]?.directorName || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {directors[index]?.capital || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {accountData[index]?.accountName || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {disbursments[index]?.disbursementamount || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {interests[index]?.interestamount || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {profitAndLoss[index]?.totalamount || "N/A"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {penalties[index]?.penaltyAmount || "N/A"}
                  </CTableDataCell>
                </CTableRow>
              ))
            ) : (
              <CTableRow>
                <CTableDataCell colSpan="7" className="text-center">
                  Loading...
                </CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>
      </CCard>
    </div>
  );
};
export default BankStatement;
