import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  ADD_USER_DATA_SUCCESS,
  GET_ALL_GROUPS_SUCCESS,
  GET_GROUP_DETAIL_BY_ID_SUCCESS,
  CREATE_GROUPS_SUCCESS,
  GET_ALL_VERIFIED_CUSTOMER_SUCCESS,
  CREATE_VERIFICATION_SUCCESS,
  UPDATE_VERIFICATION_SUCCESS,
  CREATE_NEW_CUSTOMER_SUCCESS,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  AllGroups: null,
  groupCreated: null,
  AllVerifiedData: null,
  newstaffverifiedData: null,
  newmanagerverifiedData: null,
  newCustomerCreationData: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };

    case ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };

    case CREATE_NEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        newCustomerCreationData: action.payload,
        error: false,
      };

    case CREATE_GROUPS_SUCCESS:
      return {
        ...state,
        groupCreated: action.payload,
        error: false,
      };

    case CREATE_VERIFICATION_SUCCESS:
      return {
        ...state,
        newstaffverifiedData: action.payload,
        error: false,
      };

    case UPDATE_VERIFICATION_SUCCESS:
      return {
        ...state,
        newmanagerverifiedData: action.payload,
        error: false,
      };

    case "empty_verfied_Data":
      return {
        ...state,
        newstaffverifiedData: null,
        newmanagerverifiedData: null,
        error: false,
      };

    case GET_ALL_VERIFIED_CUSTOMER_SUCCESS:
      return {
        ...state,
        AllVerifiedData: action.payload,
        error: false,
      };

    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        AllGroups: action.payload,
        error: false,
      };

    case GET_GROUP_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        groupDetails: action.payload,
        error: false,
      };

    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
