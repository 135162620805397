import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { getInterest } from "src/api/allApis";
import authInstance from "src/api/apiConfig";
import { successAlert } from "src/utils/Swal";

const NewRegistrationCreateForm = () => {
  const { Branches } = useSelector((state) => state?.customerReducer);
  const Schema = sessionStorage.getItem("schemaName");

  const initialData = {
    firstName: "",
    phoneNumber: "",
    fatherName: "",
    schemaName: Schema,
    grandFatherName: "",
    employeeId: 1,
    loanAmountRequested: "",
    loanTerm: "",
    address: {
      city: "",
    },
    aadharNumber: "",
  };

  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedLoanTypeId, setSelectedLoanTypeId] = useState("");
  const [userDetails, setUserDetails] = useState(initialData);
  const [interset, setInterest] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    const jsonData = { address: "" };
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      address: { city: jsonData.address },
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPayload = {
      ...userDetails,
      branchId: selectedBranchId,
      interestId: selectedLoanTypeId,
    };
    const response = authInstance
      .post("customersBackData", newPayload)
      .then((res) => {
        if (res.data.status === "success") {
          successAlert("Success", "Registration Successfull");
        }
      });
  };

  const {
    firstName,
    phoneNumber,
    fatherName,
    grandFatherName,
    loanAmountRequested,
    interestId,
    loanTerm,
    address,
    aadharNumber,
  } = userDetails;

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBranchId(selectedId);
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    setSelectedLoanTypeId(selectedId);
  };

  const fetchInterest = async () => {
    const res = await getInterest({
      schemaName: Schema,
    });
    setInterest(res?.data || []);
  };

  useEffect(() => {
    fetchInterest();
  }, []);

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol xs={12}>
              <strong>Registration Form</strong>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CContainer>
            <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
              <CRow>
                <div>
                  <div></div>
                  <div>
                    <CFormLabel>
                      Select Branch
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>

                    <CFormSelect
                      id="branchName"
                      name="branchName"
                      className="w_12"
                      onChange={handleSelectChange}
                      value={selectedBranchId}
                      required
                    >
                      <option value="">Select Branch</option>

                      {Branches?.data?.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.branchName}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </div>
              </CRow>
              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="firstName">
                    Full Name
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="firstName"
                    placeholder="Full Name"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="phoneNumber">
                    Mobile Number
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    id="phoneNumber"
                    placeholder="Mobile Number"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </CCol>
              </CRow>

              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="father">Father Name</CFormLabel>
                  <CFormInput
                    type="text"
                    id="fatherName"
                    placeholder="Father Name"
                    name="fatherName"
                    value={fatherName}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="grandFatherName">
                    Grand Father Name
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="grandFatherName"
                    placeholder="grandFatherName / father-in-Law"
                    name="grandFatherName"
                    value={grandFatherName}
                    onChange={handleChange}
                    required
                  />
                </CCol>
              </CRow>

              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="loanAmountRequested">
                    Requested Amount
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    id="loanAmountRequested"
                    type="number"
                    name="loanAmountRequested"
                    placeholder="loanAmountRequested"
                    value={loanAmountRequested}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="interestId">
                    Loan Type
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormSelect
                    className="custom-select"
                    id="interestId"
                    name="loanType"
                    type="select"
                    value={selectedLoanTypeId}
                    onChange={handleSelect}
                    required
                  >
                    <option value="">Select Loan Type</option>
                    {interset?.map((item, i) => (
                      <option key={item.id} value={item.id}>
                        {item?.loanType}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>

              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="loanTerm">
                    loanTerm
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="loanTerm"
                    name="loanTerm"
                    placeholder="loanTerm"
                    value={loanTerm}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="address">
                    Address
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Address"
                    value={address.city}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="address">
                    Aadhar Number
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    id="aadharNumber"
                    name="aadharNumber"
                    type="number"
                    placeholder=" Aadhar Number"
                    value={aadharNumber}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={8}></CCol>
                <CCol xs={4}>
                  <CButton style={{ width: "100%" }} type="submit">
                    Save
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CContainer>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default NewRegistrationCreateForm;
