import {
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllEntries } from "src/redux/accounting/action";
import { formatDate } from "src/services/helpers";
const JEntryData = () => {
  const dispatch = useDispatch();
  const { journalData } = useSelector((state) => state?.acountingReducer);

  const schemaName = sessionStorage.getItem("schemaName");
  useEffect(() => {
    const data = {
      schemaName: schemaName,
    };
    dispatch(getAllEntries(data));
  }, []);

  return (
    <div className="content">
      <CCard>
        <CCardBody>
          <CTable borderless>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-5">
                  Particulars
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">J.F</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount(Dr.)</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount(Cr.)</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {journalData?.map((value, i) => (
                <CTableRow key={i}>
                  <CTableDataCell>{formatDate(value?.entryDate)}</CTableDataCell>
                  <CTableDataCell>
                    <div className="entry_data">
                      <div className="draw">
                        <span>{value?.debitAccount} A/c</span>
                        <span>Dr.</span>
                      </div>
                      <div className="to_cash">
                        <span>To </span>
                        <span>{value?.creditAccount} A/c</span>
                      </div>
                      <div>
                        <span>({value?.narration})</span>
                      </div>
                    </div>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    <div className="entry_data">
                      <div className="draw"></div>
                      <div className="to_pur">
                        <span>{value?.debitAmount}</span>
                      </div>
                    </div>
                  </CTableDataCell>
                  <CTableDataCell>
                    <div className="entry_data">
                      <div className="draw"></div>

                      <div className="to_cas">
                        <span>{value?.creditAmount}</span>
                      </div>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default JEntryData;
