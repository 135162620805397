import { CButton, CCardBody, CCol, CForm, CFormInput, CFormLabel, CRow, } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { emptyVerifiedData, verificationUser } from "src/redux/actions/userAction";
import { useSelector } from "react-redux";
const initialValues = {
  staffVerificationImage: null,
  remark: "",
};

const StaffVerification = ({ selectedData, setShowVerification }) => {
  const dispatch = useDispatch();
  const { newstaffverifiedData } = useSelector((state) => state?.userReducer)

  const SchemaName = sessionStorage.getItem("schemaName");
  const roleId = sessionStorage.getItem("emplyeeId");
  const santialRef = useRef();

  const [identification, setIdentification] = useState(initialValues);

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };

  const { remark } = identification;

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("verifiedByStaffId", roleId);
    formData.append("remark", remark);
    formData.append("verifiedByStaff", true);
    formData.append("staffVerificationImage", santialRef?.current?.files[0]);
    const obj = {
      id: selectedData?.id,
      data: formData,
    };
    dispatch(verificationUser(obj));
  };

  useEffect(() => {
    if (newstaffverifiedData?.status === "success") {
      dispatch(emptyVerifiedData())
      setShowVerification(false)
    }
  }, [newstaffverifiedData])

  return (
    <CCardBody>
      <div className="">
        <div>
          <h4 className="card-title">Customer Information</h4>
        </div>
        <div className="staffform_header"></div>
        <div className="cus_data">
          <div>
            <span className="cus_details">name :</span>
            <span className="cus_ap">{`${selectedData?.customer?.firstName} ${selectedData?.customer?.lastName}`}</span>
          </div>
          <div>
            <span className="cus_details">phonenumber :</span>
            <span className="cus_ap">
              {selectedData?.customer?.phoneNumber}
            </span>
          </div>
        </div>
        <CForm onSubmit={(e) => handleSubmit(e)} autoComplete="off">
          <CRow className="pt-20">
            <CCol>
              <CFormLabel>please upload a image with your home</CFormLabel>
              <CFormInput
                type="file"
                ref={santialRef}
                id="staffVerificationImage"
                onChange={onChangeValue}
              />
            </CCol>
            <CCol>
              <CFormLabel>Enter a Remark</CFormLabel>
              <CFormInput
                type="text"
                placeholder="enter a remark"
                id="remark"
                name="remark"
                value={remark}
                onChange={onChangeValue}
              />
            </CCol>
          </CRow>
          <div className="btn_verify">
            <CRow>
              <CCol xs={10}></CCol>
              <CCol>
                <CButton type="submit" color="primary" className="px-4">
                  Verify
                </CButton>
              </CCol>
            </CRow>
          </div>
        </CForm>
      </div>
    </CCardBody>
  );
};

StaffVerification.propTypes = {
  selectedData: PropTypes.object,
  setShowVerification: PropTypes.func,
};

export default StaffVerification;
