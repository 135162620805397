import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../navigations/AppRoutes";
import { CHeaderNav, CSidebarBrand } from "@coreui/react";
import navigation from "src/navigations/Menu";
import "./NavBar.css";
import Vakilkaro from "../assets/brand/vakilkaro";
import { ProfileDropdown } from "./Header";
import { useDispatch } from "react-redux";
import { getAllBranches } from "src/redux/actions/customerAction";

const getBreadcrumbs = (location) => {
  const breadcrumbs = [];
  const pathSegments = location.split("/").filter((segment) => segment !== "");

  let userData = sessionStorage.getItem("token");
  pathSegments.reduce((prev, curr) => {
    const currentPath = prev + "/" + curr;
    const route = routes.find((route) => route.path === currentPath);
    if (userData) {
      if (route) {
        breadcrumbs.push({
          pathname: currentPath,
          name: route.name,
          active: false,
        });
      }
    }
    return currentPath;
  }, "");

  if (breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].active = true;
  }

  return breadcrumbs;
};

const AppBreadcrumb = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navigationData, setNavigationData] = useState([]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const roletype = sessionStorage.getItem("role");
  const SchemaName = sessionStorage.getItem("schemaName");

  const obj = {
    schemaName: SchemaName,
  };

  useEffect(() => {
    dispatch(getAllBranches(obj));
    const filteredData = navigation?.filter(
      (element) => element.showOnlyForRole === roletype
    );
    setNavigationData(filteredData);
  }, []);

  return (
    <div className="d-flex row w-100 sticky-header" style={{ margin: 0 }}>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-logo ">
            <CSidebarBrand className="d-none d-md-flex" to="/">
              {/* <Vakilkaro /> */}
              <h3>Riyms</h3>
            </CSidebarBrand>
          </div>
          <div className={`navbar-links ${isOpen ? "active" : ""}`}>
            {navigationData?.map((item, index) => {
              return (
                <div className="pr-15" key={index}>
                  <div className="dropdown">
                    <Link to={item.to} className="dropbtn">
                      <div className="d-flex row">
                        <span>{item.name}</span>
                      </div>
                      <div className="dropdown-content">
                        {item?.items?.map((dropdown, index) => {
                          return (
                            <Link to={dropdown.to} key={index}>
                              {dropdown.name}
                            </Link>
                          );
                        })}
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="navbar-toggle" onClick={toggleMenu}>
            Menu
          </div>
          <CHeaderNav className="ms-3">
            <ProfileDropdown />
          </CHeaderNav>
        </div>
      </nav>
    </div>
  );
};

export default React.memo(AppBreadcrumb);
