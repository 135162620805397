import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatDate, getS3Url } from "src/services/helpers";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const LoanStatus = ({ customerData = [], emiData = [] }) => {
  const componentRef = React.useRef();
  const parsedAddress = customerData?.address;
  const { disbursements } = customerData;
  const { branch } = customerData;
  const panUrl = getS3Url(customerData?.panImageUrl);
  const aadhaarUrl = getS3Url(customerData?.aadharImageUrl);
  const aadharbackimage = getS3Url(customerData?.aadharbackimageurl);
  const bankstmtImg = getS3Url(customerData?.bankStatementsImageUrl);

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  var firstDisbursementAmount = null;
  var SanctionedLoanAmt = null;

  if (disbursements && disbursements?.length > 0) {
    firstDisbursementAmount = disbursements[0]?.DisbursementAmount;
  }

  const pendingEmis =
    emiData !== null || undefined
      ? emiData?.data?.filter((emi) => emi?.emiStatus === "pending")
      : [];
  const paidEmis =
    emiData !== null || undefined
      ? emiData?.data?.filter((emi) => emi?.emiStatus === "paid")
      : [];
  const lastEmi =
    emiData !== null || undefined ? emiData?.data[emiData?.data?.length] : [];

  const endInstallmentDate = lastEmi?.created;

  const handleClose = () => {
    setOpen(false);
  };

  const handleBankDialog = () => {
    setImageSrc(bankstmtImg);
    setOpen(true);
  };
  const handleAdharDialog = () => {
    setImageSrc(aadhaarUrl);
    setOpen(true);
  };

  const handleAdharBackDialog = () => {
    setImageSrc(aadharbackimage);
    setOpen(true);
  };

  const handlePanDialog = () => {
    setImageSrc(panUrl);
    setOpen(true);
  };

  const bankDialog = () => {
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Image Opened..."}</DialogTitle>
          <DialogContent>
            <img src={imageSrc} alt="imageSrc" style={{ width: "500px" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  const Guarantor = JSON.parse(customerData?.nominee)

  return (
    <div className="paymentrecord paymentrecord2">
      {bankDialog()}
      <div style={{ width: "100%" }}>
        <div className="form-wrapper">
          <div
            className="statement-form statement-form2"
            ref={componentRef}
            id="my-print"
          >
            <hr></hr>
            <div className="maxima">
              <div className="fifty">
                <div className="stmt-info">
                  <span className="info-head">
                    <p>Loan Reference Number</p>
                  </span>
                  <div className="info-input">
                    <>:</>
                    <span className="input-span caps-on">
                      {customerData?.loanAccountNumber}
                    </span>
                  </div>
                </div>
                <div className="stmt-info">
                  <span className="info-head">
                    <p>Customer name</p>
                  </span>
                  <div className="info-input">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {`${customerData?.firstName ? customerData?.firstName : ""
                        } `}
                    </span>
                  </div>
                </div>
              </div>
              <div className="fifty"></div>
            </div>
            <div className="stmt-headbar">
              <div className="stmt-headbar1">
                <div className="info-head">
                  <p>Customer Personal Details</p>
                </div>
                <div className="info-head">
                  {" "}
                  <p>Loan Details</p>
                </div>
              </div>
              <div className="stmt-headbar2">
                <p>All Amounts in INR</p>
              </div>
            </div>
            <div className="maximacopy">
              <div className="fiftycopyauto">
                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Full Name</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.firstName}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Gender</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.gender}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>{"Father's Name"}</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.fatherName}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>{"Grand Father's Name"}</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.grandFatherName}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Mobile Number</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span">
                      {customerData?.phoneNumber}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Email Address</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span">{customerData?.email}</span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>D.O.B.</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span">{customerData?.dob}</span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Marital Status</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.martialStatus === "married"
                        ? "Married"
                        : "Un-Married"}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Occupation</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span capitalize-first">
                      {customerData?.occupation}
                    </span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Income</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span">{customerData?.income}</span>
                  </div>
                </div>

                <div className="stmt-infocopy">
                  <span className="info-headcopy">
                    <p>Monthly Expenses</p>
                  </span>
                  <div className="info-inputcopy">
                    <>:</>
                    <span className="input-span">
                      {customerData?.monthlyExpenses}
                    </span>
                  </div>
                </div>



                <div className="stmt-headbarhalf">
                  <div className="stmt-headbar1">
                    <div className="info-head">
                      <p>Address Details</p>
                    </div>
                  </div>
                  <div className="stmt-headbar2"></div>
                </div>

                <div className="maxima50">
                  <div className="fiftycopyhalf">
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Aadhaar Address 1 : </p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {parsedAddress?.area}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Current Residency :</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {parsedAddress?.district}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Current Working Address :</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {parsedAddress?.city}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>State</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {parsedAddress?.state}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Country</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {parsedAddress?.country}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="stmt-headbarhalf">
                  <div className="stmt-headbar1">
                    <div className="info-head">
                      <p>1. Guarantor Details </p>
                    </div>
                  </div>
                  <div className="stmt-headbar2"></div>
                </div>
                <div className="maxima50">
                  <div className="fiftycopyhalf">
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Guarantor Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.GuarantorName}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Mobile Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor.mobileNumber}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>fathersName</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.fathersName}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>address</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="stmt-headbarhalf">
                  <div className="stmt-headbar1">
                    <div className="info-head">
                      <p>2. Guarantor Details</p>
                    </div>
                  </div>
                  <div className="stmt-headbar2"></div>
                </div>
                <div className="maxima50">
                  <div className="fiftycopyhalf">
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Guarantor Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.GuarantorName1}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Mobile Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor.mobileNumber1}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>fathersName</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.fathersName1}
                        </span>
                      </div>
                    </div>
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>address</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {Guarantor?.address1}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="fiftycopyauto">
                <div className="fiftycopyauto-box">
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Loan Account Number </p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span caps-on">
                        {customerData?.loanAccountNumber}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Loan Amount Requested</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span">
                        {customerData?.loanAmountRequested}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Credit Score</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span">
                        {customerData?.creditScore}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Loan Approval Status</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span capitalize-first">
                        {customerData?.loanApprovalStatus}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Approval Amount</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span">
                        {customerData?.approvalAmount}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Sanctioned Loan Amt</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span">{SanctionedLoanAmt}</span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Loan Term</p>
                    </span>
                    <div className="info-inputcopy">
                      <span>:</span>
                      <span className="input-span">
                        {customerData?.loanTerm}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Disbursed Amount</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {firstDisbursementAmount}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>ROI</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>

                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Tenure{"(Months)"}</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {customerData?.loanTerm}
                      </span>
                    </div>
                  </div>
                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Previous Installment Amount</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {paidEmis?.length > 0
                          ? paidEmis[paidEmis?.length - 1]?.emiAmount
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Next Installment Amount</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {pendingEmis?.length > 0
                          ? pendingEmis[1]?.emiAmount
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>Next Installment Due Date</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {pendingEmis?.length > 0 ? pendingEmis[1]?.created : ""}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>First Due Date</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">
                        {pendingEmis?.length > 0 ? pendingEmis[0]?.created : ""}
                      </span>
                    </div>
                  </div>

                  <div className="stmt-infocopy">
                    <span className="info-headcopy">
                      <p>End Installment Date</p>
                    </span>
                    <div className="info-inputcopy">
                      <>:</>
                      <span className="input-span">{endInstallmentDate}</span>
                    </div>
                  </div>
                </div>
                <div className="stmt-headbarhalf" style={{ marginTop: "30px" }}>
                  <div className="stmt-headbar1">
                    <div className="info-head">
                      <p>Bank Details</p>
                    </div>
                  </div>
                  <div className="stmt-headbar2"></div>
                </div>
                <div className="maxima50">
                  <div className="fiftycopyhalf">
                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Bank Account Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span">
                          {customerData?.bankAccountNo}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>IFSC Code</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {customerData?.ifscCode}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Bank Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {customerData?.bankName}
                        </span>
                      </div>
                    </div>

                    <div
                      className="stmt-infocopy"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="info-headcopy info-headcopy-in">
                        <p>Bank Statement Image</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span">
                          <img
                            onClick={handleBankDialog}
                            src={bankstmtImg}
                            alt="statementImage"
                            width={"150px"}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p> Branch Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {branch?.branchName}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Branch Code</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span capitalize-first">
                          {branch?.branchCode}
                        </span>
                      </div>
                    </div>

                    <div className="stmt-infocopy">
                      <span className="info-headcopy info-headcopy-in">
                        <p>Contact Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="input-span">
                          {branch?.contactNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="stmt-headbar-iii global-margin-top">
              <div className="stmt-headbar1-iii">
                <div className="info-head">
                  <p>Customer PAN Details</p>
                </div>
                <div className="info-head">
                  <p>Customer Adhaar Details</p>
                </div>
              </div>
              <div className="maxima">
                <div className="fifty">
                  <div className="stmt-info">
                    <span className="info-head">
                      <p>PAN Number</p>
                    </span>
                    <div className="info-input">
                      <>:</>
                      <span className="input-span capitalize-first">
                        {customerData?.PanNumber}
                      </span>
                    </div>
                  </div>

                  <div
                    className="stmt-info"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="info-head">
                      <p>PAN Image</p>
                    </span>
                    <div className="info-input">
                      <>:</>
                      <span className="input-span">
                        <img
                          onClick={handlePanDialog}
                          src={panUrl}
                          alt="PANImage"
                          width={"150px"}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="fifty">
                  <div className="stmt-info">
                    <span className="info-head">
                      <p>Aadhaar Number </p>
                    </span>
                    <div className="info-input">
                      <>:</>
                      <span className="input-span">
                        {customerData?.aadharNumber}
                      </span>
                    </div>
                  </div>

                  <div
                    className="stmt-info"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="info-head">
                      <p>Aadhaar Image (Front)</p>
                    </span>
                    <div className="info-input">
                      <>:</>
                      <span className="input-span">
                        <img
                          onClick={handleAdharDialog}
                          src={aadhaarUrl}
                          alt="aadharImage"
                          width={"150px"}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="stmt-info"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="info-head">
                      <p>Aadhaar Image (Back)</p>
                    </span>
                    <div className="info-input">
                      <>:</>
                      <span className="input-span">
                        <img
                          onClick={handleAdharBackDialog}
                          src={aadharbackimage}
                          alt="aadharImage"
                          width={"150px"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="stmt-headbar-iii global-margin-top">
              <div className="stmt-headbar1-iii"></div>

              <div className="stmt-headbar2-iii">
                <div className="info-head">
                  <p>Disbursement Details</p>
                </div>
              </div>
              <div className="table-div">
                <table>
                  <tr>
                    <th style={{ textAlign: "center" }}>Disbursement Amount</th>
                    <th style={{ textAlign: "center" }}>Disbursement Date</th>
                  </tr>
                  {customerData?.disbursements?.map((disbursement, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {disbursement?.DisbursementAmount}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {formatDate(disbursement?.DisbursementDate)}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
            <div className="stmt-headbar-iii global-margin-top">
              <div className="stmt-headbar1-iii"></div>

              <div className="stmt-headbar2-iii">
                <div className="info-head">
                  <p>Payment Details</p>
                </div>
              </div>
              <div className="table-div">
                <table>
                  <tr>
                    <th style={{ textAlign: "center" }}>Payment Date</th>
                    <th style={{ textAlign: "center" }}>Payment Mode</th>
                    <th style={{ textAlign: "center" }}>Payment Amt.</th>
                    <th style={{ textAlign: "center" }}>Received By</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                  </tr>
                  {customerData?.payments?.map((payment, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {formatDate(payment?.paymentDate)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {payment?.paymentMode}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {parseFloat(payment?.paymentAmount).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {payment?.receivedBy}
                      </td>
                      <td style={{ textAlign: "center" }}>{payment?.status}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoanStatus.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};

export default LoanStatus;
