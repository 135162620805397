export function getInitialRegistrationData(data) {
  const {
    dob,
    email,
    empStartDate,
    fatherName,
    grandFatherName,
    firstName,
    gender,
    lastName,
    loanAmountRequsted,
    loanTerm,

    loanType,
    martialStatus,
    middleName,
    occupation,
    phoneNumber,
  } = data;
  return {
    dob,
    email,
    empStartDate,
    fatherName,
    grandFatherName,

    firstName,
    gender,
    lastName,
    loanAmountRequsted,
    loanTerm,
    loanType,
    martialStatus,
    middleName,
    occupation,
    phoneNumber,
  };
}
export function getInitialAdressData(data) {
  const {
    pincode,
    country,
    area,
    city,
    district,
    state,
    area1,
    Landmark1,
    city1,
    state1,
    country1,
    pincode1,
    area2,
    Landmark2,
    city2,
    state2,
    country2,
    pincode2,
  } = data;
  return {
    pincode,
    country,
    area,
    city,
    district,
    state,
    area1,
    Landmark1,
    city1,
    state1,
    country1,
    pincode1,
    area2,
    Landmark2,
    city2,
    state2,
    country2,
    pincode2,
  };
}
