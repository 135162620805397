import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import authInstance from "src/api/apiConfig";
import { errorAlert, successAlert } from "src/utils/Swal";

const initialValues = {
  aadharImageUrl: null,
  aadharBackImageUrl: null,
  passportSizePhoto: null,
  guarantorImageUrl: null,
  billImage: null,
  lockScreenImageUrl: null,
};

const resizeImage = (file, maxWidth, maxHeight, quality, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let { width, height } = img;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          callback(blob);
        },
        file.type,
        quality
      );
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
};

const ForImageUpload = (props) => {
  const { uploadDocument, setShowUploadModal } = props;

  const aadharRef = useRef();
  const aadharBackRef = useRef();
  const photoRef = useRef();
  const guarantorRef = useRef();
  const billImageRef = useRef();
  const lockScreenRef = useRef();

  const [identification, setIdentification] = useState(initialValues);
  const onChangeValue = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      // Validate file size
      if (file.size < 1 * 1024 || file.size > 2048 * 1024) {
        errorAlert(
          "Invalid file size",
          "File size must be between 1 KB and 2 mb"
        );
        return;
      }

      // Validate file type
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      if (!allowedTypes.includes(file.type)) {
        errorAlert(
          "Invalid file type",
          "Only JPG, JPEG, PNG, and PDF files are allowed"
        );
        return;
      }

      resizeImage(file, 500, 500, 0.8, (resizedBlob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setIdentification((prev) => ({
            ...prev,
            [name]: reader.result,
          }));
        };
        reader.readAsDataURL(resizedBlob);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadData = new FormData();
    uploadData.append("aadharImageUrl", aadharRef.current.files[0]);
    uploadData.append("aadharbackimageurl", aadharBackRef.current.files[0]);
    uploadData.append("passportSizePhoto", photoRef.current.files[0]);
    uploadData.append("guarantorimageurl", guarantorRef.current.files[0]);
    uploadData.append("billImage", billImageRef.current.files[0]);
    uploadData.append("lockscreenimageurl", lockScreenRef.current.files[0]);

    try {
      const response = await authInstance.patch(
        `/customers/${uploadDocument}`,
        uploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Optional, FormData should set this automatically
          },
        }
      );
      if (response.status === 201) {
        successAlert("Document uploaded successfully");
        setShowUploadModal(false);
      }
    } catch (error) {
      errorAlert("failed", "Something went wrong");
    }
  };

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={handleSubmit} autoComplete="off">
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="aadharImageUrl">
                Upload Aadhar Card Front
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={aadharRef}
                id="aadharImageUrl"
                name="aadharImageUrl"
                placeholder="Upload Aadhar Card"
                onChange={onChangeValue}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="aadharBackImageUrl">
                Upload Aadhar Card Back
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={aadharBackRef}
                id="aadharBackImageUrl"
                name="aadharBackImageUrl"
                placeholder="Upload Aadhar Card"
                onChange={onChangeValue}
              />
            </CCol>
          </CRow>
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="passportSizePhoto">
                Upload Passport Size Photo
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={photoRef}
                id="passportSizePhoto"
                name="passportSizePhoto"
                placeholder="Upload Passport Size Photo"
                onChange={onChangeValue}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="guarantorImageUrl">
                Upload Image With Guarantor
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={guarantorRef}
                id="guarantorImageUrl"
                name="guarantorImageUrl"
                placeholder=" Upload Image With Guarantor"
                onChange={onChangeValue}
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="billImage">
                Upload Bill
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={billImageRef}
                id="billImage"
                name="billImage"
                placeholder="Upload Bill"
                onChange={onChangeValue}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="lockScreenImageUrl">
                Lock Screen With Box Image
                <span className="red-star">*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={lockScreenRef}
                id="lockScreenImageUrl"
                name="lockScreenImageUrl"
                placeholder="Lock Screen With Box Image"
                onChange={onChangeValue}
              />
            </CCol>
          </CRow>

          <CRow className="pt-20 mb-20">
            <CCol xs={6}>
              <div className="identification_btn" style={{ width: "100%" }}>
                <CButton type="submit" style={{ width: "70%" }}>
                  Upload
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CForm>

        {/* Image Previews */}
        <div className="image-previews">
          {Object.keys(identification).map(
            (key) =>
              identification[key] && (
                <div key={key} className="image-preview">
                  <img
                    src={identification[key]}
                    alt={key}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <p>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </p>
                </div>
              )
          )}
        </div>
      </CContainer>
    </CCardBody>
  );
};

ForImageUpload.propTypes = {
  uploadDocument: PropTypes.number.isRequired,
  setShowUploadModal: PropTypes.func.isRequired,
};

export default ForImageUpload;
