import React, { useEffect, useRef, useState } from "react";
import { CButton, CContainer, CForm, CFormInput, CFormSelect, CRow, CCardBody, CCol, CFormLabel, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from "@coreui/react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { addDisbursement } from "src/redux/actions/staffAction";
import { useSelector } from "react-redux";
import { createEmi } from "src/redux/actions/emiAction";
import { successAlert } from "src/utils/Swal";
import { useNavigate } from "react-router-dom";
import { formatDate } from "src/services/helpers";

const initialValues = {
  utrImageUrl: null,
};

const Disbursement = ({ customerData = [], emiData = [] }) => {

  const dispatch = useDispatch();
  const { staffReducer } = useSelector((state) => state);
  const { loandata } = useSelector((state) => state?.customerReducer);

  const SchemaName = sessionStorage.getItem("schemaName")
  const { approvalAmount, disbursements } = customerData;
  const [fileCharges, setFileCharges] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [identification, setIdentification] = useState(initialValues);
  const [emiStatus, setEmiStatus] = useState("");
  const [paymentfrequency, setPaymentfrequency] = useState("");
  const [approvedAmount, setApprovedAmount] = useState("");
  const [status, setStatus] = useState(false);
  const approvalAmountData = Number(approvalAmount);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setApprovedAmount(loandata?.approvalAmount);
    setLoanAmount(approvalAmountData || 0);
  }, [approvalAmount, loandata]);

  useEffect(() => {
    calculateTotalAmount();
  }, [approvedAmount, fileCharges, otherCharges]);

  const handleFileChargesChange = (event) => {
    const charges = parseFloat(event.target.value) || 0;
    setFileCharges(charges);
  };

  const handleOtherChargesChange = (event) => {
    const charges = parseFloat(event.target.value) || 0;
    setOtherCharges(charges);
  };

  const calculateTotalAmount = () => {
    const total = approvedAmount + fileCharges + otherCharges;
    setTotalAmount(total);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleDisbursement = () => {
    const formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("customerId", customerData?.id);
    formData.append("DisbursementDate", currentDate);
    formData.append("DisbursementAmount", approvedAmount);
    formData.append("fileCharges", fileCharges);
    formData.append("otherCharges", otherCharges);
    formData.append("utrNumber", null);
    formData.append("utrDate", currentDate);
    formData.append("loanId", loandata?.id);
    formData.append("utrImageUrl", null);
    dispatch(addDisbursement(formData));
    setStatus(true);
  };

  useEffect(() => {
    if (staffReducer?.disbursement !== null) {
      if (status === true) {
        successAlert("success", "Disbursement has been Done");
        const emiCreate = {
          schemaName: SchemaName,
          loadAmount: totalAmount,
          loadDurationInMonths: loandata?.numberOfEmis,
          annualInterestRate: loandata?.interest?.rate,
          customerId: customerData?.id,
          emitype: emiStatus,
          paymentfrequency: paymentfrequency,
          loanId: loandata?.id,
        };
        dispatch(createEmi(emiCreate));
        setStatus(false);
      }
    }
  }, [staffReducer]);

  return (
    <>
      <div>
        <CTable hover className="" responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col" className="col-md-2">
                <span className="spn">Disbursement Date</span>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                <span className="spn">Disbursement Amount</span>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                <span className="spn">File Charges</span>
              </CTableHeaderCell>

              <CTableHeaderCell scope="col" className="col-md-2">
                <span className="spn">Other Charges</span>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2">
                <span className="spn">UTR Number</span>
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {disbursements?.map((company, index) => (
              <CTableRow key={index}>
                <CTableDataCell>
                  {company?.DisbursementDate ? formatDate(company?.DisbursementDate) : "-"}
                </CTableDataCell>
                <CTableDataCell>
                  {company?.DisbursementAmount
                    ? company?.DisbursementAmount
                    : "-"}
                </CTableDataCell>
                <CTableDataCell>
                  {company?.fileCharges ? company?.fileCharges : "-"}
                </CTableDataCell>
                <CTableDataCell>
                  {company?.otherCharges ? company?.otherCharges : "-"}
                </CTableDataCell>
                <CTableDataCell>
                  {company?.utrNumber ? company?.utrNumber : "-"}
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        ></div>
      </div>

      <CRow>
        <CCol xs={12}>
          <CCardBody>
            <CContainer>
              <CForm
                // onSubmit={(event) => handleSubmit(event, isEdit)}
                autoComplete="off"
              >
                <div className="staffform_header">
                  Loan Disbursement Details :
                </div>
                <CRow className="pt-20">
                  <CCol xs={6}>
                    <CFormLabel htmlFor="loanAmount">Loan Amount</CFormLabel>
                    <CFormInput
                      type="number"
                      id="loanAmount"
                      placeholder="Loan Amount"
                      name="loanAmount"
                      value={approvedAmount}
                      onChange={(e) => setApprovedAmount(parseFloat(e.target.value))}
                      required
                    />
                  </CCol>
                  <CCol xs={6}>
                    <CFormLabel htmlFor="FileCharges">File Charges</CFormLabel>
                    <CFormInput
                      type="number"
                      id="FileCharges"
                      placeholder="File Charges"
                      name="FileCharges"
                      value={fileCharges}
                      onChange={handleFileChargesChange}
                      required
                    />
                  </CCol>
                </CRow>
                <CRow className="pt-20">
                  <CCol xs={6}>
                    <CFormLabel htmlFor="empbranchCode">
                      Other Charges
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      id="empbranchCode"
                      placeholder="Other Charges"
                      name="otherCharges"
                      value={otherCharges}
                      onChange={handleOtherChargesChange}
                      required
                    />
                  </CCol>

                  {/* <CCol xs={6}>
                    <CFormLabel htmlFor="utrNumber">
                      UTR Number/IMPS/NEFT Details
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="utrNumber"
                      placeholder="UTR Number"
                      name="utrNumber"
                      value={utrNumber}
                      onChange={(e) => setUtrNumber(e.target.value)}
                      required
                    />
                  </CCol> */}

                  {/* <CRow > */}
                  {/* <CCol xs={12} className="pt-20">
                    <CFormLabel htmlFor="empbranchCode">
                      UTR Number/IMPS/NEFT Image
                    </CFormLabel>
                    <CFormInput
                      type="file"
                      ref={utrRef}
                      id="utrImageUrl"
                      placeholder="Upload UtR "
                      onChange={onChangeValue}
                    />
                  </CCol> */}
                  {/* </CRow> */}
                </CRow>
                <CRow className="pt-20">
                  <CCol xs={6} >
                    <CFormLabel htmlFor="emiStatus">EMI Type</CFormLabel>
                    <CFormSelect
                      name="emiStatus"
                      value={emiStatus}
                      onChange={(e) => setEmiStatus(e.target.value)}
                    >
                      <option value="">Select EMI Type</option>
                      <option value="flatRate">Flat Rate</option>
                      <option value="ReducedRate">Reducing Rate</option>
                    </CFormSelect>
                  </CCol>
                  <CCol xs={6} >
                    <CFormLabel htmlFor="paymentfrequency">
                      Payment Frequency
                    </CFormLabel>
                    <CFormSelect
                      name="paymentfrequency"
                      value={paymentfrequency}
                      onChange={(e) => setPaymentfrequency(e.target.value)}
                    >
                      <option value="">Select Payment Frequency</option>
                      {/* <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="fournightly">Fournightly</option> */}
                      <option value="monthly">Monthly</option>
                    </CFormSelect>
                  </CCol>
                </CRow>
                <CRow className="pt-20">
                  <CCol xs={5}></CCol>
                  <CCol xs={7}>
                    <CRow>
                      {/* <CFormLabel>Total Amount: {totalAmount}</CFormLabel> */}
                      <CCol xs={3}>
                        <CButton
                          onClick={() => handleDisbursement()}
                          color="primary"
                          className="w-100"
                        >
                          {isEdit ? "Update" : "Submit"}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CForm>
            </CContainer>
          </CCardBody>
        </CCol>
      </CRow>
    </>
  );
};
Disbursement.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};
export default Disbursement;
