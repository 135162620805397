import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CCardHeader,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import DeleteModal from "src/components/commonComponents/commonDeleteModal";
import { Button } from "react-bootstrap";
import { getInterest } from "src/api/allApis";
import ProductAddForm from "../ProductAddForm";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { refreshInterestList } from "src/redux/actions/loanListAction";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const ProductList = () => {
  const dispatch = useDispatch();
  const componentPDF = useRef();
  const { addData, updateData, productUpdatedData } = useSelector((state) => state?.interestsReducer);
  const { deletedData } = useSelector((state) => state?.companyReducer);

  const [showModal, setShowModal] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [interestData, setInterestData] = useState([]);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [currItem, setCurrItem] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [printState, setPrintState] = useState(false);
  const roleTy = sessionStorage.getItem("role");

  useEffect(() => {
    if (printState) { generatePDF(); }
  }, [printState]);

  useEffect(() => {
    getAllInterestList();
    dispatch(refreshInterestList());
  }, [addData, updateData, productUpdatedData, deletedData]);

  const deleteCallBack = () => { };

  const getAllInterestList = () => {
    const payload = { schemaName: SchemaName };
    getInterest(payload).then((res) => {
      setInterestData(res.data);
    });
  };

  const handleShow = () => setShowModal(true);

  const handleDelete = () => {
    setSelectedItemId(null);
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);
    setCompanyModel(true);
    handleShow();
  };

  const [showEdit, setShowEdit] = useState(false);

  const handleShowAddForm = (details) => {
    setShowAddForm(true);
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setCurrItem(details);
    setShowEdit(false);
  };

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = interestData?.slice(indexOfFirstRow, indexOfLastRow);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const role = sessionStorage.getItem("role");

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <CRow className="content">
        {showAddForm === true ? (
          <CCard className="mb-4">
            <ProductAddForm
              selectedCompany={selectedCompany}
              setShowAddForm={setShowAddForm}
              setShowAddButton={setShowAddButton}
              setShowSelectAndSearch={setShowSelectAndSearch}
              showAddForm={showAddForm}
              intial={currItem}
              edit={showEdit}
            />
          </CCard>
        ) : (
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Loan Type</strong>
              </CCardHeader>
              <CCardBody>
                <CCard>
                  <div className="search_bar">
                    <div className="search-inner">
                      {showSelectAndSearch && (
                        <div>
                          <CFormSelect
                            size="sm"
                            className="mb-3"
                            style={{ cursor: "pointer" }}
                            aria-label="Small select example"
                            onChange={handleRowsPerPageChange}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </CFormSelect>
                        </div>
                      )}
                      <div style={{ display: "flex" }}>
                        {showSelectAndSearch && (
                          <>
                            <CFormInput
                              type="text"
                              size="sm"
                              placeholder="Search"
                              aria-label="sm input example"
                              style={{ margin: "0px 0px 16px 0px" }}
                              onChange={handleSearchChange}
                              value={searchInput}
                            />

                            <span
                              style={{
                                border: " 1px solid #d1c5c5",
                                borderRadius: "4px",
                                margin: "0px 5px 16px -1px",
                                padding: "1px 8px 0px 8px",
                                background: "#ebedef",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-search"></i>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    {roleTy === "company" ? (
                      <div className="btn_add mt-0 mb-14">
                        {showAddButton && (
                          <Button
                            className="add-btn w-auto plr-30 mr-10"
                            onClick={handleShowAddForm}
                          >
                            Add
                          </Button>
                        )}
                        {showAddButton && (
                          <Button
                            className="add-btn w-auto mr-10"
                            onClick={download_btn}
                          >
                            Download
                          </Button>
                        )}
                        {showAddButton && (
                          <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"loan-table.csv"}>
                            <CButton className="add-btn w-auto m-0">
                              CSV
                            </CButton>
                          </CSVLink>
                        )}
                      </div>
                    ) : roleTy === "employee" ? (
                      ""
                    ) : roleTy === "manager" ? (
                      ""
                    ) : (
                      ""
                    )}

                  </div>

                  {/* </div> */}
                  <CCardBody>
                    <div
                      className={`table-container ${showAccordion ? "hidden" : ""
                        } ${showAddForm ? "hidden" : ""}`}
                    >
                      <div
                        ref={printState ? componentPDF : null}
                        style={{ padding: printState ? "20px" : "0px" }}
                      >
                        {printState && (
                          <div className="print-div">
                            <div>
                              {/* <Vakilkaro /> */}
                              <h3>Riyms</h3>
                            </div>
                            <div>
                              <p>{COMPANY_NAME}</p>
                            </div>
                          </div>
                        )}
                        <CTable color="dark" hover responsive>
                          <CTableHead className="tabl-head">
                            <CTableRow className="table-row">
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-2"
                              >
                                S. No.
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-4"
                              >
                                Loan Type
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-4"
                              >
                                Rate
                              </CTableHeaderCell>

                              {role === "manager"
                                ? ""
                                : !printState && (
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-2"
                                  >
                                    Action
                                  </CTableHeaderCell>
                                )}
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {interestData && interestData.length > 0 ? (
                              currentRows
                                ?.filter((item) =>
                                  item.loanType
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase())
                                )
                                .map((item, index) => {
                                  return (
                                    <CTableRow key={index}>
                                      <CTableDataCell>{indexOfFirstRow + index + 1}</CTableDataCell>
                                      <CTableDataCell>
                                        {item.loanType}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {item.rate}
                                      </CTableDataCell>
                                      {role === "manager"
                                        ? ""
                                        : !printState && (
                                          <CTableDataCell className="col-md-3">
                                            <>
                                              <Tooltip text="Update">
                                                <FontAwesomeIcon
                                                  role="button"
                                                  icon={faPenToSquare}
                                                  size="1x"
                                                  onClick={() => {
                                                    handleShowAddForm(item);
                                                    setShowEdit(true);
                                                  }}
                                                  className="px-3 updateIcon"
                                                />
                                              </Tooltip>
                                            </>
                                          </CTableDataCell>
                                        )}
                                    </CTableRow>
                                  );
                                })
                            ) : (
                              // Display a loading message or handle the case where data is not available
                              <div className="loading_data">Loading...</div>
                            )}
                          </CTableBody>
                        </CTable>
                      </div>
                      <div
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="pagin-div">
                          <div>
                            Showing {indexOfFirstRow + 1} to{" "}
                            {Math?.min(indexOfLastRow, interestData?.length)} of{" "}
                            {interestData?.length} entries
                          </div>
                          <div className="pagination-container">
                            <ReactPaginate
                              activePage={currentPage}
                              itemsCountPerPage={rowsPerPage}
                              totalItemsCount={interestData?.length}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                              prevPageText="Previous"
                              nextPageText="Next"
                              firstPageText="First"
                              lastPageText="Last"
                              innerClass="pagination"
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>
    </>
  );
};

export default ProductList;
