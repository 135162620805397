import React, { useState } from "react";
import { CButton } from "@coreui/react";
import PropTypes from "prop-types";
import { getS3Url } from "src/services/helpers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "react-bootstrap";

const StaffDetailsById = ({
  selectedCompany,
  setShowAccordion,
  setShowSelectAndSearch,
}) => {
  const panUrl = getS3Url(selectedCompany?.panImageUrl);
  const aadhaarUrl = getS3Url(selectedCompany?.aadharImageUrl);
  const degreeAndCertificateImageUrl = getS3Url(selectedCompany?.degreeAndCertificateImageUrl);

  const signatureUrl = getS3Url(selectedCompany?.signatureImageUrl);

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleClose = () => { setOpen(false) }

  const handleDialog = (imageSrc) => {
    setImageSrc(imageSrc);
    setOpen(true);
  };

  const bankDialog = () => {
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Image Opened..."}</DialogTitle>
          <DialogContent>
            <img src={imageSrc} alt="imageSrc" style={{ width: "500px" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  const handleShowAndHide = () => {
    setShowSelectAndSearch(true);
    setShowAccordion(false);
  };

  return (
    <>
      <div className="close_btn ">
        <strong>{selectedCompany?.firstName} {selectedCompany?.middleName !== "undefined"
          ? selectedCompany?.middleName
          : ""} {selectedCompany?.lastName}</strong>
        <CButton onClick={handleShowAndHide}>Back</CButton>
      </div>

      <div style={{ width: "100%" }}>
        <div>
          <div id="my-print">
            <hr></hr>
            {bankDialog()}
            <div className="maxima">
              <div className="fifty">
                Branch Name : {selectedCompany?.branch?.branchName}
              </div>
              <div className="fifty"></div>
            </div>
            <div className="stmt-headbar">
              <div className="stmt-headbar2">
                <p>All Amounts in INR</p>
              </div>
            </div>
            <div className="maximacopy">
              <div style={{ width: "100%" }}>
                <div className="monus">
                  <div style={{ width: "30%" }}>
                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>First Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.firstName}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Middle Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.middleName !== "undefined"
                            ? selectedCompany?.middleName
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Last Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.lastName}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Gender</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.gender}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Mobile Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">
                          {selectedCompany?.phoneNumber}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>E-mail Address</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">{selectedCompany?.email}</span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>D.O.B.</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">{selectedCompany?.dob}</span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Date Of Hire</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">
                          {selectedCompany?.dateOfHire}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Manager Name</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.managerName}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Employee Role</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.employeeRole}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Basic Salary</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">
                          {selectedCompany?.basicSalary}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>PAN Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp caps-on">
                          {selectedCompany?.PanNumber}
                        </span>
                      </div>
                    </div>

                    <div
                      className="sminfo"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="info-headcopy">
                        <p>PAN Image</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>{" "}
                        <span className="new_sp">
                          <img
                            onClick={() => handleDialog(panUrl)}
                            src={panUrl}
                            alt="PAN"
                            width={"100px"}
                          />

                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Aadhar Number</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">
                          {selectedCompany?.aadharNumber}
                        </span>
                      </div>
                    </div>

                    <div
                      className="sminfo"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="info-headcopy">
                        <p>Aadhaar Image</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>{" "}
                        <span className="new_sp">
                          <img
                            onClick={() => handleDialog(aadhaarUrl)}
                            src={aadhaarUrl}
                            alt="Aadhaar"
                            width={"100px"}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Bank Account No</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp">
                          {selectedCompany?.bankAccountNo}
                        </span>
                      </div>
                    </div>

                    <div className="sminfo">
                      <span className="info-headcopy">
                        <p>Job Title</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        <span className="new_sp capitalize-first">
                          {selectedCompany?.jobTitle}
                        </span>
                      </div>
                    </div>

                    <div
                      className="sminfo"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="info-headcopy">
                        <p>Signature Image</p>
                      </span>
                      <div className="info-inputcopy">
                        <>:</>
                        {" "}
                        <span className="new_s">
                          <img
                            onClick={() => handleDialog(signatureUrl)}
                            src={signatureUrl}
                            alt="Signature"
                            width={"100px"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "50%" }}>
                    <div className="stmt-headbarhalf">
                      <div className="stmt-headbar1">
                        <div className="info-head">
                          <p>Other Details</p>
                        </div>
                      </div>
                      <div className="stmt-headbar2"></div>
                    </div>

                    <div className="maxima50">
                      <div className="fiftycopyhalf">

                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Degree And Certificate</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.degreeAndCertificate}
                            </span>
                          </div>
                        </div>

                        <div
                          className="sminfo"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span className="info-headcopy info-headcopy-in">
                            <p>Degree & Certificate Image</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {" "}
                              <img
                                onClick={() => handleDialog(degreeAndCertificateImageUrl)}
                                src={degreeAndCertificateImageUrl}
                                alt="Degree and Certificate"
                                width={"100px"}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Address</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.address}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>D.L. Number</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp caps-on">
                              {selectedCompany?.dlNumber}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Emergency Contact Information</p>
                          </span>
                          <div className="info-inputcopy capitalize-first">
                            <>:</>
                            <span className="new_sp">
                              {selectedCompany?.emergencyContactInformation}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Emergency Contact Number</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp">
                              {selectedCompany?.emergencyContactNumber}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Highest Education</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.highestEducation}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Identification</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.identification}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>IFSC Code</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp">
                              {selectedCompany?.ifscCode}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>MedicaI Insurance</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.medicaInsurance}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Passport Number</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp caps-on">
                              {selectedCompany?.passportNumber}
                            </span>
                          </div>
                        </div>
                        <div className="sminfo">
                          <span className="info-headcopy info-headcopy-in">
                            <p>Previous Work Experience</p>
                          </span>
                          <div className="info-inputcopy">
                            <>:</>
                            <span className="new_sp capitalize-first">
                              {selectedCompany?.previousWorkExperience}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* <CCardBody>
        <CRow style={{ margin: "20px 0px 0px 10px" }}>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff First Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.firstName} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff Middle Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.middleName} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff Last Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.lastName} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={6}>
            <label className="label">Staff Job Title</label>
            <CCard>
              <CCardBody>{selectedCompany?.jobTitle} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Mobile Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.phoneNumber} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <div className="mt-2"></div>
        <CRow>
          <CCol xs={6}>
            <label>Staff Mail Id</label>
            <CCard>
              <CCardBody>{selectedCompany?.email} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Aadhar Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.aadharNumber} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <div className="mt-2"></div>
        <CRow>
          <CCol xs={6}>
            <label>Staff Bank Account No.</label>
            <CCard>
              <CCardBody>{selectedCompany?.bankAccountNo} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff IFSC Code</label>
            <CCard>
              <CCardBody>{selectedCompany?.ifscCode}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Basic Salary</label>
            <CCard>
              <CCardBody>{selectedCompany?.basicSalary}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Degree And Certificate</label>
            <CCard>
              <CCardBody>{selectedCompany?.degreeAndCertificate}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Driving Licence</label>
            <CCard>
              <CCardBody>{selectedCompany?.dlNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff D.O.B.</label>
            <CCard>
              <CCardBody>{selectedCompany?.dob}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Emergency Contact Information</label>
            <CCard>
              <CCardBody>
                {selectedCompany?.emergencyContactInformation}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Emergency Contact Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.emergencyContactNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff/Employee Branch Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.branch?.branchName}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Employee Role</label>
            <CCard>
              <CCardBody>{selectedCompany?.employeeRole}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Gender</label>
            <CCard>
              <CCardBody>{selectedCompany?.gender}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Highest Education</label>
            <CCard>
              <CCardBody>{selectedCompany?.emergencyContactNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Identification</label>
            <CCard>
              <CCardBody>{selectedCompany?.identification}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Manager Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.managerName}</CCardBody>
            </CCard>
          </CCol>{" "}
          <CCol xs={6}>
            <label>Staff Medical Insurance</label>
            <CCard>
              <CCardBody>{selectedCompany?.medicaInsurance}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Passport Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.passportNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Previous Work Experience</label>
            <CCard>
              <CCardBody>{selectedCompany?.previousWorkExperience}</CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody> */}
    </>
  );
};
StaffDetailsById.propTypes = {
  setShowAccordion: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
};

export default StaffDetailsById;
