import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  ADD_USER_DATA_SUCCESS,
  DELETE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_SUCCESS,
  GET_STAFF_BY_ID_SUCCESS,
  GET_STAFF_BY_ID,
  CREATE_VERIFICATION_SUCCESS,
  CREATE_VERIFICATION,
  UPDATE_VERIFICATION,
  UPDATE_VERIFICATION_SUCCESS,
  CREATE_GROUPS,
  CREATE_GROUPS_SUCCESS,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_SUCCESS,
  GET_GROUP_DETAIL_BY_ID,
  GET_GROUP_DETAIL_BY_ID_SUCCESS,
  GET_ALL_VERIFIED_CUSTOMER,
  GET_ALL_VERIFIED_CUSTOMER_SUCCESS,
  CREATE_NEW_CUSTOMER_SUCCESS,
  CREATE_NEW_CUSTOMER,
} from "../action_Types";

function* allUser() {
  try {
    const response = yield call(authInstance.get, "");

    yield put({
      type: GET_ALL_USER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addUser(action) {
  try {
    const response = yield call(authInstance.post, "", action.payload);

    yield put({
      type: ADD_USER_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(authInstance.delete, `/${action.payload}`);

    yield put({
      type: DELETE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateUser(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `/${id}`, data);
    yield put({
      type: UPDATE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getStaffById(action) {
  try {
    const { id, schemaName } = action.payload;
    const obj = {
      schemaName: schemaName,
    };
    const response = yield call(authInstance.post, `employeedata/${id}`, obj);
    yield put({
      type: GET_STAFF_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createVerification(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(authInstance.patch, `verification/${id}`, data);
    yield put({
      type: CREATE_VERIFICATION_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateVerification(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(authInstance.patch, `verification/${id}`, data);
    yield put({
      type: UPDATE_VERIFICATION_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

// =================================  GROUP SECTION  ================================================
function* createGroup(action) {
  try {
    const response = yield call(authInstance.post, "addGroup", action.payload);
    yield put({
      type: CREATE_GROUPS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllGroup(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllGroups",
      action.payload
    );
    yield put({
      type: GET_ALL_GROUPS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getGroupDetailById(action) {
  const { id, schemaName } = action.payload;
  const data = {
    schemaName: schemaName,
  };
  try {
    const response = yield call(authInstance.post, `group/${id}`, data);
    yield put({
      type: GET_GROUP_DETAIL_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllverifiedCustomer(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllVerifications",
      action.payload
    );
    yield put({
      type: GET_ALL_VERIFIED_CUSTOMER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createNewCustomer(action) {
  try {
    const response = yield call(
      authInstance.post,
      "customersBackData",
      action.payload
    );
    yield put({
      type: CREATE_NEW_CUSTOMER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* userActionWatcher() {
  yield takeLatest(GET_ALL_USER, allUser);
  yield takeLatest(CREATE_NEW_CUSTOMER, createNewCustomer);
  yield takeLatest(ADD_USER_DATA, addUser);
  yield takeLatest(CREATE_GROUPS, createGroup);
  yield takeLatest(GET_ALL_GROUPS, getAllGroup);
  yield takeLatest(GET_GROUP_DETAIL_BY_ID, getGroupDetailById);
  yield takeLatest(GET_ALL_VERIFIED_CUSTOMER, getAllverifiedCustomer);
  yield takeLatest(CREATE_VERIFICATION, createVerification);
  yield takeLatest(UPDATE_VERIFICATION, updateVerification);
  yield takeLatest(GET_STAFF_BY_ID, getStaffById);
  yield takeLatest(DELETE_USER_BY_ID, deleteUser);
  yield takeLatest(UPDATE_USER_BY_ID, updateUser);
}
