import React from "react";
import { Card } from "react-bootstrap";
import { Doughnut, Pie } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import "../sale.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);
const Equity = ({ datas }) => {
  const data = {
    labels: ["Disbursements", "Payments", "Total Payments Collected"],
    datasets: [
      {
        data: [datas?.disbursementCount, datas?.paymentCount, datas?.loanCount],
        backgroundColor: ["#FF6384", "#FFCE56", "#36A2EB"],
      },
    ],
  };

  return (
    <Card className="mb-4 bx">
      <Card.Body>
        <Card.Title>Todays Collection</Card.Title>
        <h3>2,076K</h3>
        <Pie data={data} />
      </Card.Body>
    </Card>
  );
};
Equity.propTypes = {
  datas: PropTypes.object,
};
export default Equity;
