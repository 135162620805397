import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-js-pagination";
import PaymentModel from "./paymentModal";
import { getAllEmiByCustomerId } from "src/redux/actions/emiAction";
import { useSelector } from "react-redux";
import { getAllCustomers } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import LoanEmiDetailsData from "./loanEmiDetails";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const Dues = () => {
  const { emiData, paymentSuccess } = useSelector((state) => state?.emiReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [loanAccountNumber, setLoanAccountNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [showEmiDetails, setShowEmiDetails] = useState(true);
  const [getAllEmis, setGetAllEmis] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [printState, setPrintState] = useState(false);
  const [dueData, setDueData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const rowsPerPage = 10;
  const componentPDF = useRef();
  const SchemaName = sessionStorage.getItem("schemaName");

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setAllCustomerData(res?.data);
    });
  };

  useEffect(() => {
    const obj = {
      schemaName: SchemaName,
      customerId: customerData?.id,
    };
    dispatch(getAllEmiByCustomerId(obj));
  }, [visible, paymentSuccess]);

  useEffect(() => {
    const emisStatus = getAllEmis?.filter(
      (company) => company?.emiStatus === "pending"
    );
    setFilterData(emisStatus);
  }, [emiData, visible, paymentSuccess, getAllEmis]);

  const handlePayment = (data) => {
    setDueData(data);
    setVisible(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filterData?.slice(indexOfFirstRow, indexOfLastRow);

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleSearch = () => {
    const filteredCustomer = allCustomerData?.filter(
      (customer) =>
        customer.aadharNumber === String(loanAccountNumber) ||
        customer.phoneNumber.toLowerCase() === firstName.toLowerCase()
    );
    if (filteredCustomer?.length > 0) {
      setCustomerData(filteredCustomer[0]);
      const obj = {
        schemaName: SchemaName,
        customerId: filteredCustomer[0]?.id,
      };
      dispatch(getAllEmiByCustomerId(obj));
      setShowDetails(true);
      setShowTable(true);
    } else {
      setCustomerData([]);
    }
  };

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <div className="flex justify-between">
            <strong>EMI Record</strong>
            <div>
              <CButton className="add-btn m-0 w-auto" onClick={download_btn}>
                Download
              </CButton>
              {currentRows && (
                <CSVLink
                  data={currentRows === undefined ? " " : currentRows}
                  filename={"dueEMIRecord-table.csv"}
                >
                  <CButton className="add-btn w-auto m-0">CSV</CButton>
                </CSVLink>
              )}
              {showEmiDetails === false ? (
                <CButton
                  className="add-btn m-0 w-auto"
                  onClick={() => setShowEmiDetails(true)}
                >
                  Back
                </CButton>
              ) : ("")}
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow>
              <CCol xs={3}>
                <CFormInput
                  placeholder="Enter your Aadhar Number"
                  onChange={(e) => setLoanAccountNumber(e.target.value)}
                />
              </CCol>
              <CCol xs={3}>
                <CFormInput
                  placeholder="Enter your Mobile Number"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </CCol>

              <CCol xs={3}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
            </CRow>
            <div className="mb-4">
              <div className="search_bar">
                <div className="search-inner"></div>
                <div className="btn_add mt-0 mb-14"></div>
              </div>
            </div>
            <div>
              <div
                ref={printState ? componentPDF : null}
                style={{ padding: printState ? "20px" : "0px" }}
              >
                {printState && (
                  <div className="print-div">
                    <div>
                      {/* <Vakilkaro /> */}
                      <h3>Riyms</h3>
                    </div>
                    <div><p>{COMPANY_NAME}</p>
                    </div>
                  </div>
                )}

                <div>
                  {showDetails && (
                    <div className="customer_details">
                      <CRow>
                        <CCol xs={4}>
                          <span className="lable-name">Full Name : </span>
                          <span className="content-name">{`${customerData?.firstName}`}</span>
                        </CCol>
                        <CCol xs={4}>
                          <span className="lable-name">Aadhar Number : </span>
                          <span className="content-name">
                            {customerData?.aadharNumber}
                          </span>
                        </CCol>
                        <CCol xs={4}></CCol>
                      </CRow>
                      <CRow>
                        <CCol xs={4}>
                          <span className="lable-name"> Email : </span>
                          <span className="content-name">
                            {customerData?.email}
                          </span>
                        </CCol>
                        <CCol xs={4}>
                          <span className="lable-name">Pan Number : </span>
                          <span className="content-name">
                            {customerData?.PanNumber}
                          </span>
                        </CCol>
                        <CCol xs={4}></CCol>
                      </CRow>
                      <CRow>
                        <CCol xs={4}>
                          <span className="lable-name">
                            Loan Account Number :{" "}
                          </span>
                          <span className="content-name">
                            {customerData?.loanAccountNumber}
                          </span>
                        </CCol>
                      </CRow>
                    </div>
                  )}
                </div>
                {showTable && (
                  <>
                    {showEmiDetails === true ? (
                      <LoanEmiDetailsData
                        customerData={customerData?.id}
                        setShowEmiDetails={setShowEmiDetails}
                        setGetAllEmis={setGetAllEmis}
                        setLoanId={setLoanId}
                      />
                    ) : (
                      <CTable hover className="" responsive>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell scope="col" className="col-md-2">
                              <span>S. No.</span>
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col" className="col-md-3">
                              <span>Due Date</span>
                            </CTableHeaderCell>

                            <CTableHeaderCell scope="col" className="col-md-3">
                              <span> EMI Amount</span>
                            </CTableHeaderCell>
                            {!printState && (
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-4"
                              >
                                <span>Action</span>
                              </CTableHeaderCell>
                            )}
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {currentRows?.length > 0 &&
                            currentRows.map((company, index) => (
                              <CTableRow key={index}>
                                <CTableDataCell>{index + 1}</CTableDataCell>
                                <CTableDataCell>
                                  {company.dueDate}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {company.emiAmount}
                                </CTableDataCell>
                                {!printState && (
                                  <CTableDataCell>
                                    <CButton
                                      onClick={() => handlePayment(company)}
                                    >
                                      Pay
                                    </CButton>
                                  </CTableDataCell>
                                )}
                              </CTableRow>
                            ))}
                        </CTableBody>
                      </CTable>
                    )}{" "}
                  </>
                )}
              </div>

              {visible && (
                <PaymentModel
                  visible={visible}
                  setVisible={setVisible}
                  customerData={customerData}
                  emiData={emiData}
                  dueData={dueData}
                  loanId={loanId}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to{" "}
                  {Math?.min(indexOfLastRow, filterData?.length)} of{" "}
                  {filterData?.length} entries
                </div>
                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={filterData?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};
Dues.propTypes = {
  emiData: PropTypes.array,
};
export default Dues;
