import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOpeningBalance } from "src/redux/accounting/action";
const OpeningBalanceList = () => {
  const dispatch = useDispatch();
  const { openingBalance } = useSelector((state) => state?.acountingReducer)
  const schemaName = sessionStorage.getItem("schemaName");
  useEffect(() => {
    const obj = {
      schemaName: schemaName
    }
    dispatch(getOpeningBalance(obj));
  }, []);
  return (
    <div className="content">
      <CCard>
        <CCardBody>
          <div className="div_main">
            <div>Name Of Company :- </div>
            <div>Balence Sheet</div>
            <div>As On 31st March 20XX </div>
          </div>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell className="th" scope="col">
                  PARTICULARS
                </CTableHeaderCell>
                <CTableHeaderCell className="th wh" scope="col">
                  Note No.
                </CTableHeaderCell>
                <CTableHeaderCell className="th w" scope="col">
                  Figures at the end of current reporting period{" "}
                </CTableHeaderCell>
                <CTableHeaderCell className="th w" scope="col">
                  Figures at the end of previous reporting period
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow active>
                <CTableDataCell>
                  <div className="r re">
                    <div>ghghg</div>
                    <div>ghghg</div>
                  </div>
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow active>
                <CTableDataCell colSpan={4}>
                  <div className="r r re"> a).share capitals</div>
                </CTableDataCell>
              </CTableRow>
              {openingBalance?.capital?.results?.map((ele, i) => (
                <CTableRow key={i}>
                  <CTableDataCell className="r">{ele?.directorName}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>{ele?.capital}</CTableDataCell>
                  <CTableDataCell>{ele?.capital}</CTableDataCell>

                </CTableRow>
              ))}
              <CTableRow active>
                <CTableDataCell>
                  <div className="r">ghghg</div>
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
              </CTableRow>
              <CTableRow active>
                <CTableDataCell>
                  <div className="r f_w">1.hello this is heading </div>
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
              </CTableRow>
              <CTableRow active className="f_w">
                <CTableDataCell className="f_w">Total</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
                <CTableDataCell>XXXX</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  );
};
export default OpeningBalanceList;
