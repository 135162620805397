import {
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  GET_STAFF_BY_ID,
  CREATE_VERIFICATION,
  UPDATE_VERIFICATION,
  PAGE_REFRESH,
  CREATE_GROUPS,
  GET_ALL_GROUPS,
  GET_GROUP_DETAIL_BY_ID,
  GET_ALL_VERIFIED_CUSTOMER,
  CREATE_NEW_CUSTOMER,
} from "../action_Types";

export const getAllUser = () => {
  return {
    type: GET_ALL_USER,
  };
};

export const addUser = (data) => {
  return {
    type: ADD_USER_DATA,
    payload: data,
  };
};

export const getStaffDataById = (data) => {
  return {
    type: GET_STAFF_BY_ID,
    payload: data,
  };
};
export const deleteUser = (id) => {
  return {
    type: DELETE_USER_BY_ID,
    payload: id,
  };
};

export const updateUser = (id) => {
  return {
    type: UPDATE_USER_BY_ID,
    payload: id,
  };
};

export const verificationUser = (data) => {
  return {
    type: CREATE_VERIFICATION,
    payload: data,
  };
};

export const updateVerificationUser = (updatedData) => {
  return {
    type: UPDATE_VERIFICATION,
    payload: updatedData,
  };
};

export const pageRefresh = () => {
  return {
    type: PAGE_REFRESH,
  };
};

//============================= GROUP SECTION ===============================================

export const createGroup = (data) => {
  return {
    type: CREATE_GROUPS,
    payload: data,
  };
};

export const getAllGroup = (data) => {
  return {
    type: GET_ALL_GROUPS,
    payload: data,
  };
};

export const getGroupDetailById = (data) => {
  return {
    type: GET_GROUP_DETAIL_BY_ID,
    payload: data,
  };
};

export const verifiedData = (data) => {
  return {
    type: GET_ALL_VERIFIED_CUSTOMER,
    payload: data,
  };
};

export const CreatenewCustomerForm = (data) => {
  return {
    type: CREATE_NEW_CUSTOMER,
    payload: data,
  };
};

export const emptyVerifiedData = () => {
  return {
    type: "empty_verfied_Data",
  };
};
