export const GET_ALL_PLAN = "GET_ALL_PLAN";
export const GET_ALL_PLAN_SUCCESS = "GET_ALL_PLAN_SUCCESS";
export const ERROR = "ERROR";
export const SET_ERROR = "SET_ERROR";
export const ADD_PLAN_DATA = "ADD_PLAN_DATA";
export const ADD_PLAN_DATA_SUCCESS = "ADD_PLAN_DATA_SUCCESS";
export const DELETE_PLAN_BY_ID = "DELETE_PLAN_BY_ID";
export const DELETE_PLAN_BY_ID_SUCCESS = "DELETE_PLAN_BY_ID_SUCCESS";
export const UPDATE_PLAN_BY_ID = "UPDATE_PLAN_BY_ID";
export const UPDATE_PLAN_BY_ID_SUCCESS = "UPDATE_PLAN_BY_ID_SUCCESS";

// ================= Company related Actions ==============

export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ALL_COMPANY_SUCCESS = "GET_ALL_COMPANY_SUCCESS";
export const ADD_COMPANY_DATA = "ADD_COMPANY_DATA";
export const ADD_COMPANY_DATA_SUCCESS = "ADD_COMPANY_DATA_SUCCESS";
export const DELETE_COMPANY_BY_ID = "DELETE_COMPANY_BY_ID";
export const DELETE_LOAN_BY_ID = "DELETE_LOAN_BY_ID";
export const DELETE_LOAN_BY_ID_SUCCESS = "DELETE_LOAN_BY_ID_SUCCESS";
export const DELETE_COMPANY_BY_ID_SUCCESS = "DELETE_COMPANY_BY_ID_SUCCESS";
export const UPDATE_COMPANY_BY_ID = "UPDATE_COMPANY_BY_ID";
export const UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID = "UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID";
export const UPDATE_COMPANY_BY_ID_SUCCESS = "UPDATE_COMPANY_BY_ID_SUCCESS";
export const UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS ="UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS";
export const UPDATE_CUSTOMER_STATUS = "UPDATE_CUSTOMER_STATUS";
export const UPDATE_CUSTOMER_STATUS_SUCCESS = "UPDATE_CUSTOMER_STATUS_SUCCESS";

// ================================ common actions ===================================
export const SET_ROUTE_VALUE = "SET_ROUTE_VALUE";
export const STAFF_LOGIN = "STAFF_LOGIN";
export const STAFF_LOGIN_SUCCESS = "STAFF_LOGIN_SUCCESS";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMINISTRAOR_LOGIN = "ADMINISTRAOR_LOGIN";
export const ADMINISTRAOR_LOGIN_SUCCESS = "ADMINISTRAOR_LOGIN_SUCCESS";
export const SELECTEDROLE = "SELECTEDROLE";

// ==================================  staff Actions ==================================================
export const GET_ALL_STAFFS = "GET_ALL_STAFFS";
export const GET_ALL_STAFFS_SUCCESS = "GET_ALL_STAFFS_SUCCESS";
export const ADD_STAFFS_DATA = "ADD_STAFFS_DATA";
export const EDIT_STAFFS_DATA = "EDIT_STAFFS_DATA";
export const EDIT_STAFFS_DATA_SUCCESS = "EDIT_STAFFS_DATA_SUCCESS";
export const ADD_STAFFS_DATA_SUCCESS = "ADD_STAFFS_DATA_SUCCESS";
export const DELETE_STAFFS_BY_ID = "DELETE_STAFFS_BY_ID";
export const DELETE_STAFFS_BY_ID_SUCCESS = "DELETE_STAFFS_BY_ID_SUCCESS";
export const UPDATE_STAFFS_BY_ID = "UPDATE_STAFFS_BY_ID";
export const UPDATE_STAFFS_BY_ID_SUCCESS = "UPDATE_STAFFS_BY_ID_SUCCESS";
export const GET_STAFFS_BY_ID = "GET_STAFFS_BY_ID";
export const GET_STAFFS_BY_ID_SUCCESS = "GET_STAFFS_BY_ID_SUCCESS";
export const SET_SCHEMANAME = "SET_SCHEMANAME";
export const SET_PAYMENT_FALSE = "SET_PAYMENT_FALSE";
// =========================================== emi Actions ==========================================================
export const GET_ALL_EMI = "GET_ALL_EMI";
export const GET_ALL_EMI_SUCCESS = "GET_ALL_EMI_SUCCESS";
export const ADD_EMI_DATA = "ADD_EMI_DATA";
export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const ADD_EMI_DATA_SUCCESS = "ADD_EMI_DATA_SUCCESS";
export const DELETE_EMI_BY_ID = "DELETE_EMI_BY_ID";
export const DELETE_EMI_BY_ID_SUCCESS = "DELETE_EMI_BY_ID_SUCCESS";
export const UPDATE_EMI_BY_ID = "UPDATE_EMI_BY_ID";
export const UPDATE_EMI_BY_ID_SUCCESS = "UPDATE_EMI_BY_ID_SUCCESS";

// =========================================== user Actions ==========================================================
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_USER_DATA_SUCCESS = "ADD_USER_DATA_SUCCESS";
export const GET_STAFF_BY_ID = "GET_STAFF_BY_ID";
export const GET_STAFF_BY_ID_SUCCESS = "GET_STAFF_BY_ID_SUCCESS";

export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_CUSTOMER_BY_ID = "UPDATE_CUSTOMER_BY_ID";
export const UPDATE_CUSTOMER_BY_ID_SUCCESS = "UPDATE_CUSTOMER_BY_ID_SUCCESS";
export const CREATE_NEW_CUSTOMER = "CREATE_NEW_CUSTOMER";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";

// =========================================== interest ==========================================================
export const GET_ALL_INTERESTS = "GET_ALL_INTERESTS";
export const GET_ALL_INTERESTS_SUCCESS = "GET_ALL_INTERESTS_SUCCESS";
export const ADD_INTERESTS_DATA = "ADD_INTERESTS_DATA";
export const EDIT_INTERESTS_DATA = "EDIT_INTERESTS_DATA";
export const EDIT_INTERESTS_DATA_SUCCESS = "EDIT_INTERESTS_DATA_SUCCESS";
export const ADD_INTERESTS_DATA_SUCCESS = "ADD_INTERESTS_DATA_SUCCESS";
export const DELETE_INTERESTS_BY_ID = "DELETE_INTERESTS_BY_ID";
export const DELETE_INTERESTS_BY_ID_SUCCESS = "DELETE_INTERESTS_BY_ID_SUCCESS";
export const UPDATE_INTERESTS_BY_ID = "UPDATE_INTERESTS_BY_ID";
export const UPDATE_INTERESTS_BY_ID_SUCCESS = "UPDATE_INTERESTS_BY_ID_SUCCESS";

// =============================== EMI SECTION ========================================================
export const CREATE_EMI = "CREATE_EMI";
export const CREATE_EMI_SUCCESS = "CREATE_EMI_SUCCESS";
export const SHOW_LOAN_SUMMARY = "SHOW_LOAN_SUMMARY";
export const SHOW_LOAN_SUMMARY_SUCCESS = "SHOW_LOAN_SUMMARY_SUCCESS";
export const GET_ALL_EMI_BY_CUSTOMER_ID = "GET_ALL_EMI_BY_CUSTOMER_ID";
export const GET_ALL_EMI_BY_CUSTOMER_ID_SUCCESS =
  "GET_ALL_EMI_BY_CUSTOMER_ID_SUCCESS";
export const UPLOAD_SANCTION_LETTER = "UPLOAD_SANCTION_LETTER";
export const UPLOAD_SANCTION_LETTER_SUCCESS = "UPLOAD_SANCTION_LETTER_SUCCESS";
export const REFRESH_PAGE = "REFRESH_PAGE";
export const GET_ALL_EMI_BY_EMI_STATUS = "GET_ALL_EMI_BY_EMI_STATUS";
export const GET_ALL_EMI_BY_EMI_STATUS_SUCCESS =
  "GET_ALL_EMI_BY_EMI_STATUS_SUCCESS";
// ==================================== Disbursement Section ====================================================
export const CREATE_DISBURSEMENT = "CREATE_DISBURSEMENT";
export const CREATE_DISBURSEMENT_SUCCESS = "CREATE_DISBURSEMENT_SUCCESS";
export const UPDATE_DISBURSEMENT = "UPDATE_DISBURSEMENT";
export const UPDATE_DISBURSEMENT_SUCCESS = "UPDATE_DISBURSEMENT_SUCCESS";
export const CREATE_DISBURSEMENT_PAYMENT = "CREATE_DISBURSEMENT_PAYMENT";
export const CREATE_DISBURSEMENT_PAYMENT_SUCCESS =
  "CREATE_DISBURSEMENT_PAYMENT_SUCCESS";
export const DELETE_DISBURSEMENT_BY_ID = "DELETE_DISBURSEMENT_BY_ID";
export const DELETE_DISBURSEMENT_BY_ID_SUCCESS =
  "DELETE_DISBURSEMENT_BY_ID_SUCCESS";

//  ===================================== branch Section ===========================================================
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_ALL_BRANCHES_SUCCESS = "GET_ALL_BRANCHES_SUCCESS";
export const CREATE_BRANCHES = "CREATE_BRANCHES";
export const CREATE_BRANCHES_SUCCESS = "CREATE_BRANCHES_SUCCESS";

// ================================ Directors section =========================================================

export const GET_ALL_DIRECTORS = "GET_ALL_DIRECTORS";
export const GET_ALL_DIRECTORS_SUCCESS = "GET_ALL_DIRECTORS_SUCCESS";
export const CREATE_DIRECTORS = "CREATE_DIRECTORS";
export const CREATE_DIRECTORS_SUCCESS = "CREATE_DIRECTORS_SUCCESS";

// =============================== Capital Section ===================================================

export const GET_ALL_CAPITALS = "GET_ALL_CAPITALS";
export const GET_ALL_CAPITALS_SUCCESS = "GET_ALL_CAPITALS_SUCCESS";
export const CREATE_CAPITALS = "CREATE_CAPITALS";
export const CREATE_CAPITALS_SUCCESS = "CREATE_CAPITALS_SUCCESS";

// =======================================  Verification Section ==================================================

export const CREATE_VERIFICATION = "CREATE_VERIFICATION";
export const CREATE_VERIFICATION_SUCCESS = "CREATE_VERIFICATION_SUCCESS";
export const CREATE_AADHAR_VERIFICATION = "CREATE_AADHAR_VERIFICATION";
export const CREATE_AADHAR_VERIFICATION_SUCCESS = "CREATE_AADHAR_VERIFICATION_SUCCESS";
export const AADHAR_VERIFICATION_OTP = "AADHAR_VERIFICATION_OTP";
export const AADHAR_VERIFICATION_OTP_SUCCESS = "AADHAR_VERIFICATION_OTP_SUCCESS";
export const UPDATE_VERIFICATION = "UPDATE_VERIFICATION";
export const UPDATE_VERIFICATION_SUCCESS = "UPDATE_VERIFICATION_SUCCESS";
export const PAGE_REFRESH = "PAGE_REFRESH";
export const GET_ALL_VERIFIED_CUSTOMER = "GET_ALL_VERIFIED_CUSTOMER";
export const GET_ALL_VERIFIED_CUSTOMER_SUCCESS = "GET_ALL_VERIFIED_CUSTOMER_SUCCESS";
// ===========================  Group Section  ====================================

export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const CREATE_GROUPS = "CREATE_GROUPS";
export const CREATE_GROUPS_SUCCESS = "CREATE_GROUPS_SUCCESS";
export const GET_GROUP_DETAIL_BY_ID = "GET_GROUP_DETAIL_BY_ID";
export const GET_GROUP_DETAIL_BY_ID_SUCCESS = "GET_GROUP_DETAIL_BY_ID_SUCCESS";

// ==============================m  loan section ===============================================================
export const CREATE_LOAN = "CREATE_LOAN";
export const CREATE_LOAN_SUCCESS = "CREATE_LOAN_SUCCESS";
export const UPDATE_LOAN = "UPDATE_LOAN";
export const UPDATE_LOAN_SUCCESS = "UPDATE_LOAN_SUCCESS";
export const REFRESH_INTERESTLIST = "REFRESH_INTERESTLIST";
export const GET_LOAN_BY_ID = "GET_LOAN_BY_ID";
export const GET_LOAN_BY_ID_SUCCESS = "GET_LOAN_BY_ID_SUCCESS";
export const GET_ALL_LOAN = "GET_ALL_LOAN";
export const GET_ALL_LOAN_SUCCESS = "GET_ALL_LOAN_SUCCESS";
export const EMPTY_STORE = "EMPTY_STORE";
export const SET_DATA = "SET_DATA";


// ========================= Accounting Section  ===============================================
export const GET_ALL_JOURNAL_ENTRIES = "GET_ALL_JOURNAL_ENTRIES" 
export const GET_ALL_JOURNAL_ENTRIES_SUCCESS = "GET_ALL_JOURNAL_ENTRIES_SUCCESS" 
export const ADD_JOURNAL_ENTRIES = "ADD_JOURNAL_ENTRIES" 
export const ADD_JOURNAL_ENTRIES_SUCCESS = "ADD_JOURNAL_ENTRIES_SUCCESS" 
export const GET_ALL_CONTRA_ENTRIES = "GET_ALL_CONTRA_ENTRIES" 
export const GET_ALL_CONTRA_ENTRIES_SUCCESS = "GET_ALL_CONTRA_ENTRIES_SUCCESS" 
export const ADD_CONTRA_ENTRIES = "ADD_CONTRA_ENTRIES" 
export const ADD_CONTRA_ENTRIES_SUCCESS = "ADD_CONTRA_ENTRIES_SUCCESS" 
export const ADD_ACCOUNT = "ADD_ACCOUNT" 
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS" 
export const GET_ALL_ACCOUNT = "GET_ALL_ACCOUNT" 
export const GET_ALL_ACCOUNT_SUCCESS = "GET_ALL_ACCOUNT_SUCCESS" 
export const GET_ALL_LEDGER = "GET_ALL_LEDGER" 
export const GET_ALL_LEDGER_SUCCESS = "GET_ALL_LEDGER_SUCCESS" 
export const GET_ALL_OPENING= "GET_ALL_OPENING" 
export const GET_ALL_OPENING_SUCCESS = "GET_ALL_OPENING_SUCCESS" 
export const GET_ALL_TRADING = "GET_ALL_TRADING" 
export const GET_ALL_TRADING_SUCCESS = "GET_ALL_TRADING_SUCCESS" 
export const RESET_VERIFICATION = "RESET_VERIFICATION" 

export const NEW_ERROR = "NEW_ERROR"
export const NEW_VERY_ERROR = "NEW_VERY_ERROR"
