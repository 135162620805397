import React, { useState } from 'react'
import { CModal, CModalBody, CCol, CContainer, CForm, CModalHeader, CModalTitle, CButton, CFormSelect } from "@coreui/react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { updateCustomer } from 'src/api/allApis';
import { errorAlert, successAlert } from 'src/utils/Swal';

const AddCustomerGroup = ({ setVisibleCustomerGroup, visibleCustomerGroup, activeCustomerid, setActiveCustomerid, AllGroupsLoan, getAllCompanyData }) => {
  const { handleSubmit, reset } = useForm();
  const [selectedGroupId, setSelectedGroupId] = useState();
  const onSubmit = (data) => {
    const AddCustomerData = new FormData();
    AddCustomerData.append("groupid", selectedGroupId)
    const payload = { data: AddCustomerData, id: activeCustomerid, params: { isUpdateGroup: 1 } };
    updateCustomer(payload).then((response) => {
      if (response === undefined) {
        errorAlert("failed", "Some-thing Went Wrong ");
        return;
      } else if (response.status === "success") {
        successAlert("Success", "Customer Successfully Added For Loan");
        if (activeCustomerid) getAllCompanyData();
        setVisibleCustomerGroup(false);
        setActiveCustomerid(0);
        reset();
      }
    });
  }

  return (
    <CModal
      className="fullPageModal"
      backdrop="static"
      visible={visibleCustomerGroup}
      onClose={() => setVisibleCustomerGroup(false)}
      aria-labelledby="StaticBackdropExampleLabel"
      size="md"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">
          Update Customer Group Loan
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          {" "}
          <CForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <CCol xs={6}>
              <div className="mb-4">
                <label htmlFor="aaddhar_token_limit" className="mb-1">
                  Group
                </label>
                <CFormSelect
                  id="branchName"
                  name="branchName"
                  onChange={(e) => setSelectedGroupId(e.target.value)}
                  value={selectedGroupId}
                  required
                >
                  <option value={""}
                  >Select Group</option>

                  {AllGroupsLoan?.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group?.groupName}
                    </option>
                  ))}
                </CFormSelect>
              </div>
            </CCol>
            <CButton type="submit" size="sm" color="primary">
              Add
            </CButton>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
}
AddCustomerGroup.propTypes = {
  visibleCustomerGroup: PropTypes.bool.isRequired,
  setActiveCustomerid: PropTypes.func.isRequired,
  activeCustomerid: PropTypes.number,
  setVisibleCustomerGroup: PropTypes.func.isRequired,
  AllGroupsLoan: PropTypes.array,
  getAllCompanyData: PropTypes.func
};
export default AddCustomerGroup