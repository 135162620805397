import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "src/redux/actions/companyAction";
import ReactPaginate from "react-js-pagination";
import AddForm from "./detailForm";
import { getCollections } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { COMPANY_NAME } from "src/services/const";

const PaidCollection = () => {
  const dispatch = useDispatch();

  const [filteredItem, setFilteredItem] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [printState, setPrintState] = useState(false);
  const [employeeId, setEmployeeId] = useState(
    sessionStorage.getItem("emplyeeId")
  );
  const employeeRole = sessionStorage.getItem("role");
  const componentPDF = useRef();

  //const rowsPerPage = 10;
  const [collections, setCollections] = useState([]);

  const fetchCollection = async () => {
    const data = await getCollections({
      schemaName: sessionStorage.getItem("schemaName"),
    });
    setCollections(data?.data || []);
    setCompanyModel(true);
  };

  useEffect(() => {
    dispatch(getAllCompany());
    fetchCollection();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  useEffect(() => {
    if (startDate && endDate) {
      const filteredData = collections?.filter((element) => {
        const dueDate = new Date(element?.dueDate);
        const start = new Date(startDate);
        const end = new Date(endDate);
        return (
          dueDate >= start && dueDate <= end && element?.emiStatus === "paid"
        );
      });
      setFilteredItem(filteredData);
    } else {
      if (employeeRole === "agent") {
        const filteredData = collections?.filter(
          (element) =>
            String(element?.customer?.employeeId) === String(employeeId) &&
            element?.emiStatus === "paid"
        );
        setFilteredItem(filteredData);
      } else {
        const filteredData = collections?.filter(
          (element) => element?.emiStatus === "paid"
        );
        setFilteredItem(filteredData);
      }
    }
  }, [companyModel, employeeId, startDate, endDate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredItem?.slice(indexOfFirstRow, indexOfLastRow);

  const handleShowAddForm = () => {
    setShowAddButton(false);
    setShowAddForm(true);
    setShowSelectAndSearch(false);
  };

  const handleViewForm = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const download_btn = () => {
    setPrintState(true);
  }
  const generatePDF = useReactToPrint({

    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  return (
    <>
      <CRow className="content">
        <CCol xs={12}>
          <CCard>
            <CCardHeader>
              <strong>Paid Collections</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12}>
                  <CCard>
                    <CCardBody>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ margin: "0px 0px -22px 0px" }}>
                          <CFormSelect
                            onChange={(e) => setRowsPerPage(e.target.value)}
                            size="sm"
                            className="mb-3"
                            style={{ cursor: "pointer" }}
                            aria-label="Small select example"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </CFormSelect>
                        </div>

                        <div style={{ display: "flex" }}>
                          {showSelectAndSearch && (
                            <>
                              <CFormInput
                                type="date"
                                size="sm"
                                placeholder="Start Date"
                                aria-label="sm input example"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={startDate}
                              />
                              <CFormInput
                                type="date"
                                size="sm"
                                placeholder="End Date"
                                aria-label="sm input example"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={endDate}
                              />
                            </>
                          )}
                        </div>

                        <div style={{ display: "flex" }}>
                          {showSelectAndSearch && (
                            <>
                              <CFormInput
                                type="text"
                                size="sm"
                                placeholder="Select Agent/Group"
                                aria-label="sm input example"
                                onChange={handleSearchChange}
                                value={searchInput}
                              />

                              <span
                                style={{
                                  border: " 1px solid #d1c5c5",
                                  borderRadius: "4px",
                                  margin: "0px 5px 0px -1px",
                                  padding: "1px 8px 0px 8px",
                                  background: "#ebedef",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </span>
                            </>
                          )}
                        </div>

                        {/* )} */}
                        <div style={{ display: "flex" }}>
                          {showSelectAndSearch && (
                            <>
                              <CFormInput
                                type="text"
                                size="sm"
                                placeholder="Search"
                                aria-label="sm input example"
                                onChange={handleSearchChange}
                                value={searchInput}
                              />

                              <span
                                style={{
                                  border: " 1px solid #d1c5c5",
                                  borderRadius: "4px",
                                  margin: "0px 5px 0px -1px",
                                  padding: "1px 8px 0px 8px",
                                  background: "#ebedef",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </CCardBody>
                    <CCardBody>
                      {/* code search_bar */}
                      <div className="mb-4">
                        <div className="search_bar">
                          {/* div 1 started */}
                          <div className="search-inner">
                            {/* {showSelectAndSearch && ( */}
                            {/* <div>
                              <CFormSelect
                                size="sm"
                                className="mb-3"
                                style={{ cursor: "pointer" }}
                                aria-label="Small select example"
                                // onChange={(e) => setRowsPerPage(e.target.value)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </CFormSelect>
                            </div> */}
                            {/* )} */}
                            {/* search div */}
                            {/* <div style={{ display: "flex" }}> */}
                            {/* {showSelectAndSearch && ( */}
                            {/* <>
                      <CFormInput
                        type="text"
                        size="sm"
                        placeholder="Search"
                        aria-label="sm input example"
                        style={{ margin: "0px 0px 16px 0px" }}
                        // onChange={handleSearchChange}
                        // value={searchInput}
                      />
                      <span
                        style={{
                          border: " 1px solid #d1c5c5",
                          borderRadius: "4px",
                          margin: "0px 5px 16px -1px",
                          padding: "1px 8px 0px 8px",
                          background: "#ebedef",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </span>
                    </> */}
                            {/* )} */}
                            {/* </div> */}
                          </div>
                          {/* div 1 ended */}

                          {/* both btn div */}
                          <div className="btn_add mt-0 mb-14">
                            {/* <CButton
                              className="add-btn w-auto plr-30 mr-10 "
                              // onClick={handleShowAddForm}
                            >
                              Add
                            </CButton> */}

                            <CButton
                              className="add-btn m-0 w-auto"
                              onClick={download_btn}
                            >
                              Download
                            </CButton>
                          </div>
                        </div>
                      </div>
                      {/* search_bar end */}
                      <div
                        className={`table-container ${showAccordion ? "hidden" : ""
                          } ${showAddForm ? "hidden" : ""}`}
                      >
                        <div ref={printState ? componentPDF : null} style={{ padding: printState ? '20px' : "0px" }}>

                          {printState && (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '20px', fontWeight: 700 }}>
                              <div>
                                {/* <Vakilkaro /> */}
                                <h3>Riyms</h3>
                              </div>
                              <div><p>{COMPANY_NAME}</p></div>
                            </div>
                          )}
                          <CTable color="dark" hover className="" responsive>
                            <CTableHead>
                              <CTableRow>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  S. No.
                                </CTableHeaderCell>

                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  Member Name
                                </CTableHeaderCell>

                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  Mobile
                                </CTableHeaderCell>

                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  {/* Bank */}
                                  Group Name
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  Pay Date
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  Duration
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  Amount
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  Status
                                </CTableHeaderCell>
                                {!printState && (<CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  Action
                                </CTableHeaderCell>)}
                              </CTableRow>
                            </CTableHead>
                            <CTableBody>
                              {currentRows
                                ?.filter((item) =>
                                  item?.customer?.firstName
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase())
                                )
                                ?.map((company, index) => (
                                  <CTableRow key={index}>
                                    <CTableDataCell>{index + 1}</CTableDataCell>
                                    <CTableDataCell>
                                      {company?.customer?.firstName}{" "}
                                      {company?.customer?.middleName ==
                                        "undefined"
                                        ? ""
                                        : company?.customer?.middleName}{" "}
                                      {company?.customer?.lastName}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.customer?.phoneNumber}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* {company?.customer?.bankName} */}
                                      Monu
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.dueDate}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.loadDurationInMonths}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.loadAmount}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <span className="pending_status">
                                        <span
                                          className={
                                            company?.emiStatus == "pending"
                                              ? "pending"
                                              : company?.emiStatus == "paid"
                                                ? "paid"
                                                : "reject"
                                          }
                                        // onClick={() => alert("Hello")}
                                        >
                                          {company?.emiStatus?.toUpperCase()}
                                        </span>
                                      </span>
                                    </CTableDataCell>
                                    {!printState && (<CTableDataCell className="col-md-2">
                                      <Tooltip text="View">
                                        <FontAwesomeIcon
                                          role="button"
                                          icon={faEye}
                                          size="1x"
                                          className="viewIcon"
                                          onClick={() => handleViewForm()}
                                        />
                                      </Tooltip>

                                      <Tooltip text="Update">
                                        <FontAwesomeIcon
                                          role="button"
                                          icon={faPenToSquare}
                                          size="1x"
                                          onClick={() => handleShowAddForm()}
                                          className="px-3 updateIcon"
                                        />
                                      </Tooltip>
                                    </CTableDataCell>)}
                                  </CTableRow>
                                ))}
                            </CTableBody>
                          </CTable>
                        </div>

                        <div className="pagin-div">
                          <div>
                            Showing {indexOfFirstRow + 1} to{" "}
                            {Math?.min(indexOfLastRow, filteredItem?.length)} of{" "}
                            {filteredItem?.length} entries
                          </div>
                          <div className="pagination-container">
                            <ReactPaginate
                              activePage={currentPage}
                              itemsCountPerPage={rowsPerPage}
                              totalItemsCount={filteredItem?.length}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                              prevPageText="Previous"
                              nextPageText="Next"
                              firstPageText="First"
                              lastPageText="Last"
                              innerClass="pagination"
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </div>

                      {showAddForm && (
                        <AddForm
                          selectedCompany={selectedCompany}
                          setShowAddForm={setShowAddForm}
                          setShowAddButton={setShowAddButton}
                          setShowSelectAndSearch={setShowSelectAndSearch}
                        />
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""}`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};

export default PaidCollection;
