import React, { useRef, useState } from "react";
import { CModal, CButton, CModalBody, CCol, CContainer, CForm, CModalHeader, CModalTitle, CRow, CFormInput, CFormLabel } from "@coreui/react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addCompany, getAllCompany, updateCompany } from "src/redux/actions/companyAction";
import { successAlert } from "src/utils/Swal";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
registerLocale("en", en);

const initialValues = {
  company_logo: "",
  gstRegistrationImageUrl: ""
};

const CompanyAddAndUpdateForm = ({ visible, setVisible, loanData, updateForm }) => {

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const companyLogoRef = useRef(null);
  const { register, handleSubmit, reset } = useForm();
  const [images, setImages] = useState(initialValues)

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setImages({ ...images, [name]: value });
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("userName", data.userName);
    formData.append("password", data.password);
    formData.append("companyName", data.companyName);
    formData.append("companyAddress", data.companyAddress);
    formData.append("city", data.city);
    formData.append("zipCode", data.zipCode);
    formData.append("state", data.state);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("companyOwnerName", data.companyOwnerName);
    formData.append("companyOwnerPhoneNumber", data.companyOwnerPhoneNumber);
    formData.append("companyOwnerAddress", data.companyOwnerAddress);
    formData.append("gstNumber", data.gstNumber);
    formData.append("licenses", data.licenses);
    formData.append("taxIdentificationNumber", data.taxIdentificationNumber);
    formData.append("numberOfEmployee", data.numberOfEmployee);
    formData.append("startDate", data.startDate);
    formData.append("dueDate", data.dueDate);
    if (fileInputRef.current?.files[0]) {
      formData.append("gstRegistrationImageUrl", fileInputRef.current.files[0]);
    }
    if (companyLogoRef.current?.files[0]) {
      formData.append("company_logo", companyLogoRef.current.files[0]);
    }
    if (updateForm) {
      formData.append("schemaName", loanData?.schemaName);
    }
    if (updateForm) {
      const updatedData = {
        id: loanData?.id,
        data: formData,
      };
      dispatch(updateCompany(updatedData));
      successAlert("Success", "Company Successfully Updated");
    } else {
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      // if (companyLogoRef.current) {
      //   companyLogoRef.current.value = "";
      // }
      dispatch(addCompany(formData));
      dispatch(getAllCompany());
      successAlert("Success", "New Company Successfully Added");
      dispatch(getAllCompany());
    }
    dispatch(getAllCompany());
    reset();
    setVisible(false);
  };

  return (
    <CModal
      className="fullPageModal"
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="StaticBackdropExampleLabel"
      size="lg"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">
          {updateForm ? "Edit Company" : "Add Company"}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          {" "}
          <div className="company_header">Basic Details :</div>
          <CForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="userName" className="mb-1">
                    Username
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="Username"
                    name="userName"
                    required
                    defaultValue={loanData ? loanData.userName : ""}
                    {...register("userName", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="password" className="mb-1">
                    Password
                  </label>
                  <CFormInput
                    type="password"
                    placeholder="Password"
                    name="password"
                    required
                    defaultValue={loanData ? loanData.password : ""}
                    {...register("password", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="companyName" className="mb-1">
                    Company Name
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="Company Name"
                    name="companyName"
                    required
                    defaultValue={loanData ? loanData.companyName : ""}
                    {...register("companyName", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-1">
                    Company Mail Id
                  </label>
                  <CFormInput
                    type="email"
                    placeholder="Company Mail Id"
                    name="email"
                    required
                    defaultValue={loanData ? loanData.email : ""}
                    {...register("email", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <div className="company_header">Address Details :</div>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="companyAddress" className="mb-1">
                    Company Address
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="Company Address"
                    name="companyAddress"
                    required
                    defaultValue={loanData ? loanData.companyAddress : ""}
                    {...register("companyAddress", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="city" className="mb-1">
                    City
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="City"
                    name="city"
                    required
                    defaultValue={loanData ? loanData.city : ""}
                    {...register("city", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="state" className="mb-1">
                    State
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="State"
                    name="state"
                    required
                    defaultValue={loanData ? loanData.state : ""}
                    {...register("state", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="zipCode" className="mb-1">
                    Zip Code
                  </label>
                  <CFormInput
                    type="number"
                    placeholder="Zip Code"
                    name="zipCode"
                    required
                    defaultValue={loanData ? loanData.zipCode : ""}
                    {...register("zipCode", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="mb-1">
                    Company Mobile Number
                  </label>
                  <CFormInput
                    type="number"
                    placeholder="Company Mobile Number"
                    name="phoneNumber"
                    required
                    defaultValue={loanData ? loanData.phoneNumber : ""}
                    {...register("phoneNumber", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <div className="company_header">Owner Details :</div>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="companyOwnerName" className="mb-1">
                    Owner Name
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="Owner Name"
                    name="companyOwnerName"
                    required
                    defaultValue={loanData ? loanData.companyOwnerName : ""}
                    {...register("companyOwnerName", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="companyOwnerAddress" className="mb-1">
                    Owner Address
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="Owner Address"
                    name="companyOwnerAddress"
                    required
                    defaultValue={loanData ? loanData.companyOwnerAddress : ""}
                    {...register("companyOwnerAddress", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="companyOwnerPhoneNumber" className="mb-1">
                    Owner Phone Number
                  </label>
                  <CFormInput
                    type="number"
                    placeholder="Owner Phone Number"
                    name="companyOwnerPhoneNumber"
                    required
                    defaultValue={loanData ? loanData.companyOwnerPhoneNumber : ""}
                    {...register("companyOwnerPhoneNumber", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>
            <div className="company_header">Verification Details :</div>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="gstNumber" className="mb-1">
                    GST Number
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="GST Number"
                    name="gstNumber"
                    required
                    defaultValue={loanData ? loanData.gstNumber : ""}
                    {...register("gstNumber", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="taxIdentificationNumber" className="mb-1">
                    CIN
                  </label>
                  <CFormInput
                    type="text"
                    placeholder="CIN"
                    name="taxIdentificationNumber"
                    required
                    defaultValue={
                      loanData ? loanData.taxIdentificationNumber : ""
                    }
                    {...register("taxIdentificationNumber", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="numberOfEmployee" className="mb-1">
                    Number Of Employee
                  </label>
                  <CFormInput
                    type="number"
                    placeholder="Number Of Employee"
                    name="numberOfEmployee"
                    required
                    defaultValue={loanData ? loanData.numberOfEmployee : ""}
                    {...register("numberOfEmployee", { required: true })}
                  />
                </div>
              </CCol>

              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="gstRegistrationImageUrl" className="mb-1">
                    GST Registration Image
                  </label>
                  <CFormInput
                    type="file"
                    id="gstRegistrationImageUrl"
                    ref={fileInputRef}
                    onChange={onChangeValue}
                  />
                </div>
              </CCol>
            </CRow>

            <div className="company_header">Other Details :</div>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <CFormLabel htmlFor="company_logo">
                    Company Logo <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    id="company_logo"
                    ref={companyLogoRef}
                    onChange={onChangeValue}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <CFormLabel htmlFor="Start_date">
                    Start Date <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="date"
                    id="start_date"
                    placeholder="Start Date"
                    name="startDate"
                    // value={startDate}
                    defaultValue={loanData ? loanData.startDate : ""}
                    {...register("startDate", { required: true })}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <CFormLabel htmlFor="due_date">
                    Due Date <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="date"
                    id="due_date"
                    placeholder="Due Date"
                    name="dueDate"
                    defaultValue={loanData ? loanData.dueDate : ""}
                    {...register("dueDate", { required: true })}
                  />
                </div>
              </CCol>
            </CRow>

            <CButton type="submit" size="sm" color="primary">
              {updateForm ? "Update Company" : "Add Company"}
            </CButton>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

CompanyAddAndUpdateForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  loanData: PropTypes.object,
  updateForm: PropTypes.bool,
};

export default CompanyAddAndUpdateForm;

