import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStaff, edtiStaff, setUpdateFalse, } from "src/redux/actions/staffAction";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getAllEmplyeesData } from "src/api/allApis";
import { successAlert } from "src/utils/Swal";

export const EmployeePorvider = () => {

  const navigate = useNavigate();
  const { staffReducer } = useSelector((state) => state);
  const { newUpdate, updateData } = useSelector((state) => state?.staffReducer);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const DACRef = useRef();
  const passPortPhotoRef = useRef();
  const panImageRef = useRef();
  const aadharImageRef = useRef();
  const signatureRef = useRef();
  const location = useLocation();

  const staffDetails = location.state ? location?.state?.staffDetails : {};
  delete staffDetails["signatureImageUrl"];
  delete staffDetails["aadharImageUrl"];
  delete staffDetails["panImageUrl"];
  delete staffDetails["passportSizePhoto"];
  delete staffDetails["degreeAndCertificateImageUrl"];
  const staffCompanyId = sessionStorage.getItem("companyId");

  const [employeeData, setEmployeeData] = useState(staffDetails);
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Password is required"),
    aadharNumber: Yup.string()
      .matches(/^[0-9]{12}$/, "Aadhar number must be 12 digits")
      .required("Aadhar number is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    emergencyContactNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Emergency contact number must be 10 digits")
      .required("Emergency contact number is required"),
  });

  const onChangeValue = (e) => {
    setError("");
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBranchId(selectedId);
  };

  const handleSubmit = async (event, isEdit) => {
    event.preventDefault();
    try {
      const AddStafffData = new FormData();
      AddStafffData.append("schemaName", staffReducer?.SCHEMANAME);
      staffDetails?.id && AddStafffData.append("id", staffDetails?.id);
      AddStafffData.append("userName", employeeData.userName);
      AddStafffData.append("password", employeeData.password);
      AddStafffData.append("email", employeeData.email);
      AddStafffData.append("shopName", employeeData.shopName);
      AddStafffData.append("companyId", staffCompanyId);
      AddStafffData.append("dob", employeeData.dob);
      AddStafffData.append("phoneNumber", employeeData.phoneNumber);
      AddStafffData.append("address", employeeData.address);
      AddStafffData.append("branchId", selectedBranchId);
      AddStafffData.append("emergencyContactInformation", employeeData.emergencyContactInformation);
      AddStafffData.append("emergencyContactNumber", employeeData.emergencyContactNumber);
      AddStafffData.append("jobTitle", employeeData.jobTitle);
      AddStafffData.append("dateOfHire", employeeData.dateOfHire);
      AddStafffData.append("aadharImageUrl", aadharImageRef.current.files[0]);
      AddStafffData.append("passportSizePhoto", passPortPhotoRef.current.files[0]);
      AddStafffData.append("aadharNumber", employeeData.aadharNumber);
      AddStafffData.append("firstName", employeeData.firstName);
      AddStafffData.append("empbranchCode", employeeData.empbranchCode);
      AddStafffData.append("employeeRole", employeeData.employeeRole);
      AddStafffData.append("employeeVerification", employeeData.employeeVerification);
      AddStafffData.append("status", employeeData.empStatus);

      await validationSchema.validate({
        password: employeeData.password,
        aadharNumber: employeeData.aadharNumber,
        phoneNumber: employeeData.phoneNumber,
        emergencyContactNumber: employeeData.emergencyContactNumber,
      });

      if (isEdit) {
        dispatch(edtiStaff(AddStafffData));
        const res = await edtiStaff(AddStafffData);
        setStatus(true);
        return;
      }
      dispatch(addStaff(AddStafffData));

      getAllEmplyesData();
      setStatus(true);
    } catch (err) {
      if (err.name === "ValidationError") {
        return setError(err.errors);
      } else {
        console.log(err);
      }
    }
  };
  const SchemaName = sessionStorage.getItem("schemaName");

  useEffect(() => {
    if (newUpdate === true) {
      dispatch(setUpdateFalse());
      successAlert("Success", "New Staff Created");
      navigate("/staff");
    }
    if (updateData === true) {
      dispatch(setUpdateFalse());
      successAlert("Success", "SuccessFully Updated");
      navigate("/staff");
    }
  }, [newUpdate, updateData]);
  const getAllEmplyesData = () => {
    const payload = { schemaName: SchemaName };

    getAllEmplyeesData(payload).then((res) => { });
  };

  return {
    employeeData,
    onChangeValue,
    handleSubmit,
    DACRef,
    handleSelectChange,
    passPortPhotoRef,
    panImageRef,
    error,
    selectedBranchId,
    aadharImageRef,
    signatureRef,
  };
};
