import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import ReactPaginate from "react-js-pagination";
import StaffDetailsById from "./staffDetailById";

import { getAllEmplyeesData } from "src/api/allApis";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { useSelector } from "react-redux";
import { setUpdateFalse } from "src/redux/actions/staffAction";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const UserOperations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newUpdate } = useSelector((state) => state?.staffReducer);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [printState, setPrintState] = useState(false);

  const SchemaName = sessionStorage.getItem("schemaName");
  const role = sessionStorage.getItem("role");
  const componentPDF = useRef();

  useEffect(() => {
    getAllEmplyesData();
  }, []);
  useEffect(() => {
    if (newUpdate === true) {
      getAllEmplyesData();
      dispatch(setUpdateFalse());
    }
  }, [newUpdate]);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const getAllEmplyesData = () => {
    const payload = { schemaName: SchemaName };

    getAllEmplyeesData(payload).then((res) => {
      setStaffData(res?.data);
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = staffData?.slice(indexOfFirstRow, indexOfLastRow);

  const handleEditLoan = (staffDetails) => {
    navigate("/staff/edit?isEdit=1", { state: { staffDetails } });
  };

  const handleViedDetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };
  return (
    <CRow className="content">
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Manage Staff</strong>
          </CCardHeader>
          <CRow>
            <CCol xs={12}>
              <CCardBody>
                <CCard>
                  <div className="search_bar">
                    <div className="search-inner">
                      {showSelectAndSearch && (
                        <div>
                          <CFormSelect
                            onChange={handleRowsPerPageChange}
                            size="sm"
                            className="mb-3"
                            style={{ cursor: "pointer" }}
                            aria-label="Small select example"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </CFormSelect>
                        </div>
                      )}
                      <div style={{ display: "flex" }}>
                        {showSelectAndSearch && (
                          <>
                            <CFormInput
                              type="text"
                              size="sm"
                              placeholder="Search"
                              aria-label="sm input example"
                              style={{ margin: "0px 0px 16px 0px" }}
                              onChange={handleSearchChange}
                              value={searchInput}
                            />

                            <span
                              style={{
                                border: " 1px solid #d1c5c5",
                                borderRadius: "4px",
                                margin: "0px 5px 16px -1px",
                                padding: "1px 8px 0px 8px",
                                background: "#ebedef",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-search"></i>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    {showSelectAndSearch && (
                      <>
                        {role === "employee" ? (
                          ""
                        ) : (
                          <div className="btn_add mt-0 mb-14">
                            <CButton
                              className="add-btn mr-10 w-auto"
                              onClick={download_btn}
                            >
                              Download
                            </CButton>
                            <CSVLink
                              data={
                                currentRows === undefined ? " " : currentRows
                              }
                              filename={"staff-table.csv"}
                            >
                              <CButton className="add-btn w-auto m-0">
                                CSV
                              </CButton>
                            </CSVLink>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <CCardBody>
                    <div
                      className={`table-container ${showAccordion ? "hidden" : ""
                        }`}
                    >
                      <div
                        ref={printState ? componentPDF : null}
                        style={{ padding: printState ? "20px" : "0px" }}
                      >
                        {printState && (
                          <div className="print-div">
                            <div>
                              {/* <Vakilkaro /> */}
                              <h3>Riyms</h3>
                            </div>
                            <div>
                              <p>{COMPANY_NAME}</p>{" "}
                            </div>
                          </div>
                        )}
                        <CTable hover className="">
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-1"
                              >
                                S. No.
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-2"
                              >
                                Staff Names
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-3"
                              >
                                Staff Email ID
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-3"
                              >
                                Staff Mobile Number
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="col-md-2"
                              >
                                Status
                              </CTableHeaderCell>
                              {!printState && (
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-3"
                                >
                                  Action
                                </CTableHeaderCell>
                              )}
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {staffData && staffData?.length > 0 ? (
                              currentRows
                                ?.filter((item) =>
                                  item?.phoneNumber
                                    ?.toLowerCase()
                                    ?.includes(searchInput?.toLowerCase())
                                )
                                ?.map((company, index) => (
                                  <CTableRow key={index}>
                                    <CTableDataCell>
                                      {indexOfFirstRow + index + 1}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.firstName}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.email || "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.phoneNumber || "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {company?.status || "--"}
                                    </CTableDataCell>
                                    {!printState && (
                                      <CTableDataCell
                                        className="col-md-3"
                                        style={{ padding: "10px 0px 10px 0px" }}
                                      >
                                        <Tooltip text="View">
                                          <FontAwesomeIcon
                                            role="button"
                                            icon={faEye}
                                            size="1x"
                                            className="viewIcon"
                                            onClick={() =>
                                              handleViedDetails(company)
                                            }
                                          />
                                        </Tooltip>
                                        {role === "employee" ? (
                                          ""
                                        ) : role === "manager" ? (
                                          ""
                                        ) : (
                                          <Tooltip text="Update">
                                            <FontAwesomeIcon
                                              role="button"
                                              icon={faPenToSquare}
                                              size="1x"
                                              onClick={() =>
                                                handleEditLoan(company)
                                              }
                                              className="px-3 updateIcon"
                                            />{" "}
                                          </Tooltip>
                                        )}
                                      </CTableDataCell>
                                    )}
                                  </CTableRow>
                                ))
                            ) : (
                              <div className="loading_data">Loading...</div>
                            )}
                          </CTableBody>
                        </CTable>
                      </div>

                      <div className="pagin-div">
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                          Showing {indexOfFirstRow + 1} to{" "}
                          {Math?.min(indexOfLastRow, staffData?.length)} of{" "}
                          {staffData?.length} entries
                        </div>
                        <div className="pagination-container">
                          <ReactPaginate
                            activePage={currentPage}
                            itemsCountPerPage={rowsPerPage}
                            totalItemsCount={staffData?.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            prevPageText="Previous"
                            nextPageText="Next"
                            firstPageText="First"
                            lastPageText="Last"
                            innerClass="pagination"
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                    {showAccordion && (
                      <StaffDetailsById
                        selectedCompany={selectedCompany}
                        setShowAccordion={setShowAccordion}
                        setShowSelectAndSearch={setShowSelectAndSearch}
                      />
                    )}
                  </CCardBody>
                </CCard>
              </CCardBody>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
      <CCol xs={12}>
        <div
          className={`accordion-container ${showAccordion ? "visible" : ""}`}
        ></div>
      </CCol>
    </CRow>
  );
};

export default UserOperations;
